import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import { ChartYears } from "../../../Helper/DateFormat";
import { months } from "../../../Helper/Year";
import { ApiStringDateToDate } from "../../../Helper/ApiStringDateToDate";
const randomColor = require("randomcolor"); // import the script

export type LineChartDataSet = {
  date: Date;
  value: number;
  siteName: string;
  fuelSourceName: string;
};

const Chart = (p: {
  dataSet?: LineChartDataSet[][];
  selectedIndex: number;
  myRef: any;
  expand?: boolean;
  tooltipTag?: string;
}) => {
  const svgRef = useRef(null);
  const data = useMemo<LineChartDataSet[][]>(
    () => p.dataSet || [],
    [p.dataSet]
  );

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    var svg: d3.Selection<SVGGElement, unknown, null, undefined>,
      tip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
    setTimeout(() => {
      const width = p.myRef.current?.offsetWidth - 40;
      const height = p.myRef.current?.offsetHeight;

      const maxValue = Math.max(...data[0]?.map((x: any) => x.cost));

      // append the svg object to the body of the page
      svg = d3
        .select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .attr("id", "energyCost")
        .append("g")
        .attr("transform", `translate(40, 0)`);

      const everything = svg.selectAll("*");
      everything.remove();

      var subgroups = ["date", "cost"];
      var xAxis = d3.scaleLinear().range([height - 20, 10]);
      var yAxis = d3
        .scaleBand()
        .range([width - 40, 0])
        .padding(0.5);
      xAxis.domain([0, ((maxValue ?? 2000) + 200) as number]);
      svg
        .append("g")
        .call(d3.axisLeft(xAxis))
        .attr("stroke-width", 0)
        .selectAll("text")
        .style("text-anchor", "end")
        .style("color", "#000000");
      const dates = Array.from(
        new Set(
          data
            .flat()
            .sort(
              (a, b) =>
                ApiStringDateToDate(a.date.toString()).valueOf() -
                ApiStringDateToDate(b.date.toString()).valueOf()
            )
            .map((i) => {
              return ChartYears(ApiStringDateToDate(i.date.toString()));
            })
        ).values()
      ).reverse();
      yAxis.domain(dates);

      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("pointer-events", "none")
        .style("background", "rgba(0,0,0,0.8)")
        .style("z-index", 10)
        .style("padding", "8px")
        .style("font-size", "11px")
        .style("border-radius", "10px");

      var color = d3
        .scaleOrdinal()
        .domain(subgroups)
        .range(
          Array(99)
            .fill(0)
            .map(() =>
              randomColor({
                luminosity: "light",
              })
            )
        );

      const dataMap = new Map();
      data.forEach((item) => {
        item.forEach((i: any, idx) => {
          const key = ChartYears(ApiStringDateToDate(i.date));
          const found = dataMap.get(key);
          if (found) {
            found[0][`item${idx}`] = i.cost;
            dataMap.set(key, found);
          } else {
            dataMap.set(key, [
              {
                date: key,
                siteName: i.siteName,
                originalDate: i?.date,
                fuelSourceName: i.fuelSourceName,
                [`item${idx}`]: i?.cost,
              },
            ]);
          }
        });
      });

      const keys = Object.keys(
        Array.from(dataMap.values())
          .flat()
          .reduce((obj, next) => Object.assign(obj, next), {})
      );
      var stackedData = d3.stack().keys(keys.slice(1))(
        Array.from(dataMap.values()).flat()
      );

      svg
        .append("defs")
        .append("linearGradient")
        .attr("id", "myGradient")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "0%")
        .attr("y2", "100%")
        .selectAll("stop")
        .data([
          { offset: "0%", color: "#ffffff" },
          { offset: "100%", color: "#ccc" },
        ])
        .enter()
        .append("stop")
        .attr("offset", (d) => d.offset)
        .attr("stop-color", (d) => d.color);

      svg
        .append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(stackedData)
        .enter()
        .append("g")
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data(function (d) {
          return d;
        })
        .enter()
        .append("rect")
        .attr("x", function (d, index) {
          if (index === p.selectedIndex) {
            return yAxis(d.data.date.toString())! - 10 || null;
          }
          return yAxis(d.data.date.toString()) || null;
        })
        .attr("y", function (d) {
          return xAxis(d[1]) - 40;
        })
        .attr("height", function (d) {
          return xAxis(d[0]) - xAxis(d[1]) + 65;
        })
        .attr("width", function (d, index) {
          if (index === p.selectedIndex) {
            return yAxis.bandwidth() + 20;
          }
          return yAxis.bandwidth();
        })
        .attr("fill", function (d, index) {
          if (index === p.selectedIndex) {
            return "url(#myGradient)";
          }
          return "#0000";
        });

      svg
        .append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(stackedData)
        .enter()
        .append("g")
        .attr("fill", function (d, index) {
          return "#4989C6";
        })
        .on("mouseout", function (d) {
          d3.select(this).attr("fill", function (d) {
            return "#4989C6";
          });
        })
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data(function (d) {
          return d;
        })
        .enter()
        .append("rect")
        .attr("rx", 10)
        .attr("ry", 10)
        .attr("x", function (d) {
          return yAxis(d.data.date.toString()) || null;
        })
        .attr("y", function (d) {
          return xAxis(d[1]);
        })
        .attr("height", function (d) {
          return xAxis(d[0]) - xAxis(d[1]);
        })
        .attr("width", yAxis.bandwidth())
        .on("mouseover", function (event, d) {
          //@ts-ignore
          const data = d3.select(this.parentNode).datum() as any;
          const subgroupName = data.key;

          const subgroupValue = d.data[subgroupName];

          const items = p?.dataSet
            ?.flat()
            .filter((i: any) => i?.date === d?.data?.originalDate);

          tip.style("opacity", 1);
          const c = color(d[0].toString()) as string;
          tip.style("color", c);
          const body =
            items!.length > 0 &&
            `
              <div><b>${p.tooltipTag}:</b> ${subgroupValue}</div>
                <div><b>Site:</b> ${items ? items[0]?.siteName : ""}</div>
                <div><b>FuelSource:</b> ${
                  items ? items[0]?.fuelSourceName : ""
                }</div>
              `;
          tip.html(`<h6>${d.data.date}</h6>${body}`);
        })
        .on("mousemove", function (event, d) {
          tip
            .style("left", `${event.pageX}px`)
            .style("top", `${event.pageY}px`);
        })
        .on("mouseleave", function () {
          tip.style("opacity", 0);
        });

      svg
        .append("g")
        .call(d3.axisBottom(yAxis).ticks(d3.timeMonth))
        .attr("stroke-width", 0)
        .attr("transform", `translate(0, ${height - 20})`)
        .selectAll("text")
        .style("text-anchor", "middle")
        .style("font-weight", function (d: any) {
          if (months[p.selectedIndex].label.includes(d.split(",")[0])) {
            return "bold";
          }
          return "normal";
        })
        .style("color", function (d: any) {
          if (months[p.selectedIndex].label.includes(d.split(",")[0])) {
            return "black";
          }
          return "#8e8e8e";
        });
    }, 1000);

    return () => {
      setTimeout(() => {
        tip?.remove();
        svg?.remove();
      }, 1000);
    };
  }, [p.dataSet, data, p.selectedIndex, p.expand]);

  return <svg ref={svgRef} />;
};

interface Props {
  expand?: boolean;
  selectedIndex: number;
  records: LineChartDataSet[][];
  loading: boolean;
  tooltipTag?: string
}
export default function BarChart({
  records,
  loading,
  selectedIndex,
  expand,
  tooltipTag = "Emissions",
}: Props) {
  const myRef = useRef<any>(null);

  useEffect(() => {
    if (myRef.current) {
      console.log("final Width:", myRef.current.offsetWidth);
    } else {
      console.error("Ref not attached to the component");
    }
  }, [myRef, expand]);

  if (loading === true) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (records.flat().length === 0) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <h4>No data</h4>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ position: "absolute", height: "100%", width: "100%" }}
      ref={myRef}
    >
      {" "}
      <Chart
        dataSet={records}
        selectedIndex={selectedIndex}
        myRef={myRef}
        expand={expand}
        tooltipTag={tooltipTag}
      />{" "}
    </div>
  );
}
