import { Link } from "react-router-dom";

export default function Analytics() {
    return <section className="mainLinks w-55 mt-5">
        <div className="row gx-5">
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header bg-white rounded-custom-top text-center fw-bold py-4">
                        Energy League
                    </div>
                    <Link
                        to="/analytics/utilityleague"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                        Carbon Footprints
                    </div>
                    <Link
                        to="/analytics/carbonfootprints"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                        Avoided / Wasted Energy
                    </div>
                    <Link
                        to="/analytics/energywaste"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header bg-white rounded-custom-top text-center fw-bold py-4">
                        Energy saving Tips
                    </div>
                    <Link
                        to="/analytics/energysavingtips"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                        Energy Efficiency Reports
                    </div>
                    <Link
                        to="/analytics/reports"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                        Energy Load
                    </div>
                    <Link
                        to="#"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header bg-white rounded-custom-top text-center fw-bold py-4">
                        Load Costs
                    </div>
                    <Link
                        to="#"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="setting-menu-item-deactivated col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                        Energy Log
                    </div>
                    <Link
                        to="/analytics/energylog"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom h-100">
                    <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                        Behaviour change
                    </div>
                    <Link
                        to="#"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
            <div className="col-lg-4 mb-4 col-12">
                <div className="card rounded-custom">
                    <div className="card-header bg-white rounded-custom-top text-center fw-bold py-4">
                        Portfolio
                    </div>
                    <Link
                        to="/analytics/portfolio"
                        className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold">
                        VIEW
                    </Link>
                </div>
            </div>
        </div>
    </section>
}