import useAxios from "axios-hooks";
import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { useSelector } from "react-redux";
import { ApiStringDateToDate } from "../../Helper/ApiStringDateToDate";
import { Site } from "../../Models/Site";
import { State } from "../../State";

const initialState = {
  isLoading: false,
  logs: [] as any[],
  tenants: [] as any[],
  sites: [] as any[],
};
const { useGlobalState } = createGlobalState(initialState);

const EnergyLogState = () => {
  const [isLoading, setLoading] = useGlobalState("isLoading");
  const [sites, setSites] = useGlobalState("sites");
  const [logs, setLogs] = useGlobalState("logs");
  const [tenants, setTenants] = useGlobalState("tenants");
  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const site = useSelector((state: State) => state.site);

  const [logReq, getLogs] = useAxios<{ logs: any[]; tenants: any[] }>(
    {},
    { manual: true }
  );

  const [, getSites] = useAxios<Site[]>(
    {
      url: "/business/sites",
    },
    { manual: true }
  );

  const fetchLogs = () => {
    if (!site) return;
    getLogs({
      url: `/business/logs?siteId=${site.id}&year=${year.value}&month=${month.value}`,
    });
  };

  useEffect(() => {
    setLoading(logReq.loading);
    if (logReq.data && logReq.data.logs.length !== 0) {
      setLogs(logReq.data.logs);
    }

    if (logReq.data && logReq.data.tenants.length !== 0) {
      setTenants(
        logReq.data.tenants.map((i) => ({
          ...i,
          date: ApiStringDateToDate(i.date),
        }))
      );
    }
  }, [logReq.data, logReq.loading]);

  const fetchSites = () => {
    return getSites().then(({ data }) => {
      if (data.length !== 0) {
        setSites(data);
      }
    });
  };

  return {
    fetchSites,
    fetchLogs,
    tenants,
    isLoading: isLoading,
    sites,
    logs,
  };
};

export default EnergyLogState;
