import useAxios from "axios-hooks";
import Layout from "./Layout";
import EnergySavingTipsSide from "./SideMenu/EnergySavingTips";
import style from "./analytics.module.css"

type EnergyTip = {
  imageUrl: null;
  category: string;
  text: string;
};

export default function EnergySavingTips() {

    const [energyTips] = useAxios<EnergyTip[]>({ url: "/utility/savingTips" });

    return (
      <Layout rightSideBar={<EnergySavingTipsSide />}>
        <div className="flex-fill d-flex flex-column">
          <h4 className={`${style.sectionHeader} mt-4 text-center`}>
            Energy Saving Tips
          </h4>
          <div className="flex-fill mx-3 mb-4 position-relative">
            <div className={`${style.full} p-3`}>
              <div className={`card ${style.analyticsCard}`}>
                <div className="card-header d-flex justify-content-between align-items-center bg-white ps-4">
                  <span className={style.cardTitle}>Energy Saving Tips</span>
                </div>
                <div className="card-body row">
                  {energyTips.data?.map((item) => {
                    if (!item.imageUrl) {
                      return <></>;
                    }

                    return (
                      <div className="col-4 ps-2 pe-2" key={item.imageUrl}>
                        <img
                          className="w-100"
                          alt={item.category}
                          src={`${process.env.REACT_APP_API_URL?.slice(0, -4)}${
                            item.imageUrl
                          }`}
                        />
                        <p className="text-center">{item.text}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
}