import { useState, useEffect } from "react";
import Layout from "../Layout";
import EnergyLogSide from "../SideMenu/EnergyLog";
import style from "../analytics.module.css";
import Card from "../Card";
import Table from "../Tables/BuildingOperationsTable";
import Table2 from "../Tables/BuildingUserTables";
import EnergyLogState from "./EnergyLogState";

export default function EnergyLog() {
  const [fullFirst, setFullFirst] = useState(false);
  const [fullSecond, setFullSecond] = useState(false);

  const pageState = EnergyLogState();

  useEffect(() => {
    pageState.fetchSites();
  }, []);

  return (
    <Layout rightSideBar={<EnergyLogSide />}>
      <div className="flex-fill d-flex flex-column">
        <h4 className={`${style.sectionHeader} mt-4 text-center`}>
          Building Energy Log
        </h4>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <div className={`${style.halfFirst} ${fullFirst && style.full} p-3`}>
            <Card
              onClick={() => setFullFirst(!fullFirst)}
              title="Regular Building Operations"
              expanded={fullFirst}
            >
              <Table data={pageState.logs} expand={fullFirst} />
            </Card>
          </div>
          <div
            className={`${style.halfSecond} ${fullSecond && style.full} p-3`}
          >
            <Card
              onClick={() => setFullSecond(!fullSecond)}
              title="Building Users"
              expanded={fullSecond}
            >
              <Table2 data={pageState.tenants} expand={fullSecond} />
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
