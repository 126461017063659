import { Redirect, Route } from "react-router-dom";
import { loggedIn } from "../Services/Auth";

interface Props {
    children?: React.ReactNode,
    [rest: string]: any
}
export default function PrivateRoute({ children, ...rest }: Props) {
    return <Route
        {...rest}
        render={(props) => loggedIn() ?
            children :
            <Redirect to={{pathname:'/login',state:{from:props.location.pathname}}}/>
        }
    />
}