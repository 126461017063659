import {
  Bar,
  BarChart,
  CartesianAxis,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

interface props {
  expand?: boolean;
  data: any;
  loading: boolean;
}

const Tick = ({ x, y, payload, itemType, expand }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="middle"
        fill="#000000"
        className="axis"
      >
        {payload.value} KWH
      </text>
    </g>
  );
};

export default function VerticalBar({ expand, data, loading }: props) {
  return (
    <ResponsiveContainer width="99%" height="105%">
      {loading ? (
        <div className="h-100 w-100 d-flex justify-content-between">
          <div
            style={{
              width: "calc(100%)",
            }}
          >
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BarChart
          data={data}
          layout="vertical"
          margin={{ left: expand ? 0 : -20 }}
          id="wasteFuels"
        >
          <CartesianGrid opacity={0.1} />
          <CartesianAxis />
          <XAxis
            strokeWidth={0}
            type="number"
            domain={[0, 1000]}
            tick={<Tick expand={expand} />}
          />
          <YAxis
            type="category"
            dataKey="source"
            tickLine={false}
            strokeWidth={0}
            textAnchor="end"
          />

          <Bar
            type="monotone"
            dataKey="value"
            fill="#F5B363"
            legendType="circle"
            barSize={15}
            radius={[10, 10, 10, 10]}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
}
