import { NavLink } from "react-router-dom";
import style from "./analytics.module.css"

export default function SideBar() {
    return <div className={style.leftSideBar}>
        <div className={style.logo}>
            <img src="/icons/gpad_logo_2.png" alt="icons" className="img-fluid ps-5" />
        </div>

        <nav className="d-flex text-white flex-column flex-fill">
            <NavLink to="/analytics/utilityleague" className="flex-fill d-flex align-items-center ps-5"
                activeClassName={style.active}>
                Energy League
            </NavLink>
            <NavLink to="/analytics/carbonfootprints"
                className="flex-fill d-flex align-items-center ps-5"
                activeClassName={style.active}>
                Carbon Footprints
            </NavLink>
            <NavLink to="/analytics/energywaste"
                className="flex-fill d-flex align-items-center ps-5"
                activeClassName={style.active}>
                Avoided / Wasted Energy
            </NavLink>
            <NavLink to="/analytics/energySavingTips"
                className="flex-fill d-flex align-items-center ps-5"
                activeClassName={style.active}>
                Energy Saving Tips
            </NavLink>
            <NavLink to="/analytics/reports"
                className="flex-fill d-flex align-items-center ps-5"
                activeClassName={style.active}>
                Reports
            </NavLink>
            <NavLink to="/analytics/portfolio"
                activeClassName={style.active}
                className="flex-fill d-flex align-items-center ps-5">
                Portfolio
            </NavLink>
        </nav>
    </div>
}