import { Site } from "../Models/Site";
import BaseAxios from "./BaseAxios";

export default async function SiteService(site: Site) {
  const s = site;
  s.vat = Number.parseFloat(s.vat.toString());
  try {
    if (site.id) {
      const { id, businessId, ...others } = s as any;
      await BaseAxios.put(`/site/update/${s.id}`, others);
      return s.id;
    } else {
      let siteId = await BaseAxios.post("/site/", s);
      return siteId.data.id;
    }
  } catch (e: any) {
    return Promise.reject(e.response.data.message);
  }
}

export async function getSites() {
  try {
    let value = await BaseAxios.get("/business/sites");
    return value.data;
  } catch (e: any) {
    return false;
  }
}

export async function getSite(id: number) {
  try {
    let value = await BaseAxios.get("/site/details/" + id);
    return value.data;
  } catch (e: any) {
    return false;
  }
}

export async function Delete(id: number) {
  try {
    let data = {
      id: id,
    };
    await BaseAxios.delete("/site", { data });
    return true;
  } catch (e: any) {
    return false;
  }
}
export async function AddEnergyToSite(energy: any) {
  try {
    let value = await BaseAxios.post("/business/saveEnergy", energy);
    return true;
  } catch (e: any) {
    return false;
  }
}
