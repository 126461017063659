import { NavLink } from "react-router-dom";

export default function FormsTop() {
    return <section
        style={{
            backgroundImage: "url('/images/bg-image.png')",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
        }}
        className="d-flex py-5 align-items-center justify-content-center flex-column w-100 mb-5"
    >
        <div className="w-55">
            <div className="row menu gx-5">
                <div className="col-4" style={{
                    height:"247px"
                }}>
                    <NavLink
                        to="/home/settings"
                        className="card w-100 h-100"
                        activeClassName="active"
                        style={{
                            filter: "drop-shadow(0px 0px 40px #0000004F)",
                            borderRadius:"24px"
                        }}
                    >
                        <div className="card-body p-5 w-100 text-center d-flex flex-column h-100 justify-content-center align-items-center">
                            <img src="/icons/settings.png" alt="settings" className="img-fluid img-menu" />
                            <p className="mb-0 fw-bold mt-4">SETTINGS</p>
                        </div>
                    </NavLink>
                </div>
                <div className="col-4">
                    <NavLink to="/home/analytics" className="card h-100 w-100" style={{
                        filter: "drop-shadow(0px 0px 40px #0000004F)",
                        borderRadius:"24px"
                    }}>
                        <div className="card-body w-100 p-5 text-center d-flex flex-column h-100 justify-content-center align-items-center">
                            <img src="/icons/dashboard.png" alt="analytics" className="img-fluid img-menu" />
                            <p className="mb-0 fw-bold mt-4">ANALYTICS</p>
                        </div>
                    </NavLink>
                </div>
                <div className="col-4">
                    <NavLink to="/home/user-management" className="card h-100 w-100" style={{
                        filter: "drop-shadow(0px 0px 40px #0000004F)",
                        borderRadius:"24px"
                    }}>
                        <div className="card-body w-100 p-5 text-center d-flex flex-column h-100 justify-content-center align-items-center">
                            <img src="/icons/key.png" alt="user managemnt" className="img-fluid img-menu" />
                            <p className="mb-0 fw-bold mt-4">USER MANAGEMENT</p>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>

    </section>

}