import Modal from "react-modal";
import { useFormik } from "formik";
import useAxios from "axios-hooks";
import * as Yup from "yup";

interface Props {
  show: boolean;
  onCancel: () => void;
  onUploadDone: () => void;
  url: string;
  title: string;
}

const RenderErrorList = (opt: { errors: string[] }) => {
  return (
    <>
      {opt.errors.map((i) => (
        <p className="mb-0">{i}</p>
      ))}
    </>
  );
};

const formSchema = Yup.object({
  excel: Yup.mixed().required(),
});

export default function SimpleImportModal({
  show,
  onCancel,
  onUploadDone,
  url,
  title,
}: Props) {
  const formik = useFormik({
    initialValues: {
      excel: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      const data = new FormData();

      data.append("excel", values.excel);

      uploadFile({
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(onUploadDone);
    },
  });

  const [uploadDataReq, uploadFile] = useAxios(
    {
      url: url,
      method: "post",
    },
    { manual: true }
  );

  const upload = () => {
    if (uploadDataReq.loading === false) {
      formik.handleSubmit();
    }
  };

  const isUploadingFile = uploadDataReq.loading === true;

  return (
    <>
      <Modal
        isOpen={show}
        style={{
          content: {
            top: "130px",
          },
        }}
        ariaHideApp={false}
        onAfterClose={async () => {}}
      >
        <div className="container d-flex flex-column h-100">
          <div className="row">
            <h2 className="text-center mb-0 fw-bold">Upload File</h2>
            <p className="text-success border-top border-success border-4 pt-2 fw-semibold">
              {title}
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12 mb-3">
              <label htmlFor="formFile" className="form-label">
                Excel File
              </label>
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(event) => {
                  if (event.target.files) {
                    formik.setFieldValue("excel", event.target.files[0]);
                  }
                }}
                className="form-control"
                type="file"
                id="formFile"
              />
              {formik.errors.excel && formik.touched.excel ? (
                <p className="text-end text-danger mb-0 text-small">
                  File is required
                </p>
              ) : null}
            </div>
          </div>

          {uploadDataReq.loading === false && uploadDataReq.error ? (
            <div className="row">
              <p className="text-danger mb-0">
                Error parsing file:{" "}
                {uploadDataReq.error.response?.data?.list ? (
                  <RenderErrorList
                    errors={uploadDataReq.error.response?.data?.list}
                  />
                ) : (
                  uploadDataReq.error.response?.data?.message
                )}
              </p>
            </div>
          ) : null}

          <div className="row align-items-end h-100">
            <div className="col-12 col-lg-12 d-flex justify-content-between mt-auto d-block">
              <button
                disabled={isUploadingFile}
                className="btn btn-outline-danger btn-nav"
                onClick={upload}
              >
                <i className="fas fa-save me-3" />
                UPLOAD
              </button>
              <button
                className="btn btn-danger btn-nav"
                type="button"
                disabled={isUploadingFile}
                onClick={onCancel}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
