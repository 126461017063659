import { Link } from "react-router-dom"
import Popup from "reactjs-popup"
import style from "./analytics.module.css"

export default function TopMenu() {
    return <Popup
        trigger={<div className={style.topMenu}>
            <img src="/icons/dashboard.png" alt="analytics" className="img-fluid ms-5   " />
            <h5 className="fw-bold text-white ms-3 mb-0">ANALYTICS</h5>
        </div>}
        on={['hover', 'click', 'focus']}
        position="bottom left"
        arrow={false}
        closeOnDocumentClick
    >
        <div>
            <ul className="list-unstyled mt-0">
                <li className={style.topMenu}>
                    <img src="/icons/settings.png" alt="analytics" className="img-fluid ms-5" />
                    <Link to="/home/settings" className="fw-bold ms-3 mb-0 text-decoration-none me-5">Settings</Link>                  
                </li>
                <li className={style.topMenu}>
                    <img src="/icons/key.png" alt="analytics" className="img-fluid ms-5" />
                    <Link to="/home/user-management" className="fw-bold ms-3 mb-0 text-decoration-none me-5">User Management</Link>                  
                </li>
            </ul>
        </div>
    </Popup>


}