interface Props {
  children: string | undefined;
  render: boolean;
}
export default function ErrorLabel({ children, render }: Props) {
  if (render === true) {
    if (children !== undefined) {
      return <p className="text-end text-danger mb-0 text-small">{children}</p>;
    } else {
      return <></>;
    }
  }

  return <></>;
}
