import moment from "moment";
import styles from "../Tables/styles.module.css";
import formatISO from "date-fns/formatISO";
interface Props {
  loading: boolean;
  expand: boolean;
  selectedIndex: number;
  data: { date: Date; value: number; cost: number }[];
}
export default function Table({ expand, selectedIndex, data, loading }: Props) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return <h5 className="text-center pt-5">No data</h5>;
  }
  return (
    <table className={`${styles.table} ${expand && styles.expand}`} id={"carbonEmissionTable"}>
      <thead>
        <tr>
          <th>Month</th>
          <th>Emissions (kg)</th>
          <th>Cost (£)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((i) => {
          return (
            <tr
              key={formatISO(i.date)}
              className={
                selectedIndex === i.date.getMonth() ? styles.selected : ""
              }
            >
              <td>{moment(i.date).format("MMMM, DD")}</td>
              <td>{i.value}</td>
              <td>{i.cost}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
