import { format } from "date-fns";
import { ChartRecord } from "../SideMenu/Base";
import styles from "./styles.module.css";
interface Props {
  expand: boolean;
  selectedIndex: number;
  records: ChartRecord[][];
  loading: boolean;
}

const mapRecordRow = (selectedIndex: number) => (i: ChartRecord) => {
  const toInt = (i: string) => parseInt(i, 10);
  const [year, month] = i.date.split("-").map(toInt);
  const dateLabel = format(new Date(year, month - 1, 1), "MMMM, dd");
  return (
    <tr className={selectedIndex === month - 1 ? styles.selected : ""}>
      <td>{dateLabel}</td>
      <td>{i.siteName}</td>
      <td>{i.consumption}</td>
      <td>{i.cost}</td>
    </tr>
  );
};

export default function Table({
  expand,
  selectedIndex,
  records,
  loading,
}: Props) {

  let bodyComponent = <h6 className="text-center">No Data</h6>;

  if (loading === true) {
    bodyComponent = (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (records.length) {
    bodyComponent = (
      <table className={`${styles.table} ${expand && styles.expand}`} id="utilityTable">
        <thead>
          <tr>
            <th>Month</th>
            <th>Site Name</th>
            <th>Consumption (kWh)</th>
            <th>Cost (£)</th>
          </tr>
        </thead>
        {records.length !== 0 && (
          <tbody>
            {records.map((item: any) => {
              return item.map(mapRecordRow(selectedIndex))
            })}
          </tbody>
        )}
      </table>
    );
  }
  return bodyComponent;
}
