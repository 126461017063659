import { useRef, useEffect } from "react";

export default function useDidUpdateEffect(
  fn: React.EffectCallback,
  inputs?: React.DependencyList
) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
}
