import { Link } from "react-router-dom";
import Select from "react-select";
import ApiCountrySelect from "../Components/ApiCountrySelect";
import ApiStateSelect from "../Components/ApiStateSelect";
import { SelectStyle } from "../Helper/ReactSelect";
import Register from "../Models/Register";
import { loggedIn, RegisterService } from "../Services/Auth";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { BusinessService } from "../Services/Business";
import useAxios from "axios-hooks";
import { useEffect, useRef, useState } from "react";
import Option from "../Models/Option";
import { useAlert } from "react-alert";
import sectors from "../Helper/sectors";
import currencyCode from "../Helper/country-currency";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

const hasErrors = (obj: Record<any, any>) => Object.keys(obj).length > 0;
const nonIsTouched = (obj: Record<any, any>) => Object.keys(obj).length === 0;

const formSchema = Yup.object({
  businessName: Yup.string().required(),
  businessType: Yup.string().required(),
  businessService: Yup.string().required(),
  buildingName: Yup.string().required(),
  contactName: Yup.string().required(),
  position: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  email: Yup.string().required(),
  currencyCode: Yup.string().required(),
});

if (loggedIn() === false) {
  formSchema.concat(Yup.object({ password: Yup.string().required() }));
}

const registration: Register = {
  businessName: "",
  businessType: "",
  businessService: "",
  password: "",
  buildingName: "",
  contactName: "",
  position: "",
  phoneNumber: "",
  email: "",
  countryId: 0,
  stateId: 0,
  town: "",
  postCode: "",
  currencyCode: "",
  subscriptionDate: "",
  totalArea: -1,
  totalPopulation: -1,
  workingHoursStart: "",
  workingHoursEnd: "",
  address_1: "",
};
const mockFloor = { size: "", area: 0, population: 0 };

export default function Business() {
  const history = useHistory();
  const alert = useAlert();
  const [businessData, setBusinessData] = useState<any>({});
  const formikRef = useRef<FormikProps<Register> | null>(null);
  const [, getBusiness] = useAxios(
    {
      url: "/business",
    },
    { manual: true }
  );

  useEffect(() => {
    if (loggedIn()) {
      getBusiness().then(({ data }) => {
        setBusinessData(data);
      });
    }
    return () => {
      setBusinessData({});
    };
  }, []);

  useEffect(() => {
    if (!formikRef.current) return;

    if (businessData) {
      formikRef.current.setValues(businessData);
    }
  }, [businessData]);

  return (
    <section className="w-55 mt-5">
      <Formik
        innerRef={(r) => (formikRef.current = r)}
        enableReinitialize
        initialStatus={{ loading: false }}
        initialValues={registration}
        onSubmit={(values, { setStatus }) => {
          let promise: Promise<void | boolean> = Promise.resolve();
          if (loggedIn()) {
            promise = BusinessService(values);
          } else {
            promise = RegisterService(values);
          }

          promise
            .then((res) => {
              if (loggedIn()) {
                alert.success("Data updated");
              } else {
                alert.success("Business created");
              }
              history.push("/login");
            })
            .catch((err) => {
              alert.error(err);
            })
            .finally(() => setStatus({ loading: false }));
        }}
        validationSchema={formSchema}
      >
        {({ values, errors, touched, status, handleBlur, handleChange }) => {
          return (
            <Form>
              <div
                className="card rounded-custom pb-5"
                style={{
                  filter: "drop-shadow(0px 0px 20px #E7E7E866)",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    paddingLeft: "2.2rem",
                    paddingRight: "2.2rem",
                  }}
                >
                  <h2 className="text-center border-bottom border-success border-4 py-4 fw-bold mb-4">
                    Business
                  </h2>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="businessName" className="form-label">
                        Business Name
                      </label>
                      <input
                        id="businessName"
                        name="businessName"
                        type="text"
                        className="form-control"
                        placeholder="Business Name"
                        value={values.businessName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.businessName && touched.businessName ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Business name is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="businessType" className="form-label">
                        Type of business
                      </label>
                      <Select
                        name="siteId"
                        id="businessType"
                        options={sectors.map((x) => ({
                          label: x,
                          value: x,
                        }))}
                        styles={SelectStyle}
                        placeholder={
                          <span className="select-placeholder">
                            Choose Business Type
                          </span>
                        }
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "businessType",
                              value: selectedOption?.value,
                            },
                          };
                          handleChange(event);
                        }}
                        value={{
                          label: values.businessType,
                          value: values.businessType,
                        }}
                        onBlur={() => {
                          handleBlur({ target: { name: "businessType" } });
                        }}
                      />
                      {errors.businessType && touched.businessType ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Business type is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="businessService" className="form-label">
                        Nature of services, activities or products
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="businessService"
                        name="businessService"
                        placeholder="Nature of services, activities or products"
                        value={values.businessService}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.businessService && touched.businessService ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Business service is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="buildingName" className="form-label">
                        Building name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="buildingName"
                        name="buildingName"
                        value={values.buildingName}
                        placeholder="E.g Eagle Building"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.buildingName && touched.buildingName ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Building name is compulsory
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <hr className="my-4" />
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="contactName" className="form-label">
                        Business Contact Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contactName"
                        name="contactName"
                        placeholder="Name"
                        value={values.contactName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.contactName && touched.contactName ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Contact name is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="position" className="form-label">
                        Position
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="position"
                        name="position"
                        placeholder="Position"
                        value={values.position}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.position && touched.position ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Position is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="phoneNumber" className="form-label">
                        Phone number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Phone number"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Phone Number is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Email is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Password is compulsory
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <hr className="my-4" />
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="address_1" className="form-label">
                        Address
                      </label>
                      <input
                        className="form-control"
                        id="address_1"
                        name="address_1"
                        placeholder="address_1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <ApiCountrySelect
                        id={"countryId"}
                        value={values.countryId}
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "countryId",
                              value: (selectedOption as Option).value,
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={() => {
                          handleBlur({ target: { name: "country" } });
                        }}
                      />
                      {errors.countryId && touched.countryId ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Country is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="town" className="form-label">
                        Town
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="town"
                        name="town"
                        placeholder="Town"
                        value={values.town}
                        onChange={(e) => {
                          let event = {
                            target: { name: "town", value: e.target.value },
                          };
                          handleChange(event);
                        }}
                        onBlur={() => {
                          handleBlur({ target: { name: "town" } });
                        }}
                      />
                      {errors.town && touched.town ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Town is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label htmlFor="postCode" className="form-label">
                        Post code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postCode"
                        name="postCode"
                        value={values.postCode}
                        placeholder="Code"
                        onChange={(e) => {
                          let event = {
                            target: { name: "postCode", value: e.target.value },
                          };
                          handleChange(event);
                        }}
                        onBlur={() => {
                          handleBlur({ target: { name: "postCode" } });
                        }}
                      />
                      {errors.postCode && touched.postCode ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Post code is compulsory
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="form-label">Currency</label>
                      <Select
                        options={currencyCode.map((x) => ({
                          label: x.country + " (" + x.currency_code + ")",
                          value: x.country + " " + x.currency_code,
                        }))}
                        styles={SelectStyle}
                        placeholder={
                          <span className="select-placeholder">Choose</span>
                        }
                        name={`currencyCode`}
                        id={`currencyCode`}
                        onChange={(e) => {
                          let event = {
                            target: {
                              name: `currencyCode`,
                              value: e?.value,
                            },
                          };
                          handleChange(event);
                        }}
                        value={{
                          label: values.currencyCode,
                          value: values.currencyCode,
                        }}
                        onBlur={() => {
                          handleBlur({
                            target: { name: "currencyCode" },
                          });
                        }}
                      />
                      {errors.currencyCode && touched.currencyCode ? (
                        <p className="text-end text-danger mb-0 text-small">
                          Currency is compulsory
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 mb-5">
                <button
                  disabled={
                    status.loading ||
                    hasErrors(formikRef.current?.errors || {}) ||
                    nonIsTouched(formikRef.current?.touched || {})
                  }
                  className={clsx("btn btn-outline-danger btn-nav", {
                    "button-disabled":
                      status.loading ||
                      hasErrors(formikRef.current?.errors || {}) ||
                      nonIsTouched(formikRef.current?.touched || {}),
                  })}
                >
                  <i className="fas fa-save me-3"></i>SAVE
                </button>
                <span>
                  <button className="btn btn-secondary btn-nav" disabled>
                    <i className="fas fa-long-arrow-alt-left me-1"></i>BACK{" "}
                  </button>
                  <Link
                    to="/home/settings/sites"
                    className="btn btn-danger ms-3 btn-nav"
                  >
                    NEXT <i className="fas fa-long-arrow-alt-right ms-1"></i>
                  </Link>
                </span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}
