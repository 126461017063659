export const SelectStyle={
    indicatorSeparator:(provided:any,state:any)=>({
        ...provided,
        margin:0,
        backgroundColor:state.isFocused ? "#94c11f":"#E8E8E8"
    }),
    dropdownIndicator:(provided:any,state:any)=>({
        ...provided,
        color:"#37404D",
        paddingLeft:"1rem",
        paddingRight:"1rem"
    }),
    control:(provided:any,state:any)=>({
        ...provided,
        boxShadow:'none',
        border:state.isFocused ? "1px solid #94c11f":"1px solid #E8E8E8",
        '&:hover':{
            border:'1px solid #94c11f'
        },
        borderRadius:0,
        height:"48px",
    })
}

export const tableSelectStyle={
    indicatorSeparator:(provided:any,state:any)=>({
        ...provided,
        margin:0
    }),
    control:(provided:any,state:any)=>({
        ...provided,
        boxShadow:'none',
        border:0,
        height:"100%",
        borderRadius:0,
        backgroundColor:"transparent",
        fontSize:"14px"   
    }),
    container:(provided:any,state:any)=>({
        ...provided,
        height:"100%"
    }),
    dropdownIndicator:(provided:any,state:any)=>({
        ...provided,
        color:"#37404D"
    })
}