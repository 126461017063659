import { ActionTypes } from "../ActionTypes";
import { Action } from "../Actions";
import Option from "../../Models/Option";

/*const initialState: Option = {
  value: new Date().getFullYear(),
  label: new Date().getFullYear().toString(),
};*/

const initialState: Option = {
  value: new Date().getFullYear(),
  label: new Date().getFullYear().toString(),
};

const reducer = (state: Option = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_YEAR:
      return action.payload;
    default:
      return state;
  }
};
export default reducer;
