import { formatISO } from "date-fns";

export const increasedPercentage = (consumptions: any[], month: number, propName="cost") => {
  const currentVal = consumptions
    .flat()
    .find(
      (c) =>
        Number.parseInt(
          (c.date instanceof Date
            ? formatISO(c.date, { representation: "date" })
            : c.date
          ).slice(5, 7)
        ) ===
        month + 1
    );
  const previouseVal = consumptions
    .flat()
    .find(
      (c) =>
        Number.parseInt(
          (c.date instanceof Date
            ? formatISO(c.date, { representation: "date" })
            : c.date
          ).slice(5, 7)
        ) === month
    );
  if (!previouseVal) {
    return 0;
  }

  return Number.parseFloat(
    (
      ((currentVal[propName] - previouseVal[propName]) / previouseVal[propName]) *
      100
    ).toFixed(2)
  );
};
