import { Form, Formik, yupToFormErrors, getIn } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { SelectStyle } from "../../Helper/ReactSelect";
import ErrorLabel from "../../Components/ErrorLabel";
import BuildingEnergyModel from "../../Models/Tarriff";
import SiteService from "../../Services/Site";
import useAxios from "axios-hooks";
import FuelSource from "../../Models/FuelSource";
import ApiCountrySelect from "../../Components/ApiCountrySelect";
import ApiStateSelect from "../../Components/ApiStateSelect";
import Option from "../../Models/Option";
import { useAlert } from "react-alert";

interface Props {
  close: (reason: "SUBMITTED" | "CANCELLED") => void;
  initial?: BuildingEnergyModel;
}

const schema = Yup.object({
  site: Yup.object({
    name: Yup.string().required("Required"),
    code: Yup.string().required("Required").label("Code"),
    type: Yup.string().required("Required"),
    vat: Yup.string().required("Required").label("VAT"),
    address: Yup.string().required("Required"),
    postCode: Yup.string().required("Required"),
    town: Yup.string().required("Required"),
    population: Yup.number().typeError("Must be a number").required("Required"),
    size: Yup.string().required("Required"),
  }),
  records: Yup.array().of(
    Yup.object({
      fuelSourceId: Yup.number().required().min(1, "Must select at least one"),
      usedInId: Yup.number()
        .typeError("Must select at least one")
        .required()
        .min(1, "Must select at least one"),
      meternumbers: Yup.array()
        .of(
          Yup.object({
            meters: Yup.string().required("Required").min(1),
          })
        )
        .min(1),
      cost: Yup.object({
        currencyCode: Yup.string().required("Required"),
        vat: Yup.number()
          .typeError("Must be number")
          .required()
          .min(0, "Minimum of zero"),
      }),
    })
  ),
});

export default function BuildingEnergy({ close, initial }: Props) {
  const alert = useAlert();
  const [{ data: fuelSources = [] }] = useAxios<FuelSource[]>({
    url: "/utility/fuelSources",
  });
  const initialFormikValues: BuildingEnergyModel = {
    records: [],
    site: undefined,
  };

  return (
    <Formik
      initialValues={initial ? initial : initialFormikValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        console.log("onSubmit");
        if (values.site !== undefined) {
          SiteService(values.site)
            .then((result) => {
              if (result !== false) {
                let fuel = {
                  siteId: result,
                  records: values.records.map((x) => {
                    const { fuelName, ...record } = x;
                    return { ...record };
                  }),
                };
                //await AddEnergyToSite(fuel);
                close("SUBMITTED");
              }
            })
            .catch((err) => {
              alert.error(err);
            });
        }
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        handleReset,
      }) => {
        return (
          <form onReset={handleReset} onSubmit={handleSubmit}>
            <h2 className="text-center mb-0 fw-bold">Sites</h2>
            <p className="text-success border-top border-success border-4 pt-2 fw-semibold">
              SITE INFORMATION
            </p>

            <div className="row">
              <div className="col-12 col-lg-6  mb-3">
                <label className="form-label" htmlFor="site.name">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="PropertyType"
                  name="site.name"
                  id="site-name"
                  value={values.site?.name}
                />
                <ErrorLabel render={getIn(errors.site, "name") !== undefined}>
                  {getIn(errors.site, "name")}
                </ErrorLabel>
              </div>
              <div className="col-12 col-lg-6  mb-3">
                <label className="form-label" htmlFor="site.type">
                  Property Type
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="PropertyType"
                  name="site.type"
                  id="site-type"
                  value={values.site?.type}
                />
                <ErrorLabel render={getIn(errors.site, "type") !== undefined}>
                  {getIn(errors.site, "type")}
                </ErrorLabel>
              </div>

              <div className="col-12 col-lg-6  mb-3">
                <label className="form-label" htmlFor="site.address">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Address"
                  name="site.address"
                  id="site-address"
                  value={values.site?.address}
                />
                <ErrorLabel
                  render={getIn(errors.site, "address") !== undefined}
                >
                  {getIn(errors.site, "address")}
                </ErrorLabel>
              </div>
              <div className="col-12 col-lg-6  mb-3">
                <label className="form-label" htmlFor="site.postCode">
                  Postal Code
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Postal Code"
                  name="site.postCode"
                  id="site-postCode"
                  value={values.site?.postCode}
                />
                {
                  <ErrorLabel
                    render={getIn(errors.site, "postCode") !== undefined}
                  >
                    {getIn(errors.site, "postCode")}
                  </ErrorLabel>
                }
              </div>
              <div className="col-12 col-lg-6  mb-3">
                <label className="form-label" htmlFor="site.town">
                  Town
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Town"
                  name="site.town"
                  id="site-town"
                  value={values.site?.town}
                />
                <ErrorLabel render={getIn(errors.site, "town") !== undefined}>
                  {getIn(errors.site, "town")}
                </ErrorLabel>
              </div>
              <div className="col-12 col-lg-6  mb-3">
                <label className="form-label" htmlFor="site.population">
                  Population/FTE
                </label>
                <input
                  type="number"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Population"
                  name="site.population"
                  id="site-population"
                  value={values.site?.population}
                />
                <ErrorLabel
                  render={getIn(errors.site, "population") !== undefined}
                >
                  {getIn(errors.site, "population")}
                </ErrorLabel>
              </div>

              <div className="col-12 col-lg-6  mb-3">
                <label className="form-label" htmlFor="site.size">
                  Gross Internal area (m<sup>2</sup>)
                </label>
                <input
                  type="number"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Size"
                  name="site.size"
                  id="site-size"
                  value={values.site?.size}
                />
                <ErrorLabel render={getIn(errors.site, "size") !== undefined}>
                  {getIn(errors.site, "size")}
                </ErrorLabel>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <label className="form-label">FTE working hours/day</label>
                <Select
                  styles={SelectStyle}
                  value={
                    values.site?.workinghours
                      ? {
                          value: values.site?.workinghours,
                          label: values.site?.workinghours,
                        }
                      : undefined
                  }
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => ({
                    value: x.toString(),
                    label: x.toString(),
                  }))}
                  onChange={(opt) =>
                    setFieldValue(
                      "site.workinghours",
                      Number.parseInt(opt?.value || "-1")
                    )
                  }
                  name="site.workinghours"
                  id="site-workinghours"
                  placeholder={
                    <span className="select-placeholder">
                      FTE working hours/day
                    </span>
                  }
                />
                <ErrorLabel
                  render={getIn(errors.site, "workinghours") !== undefined}
                >
                  {getIn(errors.site, "workingHours")}
                </ErrorLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 mb-3">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <ApiCountrySelect
                  id={"site-countryId"}
                  value={values.site?.countryId || 0}
                  onChange={(selectedOption) => {
                    let event = {
                      target: {
                        name: "site.countryId",
                        value: (selectedOption as Option).value,
                      },
                    };
                    handleChange(event);
                  }}
                  onBlur={() => {
                    handleBlur({ target: { name: "site.countryId" } });
                  }}
                />
                <ErrorLabel
                  render={getIn(errors.site, "countryId") !== undefined}
                >
                  {getIn(errors.site, "countryId")}
                </ErrorLabel>
              </div>{" "}
              <div className="col-12 col-lg-6 mb-3">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <ApiStateSelect
                  id={"site-stateId"}
                  countryId={values.site?.countryId}
                  value={values.site?.stateId}
                  placeholder={
                    <span className="select-placeholder">Choose State</span>
                  }
                  onChange={(selectedOption) => {
                    let event = {
                      target: {
                        name: "site.stateId",
                        value: (selectedOption as Option)?.value,
                      },
                    };
                    handleChange(event);
                  }}
                  onBlur={() => {
                    handleBlur({ target: { name: "site.stateId" } });
                  }}
                />
                <ErrorLabel
                  render={getIn(errors.site, "stateId") !== undefined}
                >
                  {getIn(errors.site, "stateId")}
                </ErrorLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 mb-3">
                <label className="form-label" htmlFor="site.name">
                  Code
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="PropertyType"
                  name="site.code"
                  id="site-name"
                  value={values.site?.code}
                />
                <ErrorLabel render={getIn(errors.site, "code") !== undefined}>
                  {getIn(errors.site, "code")}
                </ErrorLabel>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <label className="form-label" htmlFor="site.name">
                  VAT
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="PropertyType"
                  name="site.vat"
                  id="site-vat"
                  value={values.site?.vat}
                />
                <ErrorLabel render={getIn(errors.site, "vat") !== undefined}>
                  {getIn(errors.site, "vat")}
                </ErrorLabel>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <button type="submit" className="btn btn-outline-danger btn-nav">
                <i className="fas fa-save me-3"></i>CREATE
              </button>
              <button
                className="btn btn-danger btn-nav"
                type="button"
                onClick={() => close("CANCELLED")}
              >
                CLOSE
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
