import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart as Bars,
  Bar,
  Text,
  CartesianAxis,
} from "recharts";

const data = [
  {
    date: "Site Name 1 sedddddddd sdddddddddd",
    value: 400,
  },
  {
    date: "Site Name 2",
    value: 25,
  },
  {
    date: "Site Name 3",
    value: 150,
  },
  {
    date: "Site Name 4",
    value: 80,
  },
  {
    date: "Site Name 5",
    value: 80,
  },
  {
    date: "Site Name 6",
    value: 200,
  },
  {
    date: "Site Name 7",
    value: 75,
  },
  {
    date: "Site Name 8",
    value: 50,
  },
  {
    date: "Site Name 9",
    value: 120,
  },
];

interface props {
  expand?: boolean;
  data: { date: string; value: number }[];
  perPage?: number;
  currentPage: number
}

const Tick = ({ x, y, payload }: any) => {
  return (
    <Text
      x={x}
      y={y}
      width={50}
      textAnchor="middle"
      fill="#8E8E8E"
      fontSize="8px"
      fontWeight="normal"
      verticalAnchor="start"
    >
      {payload.value}
    </Text>
  );
};

export default function BarChart({ expand, data, perPage = 5, currentPage }: props) {
  if (data.length === 0) {
    return <h5 className="text-center mt-5">No data</h5>;
  }
  return (
    <ResponsiveContainer width="99%" height="100%">
      <Bars
        data={data.slice((currentPage - 1) * perPage, perPage * currentPage)}
        margin={{ right: 15 }}
      >
        <CartesianGrid opacity={0.1} />
        <CartesianAxis />
        <XAxis
          dataKey="siteAddress"
          strokeWidth={0}
          stroke="#8E8E8E"
          type="category"
          interval={0}
          tick={<Tick expand={expand} data={data} />}
        />
        <YAxis
          tickLine={false}
          strokeWidth={0}
          stroke="#000"
          domain={[0, 1000]}
          textAnchor="end"
        />

        <Bar
          type="monotone"
          dataKey="projectedEnergy"
          fill="#4989C6"
          legendType="circle"
          barSize={20}
          radius={[10, 10, 10, 10]}
        />
      </Bars>
    </ResponsiveContainer>
  );
}
