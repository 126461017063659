import style from "../analytics.module.css"
import Select from "react-select";
import { SelectStyle } from "../../Helper/ReactSelect";
import { months, years } from "../../Helper/Year";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators, State } from "../../State";
import { loggedIn } from "../../Services/Auth";

export default function EnergySavingTips() {
    const options = [
        { value: 1, label: 'Heating' },
        { value: 2, label: 'Cooling' },
        { value: 3, label: 'Power' },
        { value: 3, label: 'Lighting' }
    ]
    const dispatch = useDispatch()
    const { ChangeDisplay, ChangeMonth } = bindActionCreators(ActionCreators, dispatch)
    const display = useSelector((state: State) => state.display)
    const month = useSelector((state: State) => state.month)

    return <div className={style.rightSideBar}>
        {loggedIn() === false && (
            <div className={style.login}>
                <button className="btn btn-success btn-radius px-4 py-2">
                    <i className="fas fa-user me-1"></i>
                    Login
                </button>
            </div>)}
        <div className="d-flex flex-column border-top border-bottom py-3 px-4">
            <label className="checkbox-container mb-2">
                <input type="checkbox" />
                <span className="checkmark"></span>
                <span className="label">Eagle Building, London</span>
            </label>
            <label className="checkbox-container">
                <input type="checkbox" />
                <span className="checkmark"></span>
                <span className="label">1000 people</span>
            </label>
        </div>
        <div className="px-4 my-3 border-bottom flex-fill d-flex flex-column justify-content-evenly">
            <div className="mb-2">
                <label className={`form-label ${style.selectLabel}`}>Month</label>
                <Select
                    options={months}
                    styles={SelectStyle}
                    placeholder={<span className="select-placeholder">Choose</span>}
                    value={month}
                    onChange={(e) => {
                        if (e) {
                            ChangeMonth(e)
                        }
                    }}
                />
            </div>
            <div className="mb-2">
                <label className={`form-label ${style.selectLabel}`}>Year</label>
                <Select options={years} styles={SelectStyle} placeholder={<span className="select-placeholder">Choose</span>} />
            </div>
            <div className="mb-2">
                <label className={`form-label ${style.selectLabel}`}>Energy</label><br />
                <Select options={options} styles={SelectStyle} placeholder={<span className="select-placeholder">Choose</span>} />
            </div>

            <div className="mb-2">
                <label className={`form-label ${style.selectLabel}`}>Display</label>
                <Select
                    options={[
                        { value: 0, label: 'Graph' },
                        { value: 1, label: 'Table' },
                        { value: 2, label: 'Chart' }
                    ]}
                    styles={SelectStyle}
                    value={display}
                    onChange={(e) => {
                        if (e) {
                            ChangeDisplay(e)
                        }
                    }}
                />
            </div>

            <div className="mb-3">
                <label className={`form-label ${style.selectLabel}`}>Sort by</label>
                <Select options={options} styles={SelectStyle} placeholder={<span className="select-placeholder">Choose</span>} />
            </div>
        </div>
        <div className="px-4 mb-5 mt-4">
            <button className={`btn btn-danger mb-2 py-3 w-100 ${style.buttons}`}>Download Entire Document</button>
            <button className={`btn btn-outline-danger border-2 py-3 w-100 ${style.buttons}`}>Send to Email</button>
        </div>
    </div>
}