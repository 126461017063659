import { useEffect, useState, useRef } from "react";
import { Form, Formik, FormikProps, yupToFormErrors } from "formik";
import { Link } from "react-router-dom";
import { BuildingPattern, Pattern } from "../../Models/BuildingPattern";
import BuildingPatternService from "../../Services/Business";
import { useAlert } from "react-alert";
import Select from "react-select";
import { SelectStyle } from "../../Helper/ReactSelect";
import { getSites } from "../../Services/Site";
import useAxios from "axios-hooks";
import FuelUse from "../../Models/FuelUse";
import AxiosRequestFactory from "../../Components/AxiosRequestFactory";
import { PatternInputSection } from "./PatternInputSection";
import * as Yup from "yup";
import ErrorLabel from "../../Components/ErrorLabel";

const schema = Yup.object({
  siteId: Yup.number().required("Required"),
  patterns: Yup.array().of(
    Yup.lazy((item: any, options: any) => {
      return Yup.object({
        startDate: Yup.date()
          .typeError("Must be valid date")
          .required("Required"),
        endDate: Yup.date()
          .typeError("Must be valid date")
          .required("Required"),
        consumption: Yup.number().required("Required"),
        daysOnYear: Yup.number().required("Required"),
      });
    }) as any
  ),
});

const patterShouldBeValidated = (el: Pattern) => {
  return (
    (el.startDate !== undefined &&
      el.endDate !== undefined &&
      el.consumption !== undefined) ||
    el.daysOnYear !== undefined
  );
};

export default function BuildingEnergyPatterns() {
  const formikRef = useRef<FormikProps<BuildingPattern> | null>(null);
  const alert = useAlert();
  const [sites, setSites] = useState<{ value: number; label: string }[]>([]);

  const [usesReq] = useAxios<FuelUse[]>({
    url: "/utility/fuelUse",
  });

  const [patternsReq, getPatters] = useAxios<Pattern[]>(
    {
      url: "/business/patterns",
    },
    { manual: true }
  );

  const initial: BuildingPattern = {
    patterns: [],
    siteId: 0,
  };

  useEffect(() => {
    async function fetchData() {
      let result = await getSites();
      setSites(
        result.map((x: any) => ({
          label: x.name,
          value: x.id,
        }))
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    patternsReq.data?.forEach((a) => {
      if (!formikRef.current) return;

      if (!patternsReq.data) return;
      if (patternsReq.data.length === 0) return;

      if (!usesReq.data) return;
      if (usesReq.data.length === 0) return;
      const inputSectionIdx = usesReq.data.findIndex(
        (i) => a.usedInId === i.id
      );

      const patternToReuse = patternsReq.data.find(
        (i) => i.usedInId === usesReq.data?.[inputSectionIdx].id
      );

      if (!patternToReuse) return;
      formikRef.current.setFieldValue(
        `patterns[${inputSectionIdx}].startDate`,
        patternToReuse.startDate
      );
      formikRef.current.setFieldValue(
        `patterns[${inputSectionIdx}].usedInId`,
        patternToReuse.usedInId
      );
      formikRef.current.setFieldValue(
        `patterns[${inputSectionIdx}].endDate`,
        patternToReuse.endDate
      );
      formikRef.current.setFieldValue(
        `patterns[${inputSectionIdx}].consumption`,
        patternToReuse.temperature
      );
      formikRef.current.setFieldValue(
        `patterns[${inputSectionIdx}].daysOnYear`,
        patternToReuse.daysOnYear
      );
    });
  }, [patternsReq.data, usesReq.data]);

  return (
    <section className="w-55 mt-5">
      <Formik
        innerRef={(r) => (formikRef.current = r)}
        validate={(values) => {
          let errs = {};
          try {
            const forValidation = {
              ...values,
              patterns: values.patterns.filter(patterShouldBeValidated),
            };
            schema.validateSync(forValidation, { abortEarly: true });
          } catch (err) {
            errs = yupToFormErrors(err);
          }
          return errs;
        }}
        initialValues={initial}
        onSubmit={async (values) => {
          if (await BuildingPatternService(values)) {
            alert.show("Success");
          } else {
            alert.show("Failed");
          }
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => {
          const cannotSend = () => {
            return (
              values.siteId === 0 ||
              usesReq.loading === true ||
              values.patterns.length === 0
            );
          };

          return (
            <Form>
              <div
                className="card rounded-custom"
                style={{
                  filter: "drop-shadow(0px 0px 20px #E7E7E866)",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    paddingBottom: "2.2rem",
                    paddingLeft: "2.2rem",
                    paddingRight: "2.2rem",
                  }}
                >
                  <h2 className="text-center py-4 fw-bold">
                    Building Energy Profile
                  </h2>
                  <p className="text-success border-top border-success border-4 pt-2 fw-bold">
                    SITES
                  </p>

                  <div className="row">
                    <div className="col-12 text-muted mb-3">
                      <label htmlFor="siteId" className="form-label">
                        Choose Site
                      </label>
                      <Select
                        name="siteId"
                        id="siteId"
                        options={sites}
                        styles={SelectStyle}
                        placeholder={
                          <span className="select-placeholder">
                            Choose Site
                          </span>
                        }
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "siteId",
                              value: selectedOption?.value,
                            },
                          };
                          handleChange(event);
                          if (selectedOption?.value) {
                            getPatters({
                              params: { siteId: selectedOption.value },
                            });
                          }
                        }}
                        onBlur={() => {
                          handleBlur({ target: { name: "siteId" } });
                        }}
                      />
                      <ErrorLabel
                        render={
                          errors.siteId !== undefined && touched.siteId === true
                        }
                      >
                        {errors.siteId}
                      </ErrorLabel>
                    </div>
                  </div>
                  <AxiosRequestFactory request={usesReq}>
                    <AxiosRequestFactory.Data>
                      {(usesReq.data || []).map((use, idx) => {
                        return (
                          <PatternInputSection
                            key={use.use}
                            use={use}
                            index={idx}
                          />
                        );
                      })}
                    </AxiosRequestFactory.Data>
                  </AxiosRequestFactory>

                  <ErrorLabel render={typeof errors.patterns === "string"}>
                    {errors.patterns?.toString()}
                  </ErrorLabel>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 mb-5">
                <button
                  disabled={cannotSend()}
                  className="btn btn-outline-danger btn-nav"
                  type="submit"
                >
                  <i className="fas fa-save me-3"></i>SAVE
                </button>
                <span>
                  <Link
                    to="/home/settings/sites"
                    className="btn btn-danger btn-nav"
                  >
                    <i className="fas fa-long-arrow-alt-left me-1"></i>BACK{" "}
                  </Link>
                  <Link
                    to="/home/settings/utilityandemissions"
                    className="btn btn-danger ms-3 btn-nav"
                  >
                    NEXT <i className="fas fa-long-arrow-alt-right ms-1"></i>
                  </Link>
                </span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}
