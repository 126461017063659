import moment from "moment";
import { BuildingPattern } from "../Models/BuildingPattern";
import Register from "../Models/Register";
import BaseAxios from "./BaseAxios";

export default async function BuildingPatternService(params: BuildingPattern) {
  try {
    let temp:any[] = [];
    for (let i = 0; i < params.patterns.length; i++) {
      let element = params.patterns[i];
      if (element.startDate && element.endDate) {
        temp =[
          ...temp,
          {
            startDate: element.startDate,
            endDate: element.endDate,
            temperature: element.consumption,
            daysOnYear: getCount(element.startDate, element.endDate),
            usedInId: element.usedInId,
            siteId: params.siteId,
          }
        ]
      }
    }
    let result = await BaseAxios.post("/business/savePattern", temp);
    return true;
  } catch (e: any) {
    console.log(e)
    return false;
  }
}

export async function BusinessService(data: Register) {
  try {
    const {
      id = null,
      programmes,
      ...register
    } = data as Register & { id: number; programmes: any[] };
    console.log({ register });
    await BaseAxios.put("/business/", register);
    return true;
  } catch (e) {
    return false;
  }
}

const getCount = (start?: string, end?: string) => {
  var startDate = moment(start);
  var endDate = moment(end);
  return endDate.diff(startDate, "days");
};
