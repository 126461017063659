import { Site } from "../../Models/Site";
import styles from "../Tables/styles.module.css"
interface Props {
  expand: boolean;
  data: Site[];
  perPage?: number;
  currentPage: number;
}
export default function Table({ expand, data, perPage = 6, currentPage }: Props) {
  return (
    <table className={`${styles.tableReport} ${expand && styles.expand}`}>
      <thead>
        <tr>
          <th>No.</th>
          <th>Business name</th>
          <th>Property type</th>
          <th>Address</th>
          <th>Postal code</th>
          <th>Town</th>
          <th>Population</th>
          <th>Size (m2)</th>
          <th>Fuel</th>
          <th>Uses</th>
          <th>Trend</th>
        </tr>
      </thead>
      <tbody>
        {data
          .slice((currentPage - 1) * perPage, perPage * currentPage)
          .map((i) => {
            return (
              <tr key={i.id + "-row"}>
                <td>{i.id}</td>
                <td>XXX</td>
                <td>{i.type}</td>
                <td>{i.address}</td>
                <td>{i.postCode}</td>
                <td>{i.town}</td>
                <td>{i.population}</td>
                <td>{i.size}</td>
                <td>Electricity</td>
                <td>Power and Lightning</td>
                <td>
                  <i className="fas fa-long-arrow-alt-up fa-3x text-success"></i>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}