import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
export const useConvertHtmlToImg = () => {
  const convertHtmlToImg = (
    svgId: string,
    isDownloadFile: boolean = true
  ): any => {
    const svgElement: any = document.getElementById(svgId);
    svgElement!.style.backgroundColor = "white";
    htmlToImage
      .toPng(svgElement)
      .then((dataUrl) => {
        if (isDownloadFile) {
          const downloadLink = document.createElement("a");
          downloadLink.href = dataUrl;
          downloadLink.download = "Energy.png";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          return dataUrl;
        }
      })
      .catch((error) => {
        console.error("Conversion error:", error);
      });
  };

  const convertHtmlToPdf = (
    graphList: any,
    siteName: string,
    year: string,
    fuelSource: string,
    fileName: string
  ) => {
    const doc = new jsPDF();
    doc.setFont("Arial", "bold");
    doc.setFontSize(12);
    doc.text(
      `Site Name: ${siteName}, Year: ${year}, Fuel Source:  ${fuelSource}`,
      doc.internal.pageSize.getWidth() / 2,
      10,
      { align: "center" }
    );
    doc.setFont("Arial", "regular");

    doc.setFontSize(8);
    const imagePosition = [
      { x: 10, y: 50 },
      { x: 110, y: 50 },
      { x: 10, y: 110 },
      { x: 110, y: 110 },
    ];

    const convertToImg = async () => {
      for (let index = 0; index < graphList.length; index++) {
        const svgElement: any = document.getElementById(
          graphList[index]?.svgId
        );
        if (svgElement) {
          await htmlToImage.toPng(svgElement).then((imageDataURL: any) => {
            if (imageDataURL) {
              doc.text(
                graphList[index].panelTitle,
                imagePosition[index].x + 25,
                imagePosition[index].y - 5
              );
              doc.addImage(
                imageDataURL,
                "PNG",
                imagePosition[index].x,
                imagePosition[index].y,
                100,
                40
              );
            }
          });
        }
      }
    };

    convertToImg().then(() => {
      doc.save(`${fileName}-${fuelSource}-${year}.pdf`);
    });
  };
  return { convertHtmlToImg, convertHtmlToPdf };
};
