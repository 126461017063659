import { ActionTypes } from "../ActionTypes";
import { Action } from "../Actions";
import { Site } from "../../Models/Site";

const reducer = (state: Site| null = null, action: Action): Site | null => {
  switch (action.type) {
    case ActionTypes.CHANGE_SITE:
      return action.payload;
    default:
      return state;
  }
};
export default reducer;
