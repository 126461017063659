import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import Select from "react-select";
import Select2Buttons from "../Components/Select2Buttons";
import { SelectStyle, tableSelectStyle } from "../Helper/ReactSelect";
import { months, years } from "../Helper/Year";
import Logs, { Operation } from "../Models/Logs";
import { getSites } from "../Services/Site";

export default function BuildingEnergyLog() {
    const alert = useAlert()    
    const [sites, setSites] = useState<{ value: number, label: string }[]>([])

    const initial: Logs = {
        siteId: undefined,
        operations: [],
        yearId: undefined,
        monthId: undefined
    }
    useEffect(() => {
        async function fetchData() {
            let result = await getSites();
            setSites(result.map((x: any) => ({
                label: x.address,
                value: x.id
            })))
        }
        fetchData()
    }, [])

    const getWeeks = (year?: number, month?: number) => {
        if (year !== undefined && month !== undefined) {
            let monday = moment(new Date(year, month)).startOf('isoWeek').toDate();
            let endMonday = moment(new Date(year, month)).endOf('month').startOf('isoWeek').toDate();
            let week: { startDate: Date, endDate: Date }[] = []
            while (moment(monday).isSameOrBefore(moment(endMonday), 'day')) {
                week = [
                    ...week,
                    {
                        startDate: monday,
                        endDate: moment(monday).add(7, 'days').toDate()
                    }
                ]
                monday = moment(monday).add(7, 'days').toDate()
            }
            return week
        } else {
            return []
        }
    }

    return <section className="w-55 mt-5">
        <Formik
            initialValues={initial}
            onSubmit={async (values) => {
                if(!values.siteId){
                    alert.error("Site is required")
                }
                console.log(values)
            }}
        >
            {({ values,  handleBlur, handleChange }) =>
                <Form>
                    <div className="card rounded-custom" style={{
                        filter: "drop-shadow(0px 0px 20px #E7E7E866)"
                    }}>
                        <div className="card-header bg-white rounded-custom-top ">
                            <h2 className="text-center mb-0 fw-bold py-4">Building Energy Log</h2>
                        </div>
                        <div className="card-body" style={{
                            paddingBottom: "2.2rem",
                            paddingLeft: "2.2rem",
                            paddingRight: "2.2rem",
                            paddingTop: "2.2rem"
                        }}>
                            <div className="row">
                                <div className="col-12 text-muted mb-3">
                                    <label htmlFor="siteId" className="form-label">Site</label>
                                    <Select
                                        name="siteId"
                                        id="siteId"
                                        options={sites}
                                        styles={SelectStyle}
                                        placeholder={<span className="select-placeholder">Choose Site</span>}
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'siteId', value: selectedOption?.value } }
                                            handleChange(event)
                                        }}
                                        onBlur={() => {
                                            handleBlur({ target: { name: 'siteId' } })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-muted">
                                    <label className="form-label">Year</label>
                                    <Select2Buttons
                                        options={years}
                                        placeholder="years"
                                        onChange={(selectedOption: any) => {
                                            let event = { target: { name: 'yearId', value: selectedOption?.value } }
                                            handleChange(event)
                                        }}
                                    />

                                </div>
                                <div className="col-6">
                                    <label className="form-label">Month</label>
                                    <Select2Buttons
                                        options={months}
                                        placeholder="months"
                                        onChange={(selectedOption: any) => {
                                            let event = { target: { name: 'monthId', value: selectedOption?.value } }
                                            handleChange(event)
                                        }}
                                    />
                                </div>
                                <div className="col-12 mt-3">
                                    <label className="checkbox-container me-4">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <span className="label">Regular Building Operations</span>
                                    </label>
                                    <label className="checkbox-container">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                        <span className="label">Irregular Building Operations</span>
                                    </label>
                                </div>
                            </div>
                            <p className="text-success border-top border-success border-4 pt-2 mt-5 mb-4 fw-bold">
                                REGULAR BUILDING OPERATIONS
                            </p>
                            <table className="table table-striped mt-2 table-bordered">
                                <thead className="fs-12">
                                    <tr>
                                        <th className="border-end text-white text-center fw-semibold">Date/ Weekly format </th>
                                        <th className="text-center border-end text-white fw-semibold">Heating</th>
                                        <th className="text-center border-end text-white fw-semibold">Cooling</th>
                                        <th className="text-center border-end text-white fw-semibold">Lighting</th>
                                        <th className="text-center border-end text-white fw-semibold">Power</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr></tr>
                                    {
                                        getWeeks(values.yearId, values.monthId).map((x, i) => (
                                            <tr>
                                                <th className="align-middle py-4 text-center">
                                                    {
                                                        moment(x.startDate).format("DD MMMM")
                                                    }
                                                    -
                                                    {
                                                        moment(x.endDate).format("DD MMMM")
                                                    }
                                                </th>
                                                <td className="text-center align-middle p-0" style={{
                                                    height: "1px"
                                                }}>
                                                    <textarea
                                                        name={`operations.${x.startDate.toDateString()}.1`}
                                                        onChange={handleChange}
                                                        className="form-сontrol shadow-none h-100 w-100 bg-transparent rounded-0 border-0"
                                                    />
                                                </td>
                                                <td className="text-center align-middle p-0" style={{
                                                    height: "1px"
                                                }}>
                                                    <textarea
                                                        name={`operations.${x.startDate.toDateString()}.2`}
                                                        onChange={handleChange}
                                                        className="form-сontrol shadow-none h-100 w-100 bg-transparent rounded-0 border-0" />
                                                </td>
                                                <td className="text-center align-middle p-0" style={{
                                                    height: "1px"
                                                }}>
                                                    <textarea
                                                        name={`operations.${x.startDate.toDateString()}.3`}
                                                        onChange={handleChange}
                                                        className="form-сontrol shadow-none h-100 w-100 bg-transparent rounded-0 border-0" />
                                                </td>

                                                <td className="text-center align-middle p-0" style={{
                                                    height: "1px"
                                                }}>
                                                    <textarea
                                                        name={`operations.${x.startDate.toDateString()}.4`}
                                                        onChange={handleChange}
                                                        className="form-сontrol shadow-none h-100 w-100 bg-transparent rounded-0 border-0" />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <p className="text-success border-top border-success border-4 mb-4 pt-2 mb-4 mt-5 fw-bold">
                                BUILDING USERS
                            </p>
                            <table className="table table-striped mt-2 table-bordered">
                                <thead className='fs-12'>
                                    <tr>
                                        <th className="border-end text-white text-center date fw-semibold">Month </th>
                                        <th className="text-center border-end text-white date fw-semibold">Year</th>
                                        <th className="text-center border-end text-white fw-semibold">Regular office users</th>
                                        <th className="text-center border-end text-white fw-semibold">Irregular office users</th>
                                        <th className="unset px-0"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr>
                                        <td className="text-center align-middle p-0" style={{
                                            height: "1px"
                                        }}>
                                            <Select options={months} styles={tableSelectStyle} classNamePrefix="bold" placeholder={<span className="select-placeholder">Choose</span>} />
                                        </td>
                                        <td className="text-center align-middle p-0" style={{
                                            height: "1px"
                                        }}>
                                            <Select options={years} styles={tableSelectStyle} placeholder={<span className="select-placeholder">Choose</span>} />
                                        </td>
                                        <td className="text-center align-middle p-0" style={{
                                            height: "1px"
                                        }}>
                                            <input className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0" type="number" />
                                        </td>
                                        <td className="text-center align-middle p-0" style={{
                                            height: "1px"
                                        }}>
                                            <input className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0" type="number" />
                                        </td>
                                        <td className="px-0 text-center">
                                            <button className="btn btn-transparent shadow-none p-0">
                                                <i className="fas fa-times text-danger fs-3"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="fw-bold align-middle">
                                            <button className="btn shadow-none fw-bold d-flex mx-auto align-items-center text-nowrap" type="button">
                                                Add Other
                                                <i className="fas fa-plus-circle text-danger fs-3 ms-1"></i>
                                            </button>
                                        </th>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3 mb-5">
                        <button className="btn btn-outline-danger btn-nav" type="submit">
                            <i className="fas fa-save me-3"></i>SAVE
                        </button>
                        <span>
                            <Link to="/home/settings/utilityandemissions" className="btn btn-danger btn-nav"><i className="fas fa-long-arrow-alt-left me-1"></i>BACK </Link>
                            <Link to="/home/settings/reviewandprogrammes" className="btn btn-danger ms-3 btn-nav">NEXT <i className="fas fa-long-arrow-alt-right ms-1"></i></Link>
                        </span>
                    </div>
                </Form>
            }
        </Formik>
    </section>
}