import style from "../analytics.module.css";
import Select from "react-select";
import { SelectStyle } from "../../Helper/ReactSelect";
import { months, years } from "../../Helper/Year";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators, State } from "../../State";
import ReportState from "./ReportState";
import SiteList from "../SideMenu/Site";
import { loggedIn } from "../../Services/Auth";
import { BaseOption } from "../../Components/FuelSourceSelect";

export default function Reports() {
  const isLoggedIn = loggedIn();
  const dispatch = useDispatch();

  const state = ReportState();

  const site = useSelector((state: State) => state.site);
  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const display = useSelector((state: State) => state.display);
  const fuelSource = useSelector((state: State) => state.fuelSource);

  const { ChangeDisplay, ChangeMonth, ChangeYear, ChangeFuelSource } =
    bindActionCreators(ActionCreators, dispatch);

  return (
    <div className={style.rightSideBar}>
      {!isLoggedIn && (
        <div className={style.login}>
          <button className="btn btn-success btn-radius px-4 py-2">
            <i className="fas fa-user me-1"></i>
            Login
          </button>
        </div>
      )}
      <SiteList sites={state.sites} currentSite={site} />

      <div className="px-4 my-3 flex-fill">
        <div className="mb-2 mt-2">
          <label className={`form-label ${style.selectLabel}`}>Month</label>
          <Select
            options={months}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={month}
            onChange={(e) => {
              if (e) {
                ChangeMonth(e);
              }
            }}
          />
        </div>
        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>Year</label>
          <Select
            options={years}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={year}
            onChange={(e) => {
              if (e) {
                ChangeYear(e);
              }
            }}
          />
        </div>

        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>
            Fuel Source
          </label>
          <Select
            options={state.fuelSources
              .map((i) => ({
                value: i.id,
                label: i.source,
              }))
              .concat([BaseOption])}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={fuelSource}
            onChange={(e) => {
              if (e) {
                ChangeFuelSource(e);
              }
            }}
          />
        </div>

        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>Display</label>
          <Select
            options={[
              { value: 0, label: "Graph" },
              { value: 1, label: "Table" },
              { value: 2, label: "Chart" },
            ]}
            styles={SelectStyle}
            value={display}
            onChange={(e) => {
              if (e) {
                ChangeDisplay(e);
              }
            }}
          />
        </div>
        <div className="mb-3">
          <label className={`form-label ${style.selectLabel}`}>Sort by</label>
          <Select
            options={[
              { value: 0, label: "Highest Consumption" },
              { value: 1, label: "Lowest Consumption" },
            ]}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            defaultValue={{ value: 0, label: "Highest Consumption" }}
          />
        </div>
      </div>

      <div className="px-4 mb-5 mt-4">
        <button className={`btn btn-danger mb-2 py-3 w-100 ${style.buttons}`}>
          Download Entire Document
        </button>
        <button
          className={`btn btn-outline-danger border-2 py-3 w-100 ${style.buttons}`}
        >
          Send to Email
        </button>
      </div>
    </div>
  );
}
