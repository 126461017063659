import SiteList from "./Components/SiteList";

export default function UserManagement() {
    
    return <section className="w-55 mt-5">
        <form >
            <div className="card rounded-custom" style={{
                filter: "drop-shadow(0px 0px 20px #E7E7E866)"
            }}>
                <div className="card-body" style={{
                    paddingBottom:"2.2rem",
                    paddingLeft:"2.2rem",
                    paddingRight:"2.2rem"
                }}>
                    <h2 className="text-center py-4 fw-bold mb-0">User Management</h2>                    
                    <p className="text-success border-top border-success border-4 pt-2 fw-bold">
                        MONTHLY READS
                    </p>
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-danger w-100 py-4">FILL A FORM</button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-success-primary w-100 py-4">UPLOAD</button>
                        </div>

                    </div>
                    <p className="text-success border-top border-success border-4 pt-2 mt-5 fw-bold mb-1">
                        SITE LIST
                    </p>
                    <SiteList />
                </div>
            </div>
        </form>
    </section>
}
