import format from "date-fns/format/index";
import parse from "date-fns/parse/index";
import moment from "moment";

const monthNumberToLabelMap = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export default function ChartDates(date: Date) {
  return format(date, "MMM, d");
}

export function FromChartDates(date: string) {
  return parse(date, "MMM, d", new Date());
}

export function ChartYears(date: Date) {
  return `${monthNumberToLabelMap[date.getMonth() as 1]}, ${date.getFullYear().toString().slice(2,4)}`
}

export function Month(date: Date) {
  return moment(date).format("MMM");
}

export function getFullMonthName(date: Date) {
  return moment(date).format("MMMM");
}
