import { Link } from "react-router-dom";
import SiteList from "../Components/SiteList";

export default function Sites() {
  
  return (
    <section className="w-55 my-5">
      <div
        className="card rounded-custom"
        style={{
          filter: "drop-shadow(0px 0px 20px #E7E7E866)",
        }}
      >
        <div className="card-header bg-white rounded-custom-top py-4">
          <h2 className="text-center mb-0 fw-bold">Sites</h2>
        </div>
        <div
          className="card-body"
          style={{
            paddingBottom: "2.2rem",
            paddingLeft: "2.2rem",
            paddingRight: "2.2rem",
          }}
        >
          <SiteList />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3 mb-5">
        <span>
          <Link to="/home/settings/business" className="btn btn-danger btn-nav">
            <i className="fas fa-long-arrow-alt-left me-1"></i>BACK{" "}
          </Link>
          <Link
            to="/home/settings/building-energy-patterns"
            className="btn btn-danger ms-3 btn-nav"
          >
            NEXT <i className="fas fa-long-arrow-alt-right ms-1"></i>
          </Link>
        </span>
      </div>
    </section>
  );
}
