import style from "../analytics.module.css";
import Select from "react-select";
import { SelectStyle } from "../../Helper/ReactSelect";
import { months, years } from "../../Helper/Year";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators, State } from "../../State";
import PortfolioState from "./PortfolioState";
import { useEffect } from "react";
import FuelSource from "../../Models/FuelSource";
import SiteList from "../SideMenu/Site";
import { loggedIn } from "../../Services/Auth";

export default function Portfolio() {
  const dispatch = useDispatch();
  const { ChangeMonth, ChangeYear, ChangeFuelSource } = bindActionCreators(
    ActionCreators,
    dispatch
  );
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const year = useSelector((state: State) => state.year);
  const site = useSelector((state: State) => state.site);

  const state = PortfolioState();

  useEffect(() => {
    state.getFuelSource()
      .then(({ data }) => {
        ChangeFuelSource({ value: data[0].id, label: data[0].source })
      })
  }, []);

  const onCheck = (i: FuelSource) => () => ChangeFuelSource({ value: i.id, label: i.source });

  return (
    <div className={style.rightSideBar}>
      <div className={state.isLoading ? style.baseMenuBusy : "d-none"}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      {loggedIn() === false && (
        <div className={style.login}>
          <button className="btn btn-success btn-radius px-4 py-2">
            <i className="fas fa-user me-1"></i>
            Login
          </button>
        </div>)}
      <SiteList sites={state.sites} currentSite={site} />
      <div className="px-4 pb-3 border-bottom flex-fill">
        <div>
          <label className={`form-label ${style.selectLabel}`}>Month</label>
          <Select
            options={months}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={month}
            onChange={(e) => {
              if (e) {
                ChangeMonth(e);
              }
            }}
          />
        </div>
        <div className="my-5">
          <label className={`form-label ${style.selectLabel}`}>Year</label>
          <Select
            options={years}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={year}
            onChange={(e) => {
              if (e) {
                ChangeYear(e);
              }
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-column pt-4 px-4">
        {state.fuelSources.map((i) => {
          return (
            <label key={i.source} className="checkbox-container mb-1">
              <input
                checked={fuelSource?.value === i.id}
                onChange={onCheck(i)}
                type="checkbox"
              />
              <span className="checkmark"></span>
              <span className="label">{i.source}</span>
            </label>
          );
        })}
      </div>
      <div className="px-4 mb-5 mt-4">
        <button className={`btn btn-danger mb-2 py-3 w-100 ${style.buttons}`}>
          Download Entire Document
        </button>
        <button
          className={`btn btn-outline-danger border-2 py-3 w-100 ${style.buttons}`}
        >
          Send to Email
        </button>
      </div>
    </div>
  );
}
