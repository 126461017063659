import SideBar from "./SideBar";
import style from "./analytics.module.css"
import TopMenu from "./TopMenu";
interface Props {
    children: React.ReactNode;
    rightSideBar?: React.ReactNode;
}
export default function Layout({ children, rightSideBar }: Props) {
    
    return <>
        <SideBar />
        <div className={style.main}>
            <TopMenu />
            {children}
        </div>
        <div className={style.rightSideBarContainer}>
            {rightSideBar}
        </div>
    </>
}