import Login from "../Models/Login";
import Register from "../Models/Register";
import BaseAxios from "./BaseAxios";

export async function RegisterService(register: Register) {
  try {
    const response = await BaseAxios.post("/auth", register);
    console.log(response);
  } catch (e: any) {
    return Promise.reject(e.response.data.message);
  }
}

export function loggedIn() {
  return localStorage.getItem("jwt") ? true : false;
}

export function getUserData() {
  const u = localStorage.getItem("user");
  return u ? JSON.parse(u) : undefined;
}

export function getToken() {
  const jwt = localStorage.getItem("jwt");
  return jwt;
}
export function Logout() {
  localStorage.removeItem("jwt");
}
