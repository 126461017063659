const sectors = [
  "Administraive and Support Service Activities",
  "Arts, Entertainment and Recreation",
  "Charity",
  "Consultancy",
  "Construction",
  "Corporate",
  "Education",
  "Energy",
  "Engineering",
  "Financial and Insurance A",
  "Health Care",
  "Hospitality",
  "Industrial",
  "Manufacturing",
  "Production",
  "Professional, Scientific and Technical Activities",
  "Public Authority",
  "Real Estate",
  "Service",
  "SMEs",
  "Telecommunication",
  "Transportation",
  "Wholesale and Retail Trade",
]
export default sectors;
