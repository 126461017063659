import { NavLink, useHistory } from "react-router-dom";
import { loggedIn, Logout } from "./Services/Auth";

interface Props {
  children: React.ReactNode;
}
export default function Layout({ children }: Props) {
  const history = useHistory()
  return (
    <>
      <header
        className="bg-white d-flex position-fixed top-0 w-100 justify-content-between align-items-center"
        style={{
          height: "128px",
          paddingLeft: "7%",
          paddingRight: "7%",
          zIndex: 9,
          filter: "drop-shadow(0px 3px 16px rgb(0,0,0,0.14))"
        }}
      >
        <span><img src="/icons/gpad_logo_2.png" alt="icons" className="img-fluid" /></span>
        <nav className="d-flex h-100 fs-16">
          <NavLink
            to="/home"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            Home
          </NavLink>
          <NavLink
            to="/About"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
          >
            About
          </NavLink>
          <NavLink
            to="/Recognitions"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            Recognitions
          </NavLink>
          <NavLink
            to="/Work"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            How does it work?
          </NavLink>
          <NavLink
            to="/Progress"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            Progress
          </NavLink>
          <NavLink
            to="/Team"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            Team
          </NavLink>
          <NavLink
            to="/Blog"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            Blog
          </NavLink>
          <NavLink
            to="/Gallery"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            Gallery
          </NavLink>
          <NavLink
            to="/Contact"
            className="px-4 mx-1 d-flex align-items-center fw-bold"
            activeClassName="active"
          >
            Contact us
          </NavLink>
        </nav>
        {
          loggedIn() ?
            <button 
            className="btn btn-success btn-radius px-4 py-2"
            onClick={()=>{
              Logout()
              history.push("/login")
            }}
            >
              <i className="fas fa-user me-1"></i>
              Logout
            </button> :
            <NavLink
              to="/login"
              className="btn btn-success btn-radius px-4 py-2">
              <i className="fas fa-user me-1"></i>
              Login
            </NavLink>
        }
      </header>
      <main
        style={{
          marginTop: "128px",
        }}
        className="d-flex flex-column align-items-center"
      >
        {children}
      </main>
      <footer
        className="mt-5 border-top border-2 border-dark"
      >
        <div className="bg-white py-3" style={{
          paddingLeft: "7%",
          paddingRight: "7%",
          filter: "drop-shadow(0px 3px 22px #9F9F9F2E)"
        }}>
          <div className="row justify-content-between" style={{
            color: '#4D505C'
          }}>
            <div className="col-2"><img src="/icons/gpad_logo_2.png" alt="icons" className="img-fluid" /></div>

            <div className="col-4">
              <p className="fw-bold">Sign up for our newsletter</p>
            </div>

            <div className="col-2 fw-bold">Contact</div>

            <div className="col-1 text-end fw-bold">Follow us</div>
          </div>
          <div className="row justify-content-between" style={{
            color: "#BFC2C5"
          }}>
            <div className="col-2">
              <p>
                Empowering businesses and homes<br />
                to reduce energy consumption and<br />
                carbon emmissions as well as<br />
                improving their green consumptions.
              </p>
            </div>
            <div className="col-4 d-flex mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                style={{
                  maxHeight: "80px"
                }}
              />
              <button className="btn btn-primary ms-3" style={{
                borderRadius: "10px",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                maxHeight: "80px"
              }}>SUBSCRIBE</button>
            </div>
            <div className="col-2">
              <p>
                GreenPad Enterprise
                <br />
                c/o EnergiePad, 24 Halborn Viaduct
                <br />
                London EC1A 2BN
                <br />
                info@energiepad.com
              </p>
            </div>
            <div className="col-1 text-end" style={{
              color: "black"
            }}>
              <i className="fab fa-instagram"></i>
              <i className="fab fa-twitter mx-2"></i>
              <i className="fab fa-facebook-f"></i>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between py-3" style={{
          paddingLeft: "7%",
          paddingRight: "7%",
          color: "#BFC2C5"
        }}>
          <p className="mb-0">Privacy Policy</p>
          <p className="mb-0">
            &copy; {new Date().getFullYear()} Energy pad. All rights reserved
          </p>
        </div>
      </footer>
    </>
  );
}
