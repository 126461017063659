import { Consumption } from "../Models/Consumption";
import BaseAxios from "./BaseAxios";
import moment from "moment";
import Emission from "../Models/Emission";
import { M_T } from "../Models/M_T";
export default async function EmissionService(consumption: Consumption[]) {
  /*try {
    await consumption.map(async (x, i) => {
      var transformed = {
        date: moment(new Date(x.year, x.month, 1)).format("YYYY-MM-DD"),
        consumption: x.consumption,
        cost: x.cost,
      };
      console.log(transformed);
      let response = await BaseAxios.post(
        `/utility/addEmission/1/`,
        transformed
      );
      console.log(response);
    });
    return true;
  } catch (e: any) {
    console.log(e);
    return false;
  }*/
}
export const FuelUnitEnum: Record<number, string> = {
  0: "kWh",
  1: "L",
  2: "m3",
};

export async function AddConsumption(
  consumption: Consumption[],
  month: number,
  year: number,
  siteId: number,
  uses: number[]
) {
  try {
    const data = consumption
      .map((x) => {
        const fuelUnit = FuelUnitEnum[Number.parseInt(x.fuelUnit)];
        const vals = [];
        const singleVal = {
          consumption: Number.parseFloat(x.consumption.toString()),
          fuelSourceId: x.fuelSourceId,

          totalCost: x.totalCost,
          vat: x.vat,
          conversionFactor: x.conversionFactor,
          fuelUnit: fuelUnit ? fuelUnit : -1,
          siteId: siteId,
          usedInId: uses,
        };

        if (month === 12) {
          for (let i = 0; i < 12; i++) {
            vals.push({
              ...singleVal,
              date: moment(new Date(year, i, 1)).format("YYYY-MM-DD"),
            });
          }
        } else {
          vals.push({
            ...singleVal,
            date: moment(new Date(year, month, 1)).format("YYYY-MM-DD"),
          });
        }
        return vals;
      })
      .flat();

    await BaseAxios.post(`utility/addConsumption`, data);
    return true;
  } catch (e: any) {
    return false;
  }
}

export async function AddEmission(
  emission: Emission[],
  siteId: number,
  year: number,
  month: number,
  uses: number[]
) {
  try {
    const data = emission
      .map((x, i) => {
        const fuelUnit = FuelUnitEnum[Number.parseInt(x.fuelUnit)];
        const vals = [];
        const currentVal = {
          consumption: x.consumption,
          emissionFactor: x.emissionFactor,
          conversionFactor: x.conversionFactor,
          fuelUnit: fuelUnit ? fuelUnit : -1,
          siteId: siteId,
          fuelSourceId: x.fuelSourceId,
          usedInId: uses,
        };

        if (month === 12) {
          for (let i = 0; i < 12; i++) {
            vals.push({
              ...currentVal,
              date: moment(new Date(year, i, 1)).format("YYYY-MM-DD"),
            });
          }
        } else {
          vals.push({
            ...currentVal,
            date: moment(new Date(year, month, 1)).format("YYYY-MM-DD"),
          });
        }
        return vals;
      })
      .flat();

    await BaseAxios.post(`utility/addEmission`, data);
    return true;
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function AddMonitoring(
  records: M_T[],
  siteId: number,
  year: number,
  month: number,
  uses: number[]
) {
  try {
    const data = records
      .map((x, i) => {
        const fuelUnit = FuelUnitEnum[Number.parseInt(x.fuelUnit)];
        const vals = [];
        const currentVal = {
          carbon: x.carbon,
          energy: x.energy,
          conversionFactor: x.conversionFactor,
          fuelUnit: fuelUnit ? fuelUnit : -1,
          siteId: siteId,
          fuelSourceId: x.fuelSourceId,
          usedInId: uses,
        };

        if (month === 12) {
          for (let i = 0; i < 12; i++) {
            vals.push({
              ...currentVal,
              date: moment(new Date(year, i, 1)).format("YYYY-MM-DD"),
            });
          }
        } else {
          vals.push({
            ...currentVal,
            date: moment(new Date(year, month, 1)).format("YYYY-MM-DD"),
          });
        }
        return vals;
      })
      .flat();

    await BaseAxios.post(`utility/addMonitoring`, data);
    return true;
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function ConsumptionImport(file: File) {
  let data = new FormData();
  data.append("excel", file);

  try {
    let value = await BaseAxios.post("/utility/importUtility/", data);
    console.log(value);
    return true;
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export const DownloadPngImage = (svgId: string) => {
  const svgElement: any = document.getElementById(svgId);
  const height = svgElement?.getBoundingClientRect().height.toFixed(0);
  const width = svgElement?.getBoundingClientRect().width.toFixed(0);

  const canvas = document.createElement("canvas");
  canvas.height = height;
  canvas.width = width;
  const ctx: any = canvas.getContext("2d");
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, width, height);
  const svgString = new XMLSerializer().serializeToString(svgElement);

  const image = new Image();

  image.src = `data:image/svg+xml;base64,${window.btoa(svgString)}`;
  image.onload = () => {
    ctx!.drawImage(image, 0, 0);
    const dataURL = canvas!.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.href = dataURL;
    downloadLink.download = "output.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
};
