import { useEffect, useMemo, useState } from "react";
import Card from "../Card";
import style from "../analytics.module.css";
import Layout from "../Layout";
import GroupedHorizontal from "../Charts/Bars/VerticalGrouped";
import LineChart, { LineChartDataSet } from "../Charts/Lines/LineChart";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators, State } from "../../State";
import Table from "./CarbonEmissionsTable";
import PieChart from "./Carbon";
import Thermometer, { toThermometerData } from "../Charts/Bars/Thermometer";
import OverlappingInfo from "../OverlappingInfo";
import SideMenuState from "../UtilityLeague/LeageState";
import IncreasedPercentage from "../UtilityLeague/IncreasedPercentage";
import CFootPrint from "../SideMenu/carbonfootprint";
import DoubleBarChart from "../Charts/Bars/StackedSigns";
import Option from "../../Models/Option";
import { useConvertHtmlToImg } from "../../Hooks/useConvertHtmlToImg";
import { bindActionCreators } from "redux";
import useQueryManager from "../../Hooks/useQueryManager";
import { differenceInMonths } from "date-fns";
import { formatISO } from "date-fns/fp";

const ChangeDisplay = (
  value: number,
  expand: boolean,
  selectedIndex: number,
  data: LineChartDataSet[][],
  loading: boolean
) => {
  switch (value) {
    case 0:
      return (
        <LineChart
          loading={loading}
          expand={expand}
          selectedIndex={selectedIndex}
          dataSet={data}
          isCallingFromCarbonFootPrint={true}
        />
      );
    case 1:
      return (
        <Table
          loading={loading}
          data={data[0]}
          expand={expand}
          selectedIndex={selectedIndex}
        />
      );
    case 2: {
      const currentValue =
        data &&
        data.length > 0 &&
        data[0].find((i) => i.date.getMonth() === selectedIndex);
      let dataParam = [{ name: "empty", value: 0 }];
      if (currentValue) {
        dataParam = [
          {
            name: formatISO(currentValue.date),
            value: currentValue.value,
          },
          { name: "emptySide", value: 10000 },
        ];
      }
      return <PieChart loading={loading} data={dataParam} />;
    }
    default:
      return <div></div>;
  }
};

export default function CarbonFootprints() {
  const urlQuery = useQueryManager();
  const [fullFirst, setFullFirst] = useState(false);
  const [fullSecond, setFullSecond] = useState(false);
  const [fullThird, setFullThird] = useState(false);
  const [fullFourth, setFullFourth] = useState(false);
  const display = useSelector((state: State) => state.display);
  const month = useSelector((state: State) => state.month);
  const year = useSelector((state: State) => state.year);
  const site = useSelector((state: State) => state.site);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const { convertHtmlToImg } = useConvertHtmlToImg();

  const siteParam = useMemo(() => urlQuery.get("site"), [urlQuery.get("site")]);
  const fuelParam = useMemo(() => urlQuery.get("fuel"), [urlQuery.get("fuel")]);

  const dispatch = useDispatch();
  const { ChangeSite, ChangeFuelSource } = bindActionCreators(
    ActionCreators,
    dispatch
  );
  const sideMenuState = SideMenuState();

  useEffect(() => {
    if (!siteParam && !site && sideMenuState?.sites?.length > 0) {
      ChangeSite(sideMenuState.sites[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenuState.sites]);

  useEffect(() => {
    const thereIsFuelSources = sideMenuState.fuelSources.length > 0;
    const thereIsCarbonFootprint = sideMenuState.carbonFoorPrint.flat().length > 0;
    if (!fuelParam && thereIsFuelSources && thereIsCarbonFootprint) {
      const firstConsumption = sideMenuState.carbonFoorPrint.flat()[0];
      const item = sideMenuState.fuelSources.find(
        (f) => f.id === firstConsumption.fuelSourceId
      );
      if (item) {
        ChangeFuelSource({ value: item.id, label: item.source });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenuState.fuelSources, sideMenuState.isLoading]);

  useEffect(() => {
    sideMenuState.fetchSites();
  }, []);

  useEffect(() => {
    if (!year) return;

    sideMenuState.fetchCarbonFootPrint({ year, site });

    if (month?.value < 2) {
      const getCarbonFootPrintPreviousData = async () => {
        const previosYear: Option = {
          value: year.value - 1,
          label: (Number(year.label) - 1).toString(),
        };
        const requestParams = {
          year: previosYear,
          site,
        };
        sideMenuState.fetchCarbonFootPrintPreviousYear(requestParams);
      };
      if (site && fuelSource) {
        getCarbonFootPrintPreviousData();
      }
    }
  }, [site, year]);

  const getSelectedSiteData = (arrayList: any) => {
    if (arrayList.length === 0) {
      return [];
    }
    const filteredData: any = [];

    arrayList.forEach((element: any) => {
      if (element) {
        const temp = element.filter((x: any) =>
          site === null || site.name === ""
            ? true
            : x.siteName.trim() === site?.name.trim()
        );
        filteredData.push(temp);
      }
    });
    return filteredData;
  };

  const getPreviousThreeMonthData = (
    data: any,
    selectedMonth: number,
  ) => {
    const filtered = getSelectedSiteData(data).flat()
      const records = filtered.filter((r: any) => {
        const recordDate = r.date;
        const currentDate = new Date();
        currentDate.setMonth(selectedMonth - 1);
        currentDate.setFullYear(recordDate.getFullYear());
        const diff = differenceInMonths(currentDate, recordDate)
        return diff < 3;
      });
      return records.sort((a: any, b: any) => a.date.getTime() - b.date.getTime());
  };

  const getData = (data: any): any => {
    if (data.length === 0) {
      return [];
    }
    const finalResult = [];
    for (let i = 0; i < data[0].length; i++) {
      const listItem = [];
      for (let j = 0; j < data.length; j++) {
        listItem.push(data[j][i]);
      }
      finalResult.push(
        listItem.sort(function (a: any, b: any) {
          return a.date - b.date;
        })
      );
    }
    return finalResult;
  };

  const removeDuplicates = (arr: any) => {
    let unique = arr.reduce((acc: any, curr: any) => {
      if (!acc.map((x: any) => x.fuelSourceId).includes(curr.fuelSourceId))
        acc.push(curr);
      return acc;
    }, []);
    return unique;
  };

  const getFooterEmissionsValue = () => {
    if (
      sideMenuState.getUniqueEmissions() &&
      sideMenuState.getUniqueEmissions().length > 0
    ) {
      return removeDuplicates(sideMenuState.getUniqueEmissions()[0]);
    } else if (
      sideMenuState.getUniqueEmissionsPreviousYear() &&
      sideMenuState.getUniqueEmissionsPreviousYear().length > 0
    ) {
      return removeDuplicates(
        sideMenuState.getUniqueEmissionsPreviousYear()[0]
      );
    } else {
      return [];
    }
  };

  const handleDownloadSvg = () => {
    switch (display.value) {
      case 0:
        return convertHtmlToImg("consumption");
      case 1:
        return convertHtmlToImg("carbonEmissionTable");
      case 2:
        return convertHtmlToImg("chatGraph");
      default:
        return convertHtmlToImg("consumption");
    }
  };

  return (
    <Layout rightSideBar={<CFootPrint />}>
      <div className="flex-fill d-flex flex-column">
        <h4 className={`${style.sectionHeader} mt-4 text-center`}>
          Carbon Footprints
        </h4>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <div
            className={`${style.quaterFirst} ${fullFirst && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFirst(!fullFirst)}
              isDisabledDownloadBtn={
                getData(sideMenuState.carbonFoorPrint).length === 0 ||
                (getData(sideMenuState.carbonFoorPrint).length > 0 &&
                  getData(sideMenuState.carbonFoorPrint)[0].length === 0)
              }
              onDownloadClick={() => handleDownloadSvg()}
              title="Carbon Emissions (kg)"
              expanded={fullFirst}
              year={year.value}
              top={
                <div className="d-flex align-items-center mb-2">
                  <div
                    className={`rounded-circle text-center p-2 d-flex flex-column 
                                    ${
                                      display.value === 2
                                        ? "me-auto"
                                        : "ms-auto"
                                    }`}
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>
                      {sideMenuState.selectedMonthDaysAmount}
                    </span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  {display.value !== 2 && (
                    <div className="mx-2 d-flex flex-column align-items-end">
                      <span>
                        {sideMenuState.getFootPrintForMonth(month.value)
                          ?.carbonEmission || 0}
                        kg
                      </span>
                      <IncreasedPercentage
                        className={style.sm9lg17}
                        percentage={
                          sideMenuState.getFootPrintForMonth(month.value)
                            ?.increasedConsumptionPercentage
                        }
                      />
                    </div>
                  )}
                  <OverlappingInfo
                    text1="Average emission per day"
                    value1={`${
                      sideMenuState.getFootPrintForMonth(month.value)
                        ?.averageEmissionPerDay || 0
                    }kg`}
                    color1="#4989C6"
                    text2="Emissions this month"
                    value2={`${
                      sideMenuState.getFootPrintForMonth(month.value)
                        ?.carbonEmission || 0
                    }kg`}
                    color2="#4D505C"
                  />
                </div>
              }
              bottom={
                <div className="d-flex align-items-end mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={style.legend}>
                    {fuelSource?.label} Emissions {year.value}
                  </span>
                  {display.value === 2 && (
                    <div className="ms-auto d-flex flex-column align-items-end">
                      <span
                        style={{
                          fontSize: "26px",
                        }}
                      >
                        {sideMenuState.getFootPrintForMonth(month.value)
                          ?.carbonEmission || 0}
                        kg
                      </span>
                      <IncreasedPercentage
                        percentage={
                          sideMenuState.getFootPrintForMonth(month.value)
                            ?.increasedConsumptionPercentage
                        }
                      />
                    </div>
                  )}
                </div>
              }
            >
              {ChangeDisplay(
                display.value,
                fullFirst,
                month.value,
                getData(sideMenuState.carbonFoorPrint),
                sideMenuState.isLoading
              )}
            </Card>
          </div>
          <div
            className={`${style.quaterSecond} ${fullSecond && style.full} p-3`}
          >
            <Card
              onClick={() => setFullSecond(!fullSecond)}
              onDownloadClick={() => convertHtmlToImg("baseline")}
              isDisabledDownloadBtn={
                sideMenuState.carbonFoorPrint.flat().length === 0
              }
              title="Carbon Baseline (kg)"
              expanded={fullSecond}
              top={
                <div className="d-flex align-items-center">
                  <div
                    className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>
                      {sideMenuState.selectedMonthDaysAmount}
                    </span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  <IncreasedPercentage
                    className={`${style.sm12lg17} mx-2`}
                    percentage={
                      sideMenuState.getFootPrintForMonth(month.value)
                        ?.increasedConsumptionPercentage
                    }
                  />
                  <OverlappingInfo
                    text1="Target Monthly Emissions"
                    value1="20 kg"
                    color1="#94C11F"
                    text2="Actual Monthly Emissions"
                    value2="180 kg"
                    color2="#4989C6"
                  />
                </div>
              }
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={`${style.legend} me-3`}>
                    Actual {fuelSource?.label} Consumption {year.value}
                  </span>
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#94C11F",
                    }}
                  />
                  <span className={style.legend}>
                    Target {fuelSource?.label} Consumption {year.value}
                  </span>
                </div>
              }
            >
              <DoubleBarChart
                expand={fullSecond}
                selectedIndex={month.value}
                energyTargetsRecords={sideMenuState.carbonFoorPrint.map((r) => {
                  return r.map((i: any) => {
                    return {
                      ...i,
                      consumption: i.carbonEmission,
                      targetEnergy: i.carbonTarget,
                    };
                  })
                }).sort((a: any,b: any) => {
                  if (a.length === 0 || b.length === 0) return 1;
                  
                  return a[0].date.getTime() - b[0].date.getTime();
                })}
                loading={false}
              />
            </Card>
          </div>

          <div
            className={`${style.quaterThird} ${fullThird && style.full} p-3`}
          >
            <Card
              onClick={() => setFullThird(!fullThird)}
              title={"Carbon Gauge(Emissions in the last 3 months)"}
              expanded={fullThird}
              isDisabledDownloadBtn={
                getPreviousThreeMonthData(
                  sideMenuState.carbonFoorPrint,
                  month.value,
                ).length === 0
              }
              onDownloadClick={() => convertHtmlToImg("carbonGauge")}
            >
              <Thermometer
                loading={sideMenuState.isLoading}
                expand={fullThird}
                data={getPreviousThreeMonthData(
                  sideMenuState.carbonFoorPrint,
                  month.value,
                ).map(
                  toThermometerData({
                    date: (i: any) => i.date,
                    value: (i) => {
                      return Number.parseFloat(((i.carbonEmission / i.carbonTarget) * 100).toFixed(2));
                    },
                  })
                )}
              />
            </Card>
          </div>

          <div
            className={`${style.quaterFourth} ${fullFourth && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFourth(!fullFourth)}
              onDownloadClick={() => convertHtmlToImg("emissions")}
              isDisabledDownloadBtn={
                getPreviousThreeMonthData(
                  sideMenuState.carbonFoorPrint,
                  month.value,
                ).length === 0
              }
              title="Utilities (Emissions in the last 3 months)"
              expanded={fullFourth}
              bottom={
                <div className="d-flex align-items-center mt-2 justify-content-between">
                  {getFooterEmissionsValue().map((i: any) => {
                    return (
                      <div
                        key={i?.fuelSourceName}
                        className="d-flex align-items-center"
                      >
                        <span
                          className={style.legendCircle}
                          style={{
                            backgroundColor: i?.fuelSourceColorCode,
                          }}
                        />
                        <span className={`${style.legend} me-3`}>
                          {i?.fuelSourceName}
                        </span>
                      </div>
                    );
                  })}
                </div>
              }
            >
              <GroupedHorizontal
                loading={sideMenuState.isLoading}
                expand={fullFourth}
                selectedIndex={month.value}
                data={getPreviousThreeMonthData(
                  sideMenuState.carbonFoorPrint,
                  month.value,
                )}
                fuelColumns={sideMenuState.getUniqueEmissions()}
              />
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
