import { Review} from "../Models/Review";
import BaseAxios from "./BaseAxios";

export async function ReviewService(review: Review[]) {
  try {
      await BaseAxios.post("/business/setReviews", review);    
    return true;
  } catch (e: any) {
    return false;
  }
}

export async function ProgrammeService(review: Review[]) {
    try {
        await BaseAxios.post("/business/setProgrammes", review);    
      return true;
    } catch (e: any) {
      return false;
    }
  }
  