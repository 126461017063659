import useAxios from "axios-hooks"
import { useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import LoginModel from "./Models/Login"

export default function Login() {
    const history = useHistory()
    const location = useLocation<{from:string}>()

    const [loginReq, login] = useAxios({
      url: "/auth/login/",
      method: "post"
    }, { manual: true });
    
    const [details, setDetails] = useState<LoginModel>({
        email: "",
        password: ""
    })
    const submit = async (event: any) => {   
        event.preventDefault()
        const response = await login({ data: details });
        const { jwt, ...u } = response.data;
        localStorage.setItem("jwt", jwt);  
        localStorage.setItem("user", JSON.stringify(u));  

        const from = location.state?.from ?? "";
        history.push(from);
    }
    return (
      <form
        onSubmit={submit}
        className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center"
      >
        <div className="mb-3 w-50">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
            onChange={(e: any) =>
              setDetails({
                ...details,
                email: e.target.value,
              })
            }
            required
          />
        </div>
        <div className="mb-3 w-50">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            onChange={(e: any) =>
              setDetails({
                ...details,
                password: e.target.value,
              })
            }
            required
          />
        </div>
        {loginReq.loading === false && loginReq.error?.response?.data && (
          <p className="text-danger mb-0">{loginReq.error?.response?.data.message}</p>
        )}
        <button disabled={loginReq.loading} className="btn btn-primary my-3">
          Login
        </button>
        <Link to="/">Back to home</Link>
      </form>
    );
}