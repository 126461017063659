import { Pie, ResponsiveContainer, PieChart as Pies, Cell } from "recharts";

const sample = [
  { name: "Group A", value: 400 },
  { name: "Group D", value: 200 },
];

interface props {
  colors: [string, string];
  text?: string;
  thick?: boolean;
  data?: { name: string; value: number }[];
}

export default function PieChart({ colors, text, thick, data = sample }: props) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Pies>
        <Pie
          paddingAngle={0}
          dataKey="value"
          startAngle={-270}
          endAngle={90}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius="100%"
          innerRadius={thick ? "40%" : "70%"}
        >
          {data.map((entry, index) => (
            <Cell key={entry.name} fill={colors[index]} stroke={colors[index]} />
          ))}
        </Pie>
        {text ? (
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#4D505C"
            fontSize="22"
          >
            {text}
          </text>
        ) : (
          ""
        )}
      </Pies>
    </ResponsiveContainer>
  );
}
