import BarChart, { LineChartDataSet } from "./GroupedBar";

interface props {
  expand?: boolean;
  loading: boolean;
  selectedIndex: number;
  data: LineChartDataSet[][];
  fuelColumns: Array<{ fuelSourceName: string; fuelSourceColorCode: string }>;
}

export default function BarCharts({
  expand,
  selectedIndex,
  fuelColumns,
  data,
  loading,
}: props) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return <h5 className="text-center pt-5">No data</h5>;
  }

  return <BarChart selectedIndex={0} loading={loading} records={data} />;
}
