import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SelectStyle } from "../Helper/ReactSelect";
import { ProgrammeService, ReviewService } from "../Services/Review";
import { getSites } from "../Services/Site";

export default function ReviewAndProgrammes() {
    const alert = useAlert()
    const [sites, setSites] = useState<{ value: number, label: string }[]>([])

    useEffect(() => {
        async function fetchData() {
            let result = await getSites();
            setSites(result.map((x: any) => ({
                label: x.address,
                value: x.id
            })))
        }
        fetchData()
    }, [])
    return <section className="w-55  mt-5">
        <Formik
            initialValues={{
                siteId: -1,
                review: [
                    {
                        question: "How often will you like staff to be energy management trained?",
                        answers: []
                    },
                    {
                        question: "Activate Behaviour change modules",
                        answers: []
                    }
                ],
                heating: [
                    {
                        question: "What type of window glazing do you have in the building?",
                        answers: []
                    },
                    {
                        question: "Is there thermostat(s) in the building?",
                        answers: []
                    },
                    {
                        question: "Are your hot water taps open and close ones?",
                        answers: []
                    }
                ],
                cooling: [
                    {
                        question: "Are some parts of your building cooled 24/7?",
                        answers: []
                    },
                    {
                        question: "Do you have vents?",
                        answers: []
                    }
                ],
                lighting: [
                    {
                        question: "Are some parts of your building lighted 24/7?",
                        answers: []
                    },
                    {
                        question: "Are all your lights LED?",
                        answers: []
                    }
                ],
                powering: [
                    {
                        question: "Are some parts of your building powered 24/7?",
                        answers: []
                    },
                    {
                        question: "Are auto-switches in use?",
                        answers: []
                    }
                ]
            }}
            onSubmit={async (values) => {
                if (values.siteId === -1) {
                    alert.error("Site is required")
                }
                else {
                    try {

                        let reviews = values.review.filter(x => x.answers.length !== 0).map((x, i) => (
                            {
                                question: x.question,
                                answers: x.answers,
                                siteId: values.siteId
                            }
                        ))
                        let programs = [
                            ...values.heating.filter(x => x.answers.length !== 0).map((x, i) => (
                                {
                                    question: x.question,
                                    answers: x.answers,
                                    siteId: values.siteId,
                                    usedInId: 0
                                }
                            )),
                            ...values.cooling.filter(x => x.answers.length !== 0).map((x, i) => (
                                {
                                    question: x.question,
                                    answers: x.answers,
                                    siteId: values.siteId,
                                    usedInId: 1
                                }
                            )),
                            ...values.powering.filter(x => x.answers.length !== 0).map((x, i) => (
                                {
                                    question: x.question,
                                    answers: x.answers,
                                    siteId: values.siteId,
                                    usedInId: 2
                                }
                            )),
                            ...values.lighting.filter(x => x.answers.length !== 0).map((x, i) => (
                                {
                                    question: x.question,
                                    answers: x.answers,
                                    siteId: values.siteId,
                                    usedInId: 3
                                }
                            ))
                        ]
                        await ReviewService(reviews)
                        await ProgrammeService(programs)
                        alert.success("successful Upload")
                    }
                    catch (e) {
                        console.log(e)
                        alert.error("Internal server error")
                    }
                }
            }}
        >
            {({ values, handleBlur, handleChange }) =>
                <Form>
                    <div className="card rounded-custom" style={{
                        filter: "drop-shadow(0px 0px 20px #E7E7E866)"
                    }}>
                        <div className="card-header bg-white rounded-custom-top py-4">
                            <h2 className="text-center mb-0 fw-bold">Review and Programmes</h2>
                        </div>
                        <div className="card-body" style={{
                            paddingBottom: "2.2rem",
                            paddingLeft: "2.2rem",
                            paddingRight: "2.2rem",
                            paddingTop: "2.2rem"
                        }}>
                            <div className="row">
                                <div className="col-12 text-muted mb-3">
                                    <label htmlFor="siteId" className="form-label">Site</label>
                                    <Select
                                        name="siteId"
                                        id="siteId"
                                        options={sites}
                                        styles={SelectStyle}
                                        placeholder={<span className="select-placeholder">Choose Site</span>}
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'siteId', value: selectedOption?.value } }
                                            handleChange(event)
                                        }}
                                        onBlur={() => {
                                            handleBlur({ target: { name: 'siteId' } })
                                        }}
                                    />
                                </div>
                            </div>
                            <label className="mb-3">How often will you like staff to be energy management trained?</label>
                            <div className="d-flex mb-3">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[0].answers" value="Monthly" />
                                    <span className="checkmark"></span>
                                    <span className="label">Monthly</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[0].answers" value="Quaterly" />
                                    <span className="checkmark"></span>
                                    <span className="label">Quaterly</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[0].answers" value="Biannually" />
                                    <span className="checkmark"></span>
                                    <span className="label">Biannually</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[0].answers" value="Yearly" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yearly</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[0].answers" value="Periodic" />
                                    <span className="checkmark"></span>
                                    <span className="label">Periodic</span>
                                </label>
                            </div>

                            <label className="mb-3">Activate Behaviour change modules</label>
                            <div className="d-flex">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[1].answers" value="Energy Awareness" />
                                    <span className="checkmark"></span>
                                    <span className="label">Energy Awareness</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[1].answers" value="Energy Conservation" />
                                    <span className="checkmark"></span>
                                    <span className="label">Energy Conservation </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[1].answers" value="Energy Efficiency" />
                                    <span className="checkmark"></span>
                                    <span className="label">Energy Efficiency</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="review[1].answers" value="Energy Shifting Demands" />
                                    <span className="checkmark"></span>
                                    <span className="label">Energy Shifting Demands</span>
                                </label>
                            </div>

                            <p className="text-success border-top border-success border-4 pt-2 mt-5 fw-bold">
                                Heating
                            </p>

                            <label className="mb-3">What type of window glazing do you have in the building</label>
                            <div className="d-flex mb-3">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[0].answers" value="Single glazing" />
                                    <span className="checkmark"></span>
                                    <span className="label">Single glazing</span>
                                </label>
                                <label className="checkbox-container w-20">

                                    <Field type="checkbox" name="heating[0].answers" value="Double glazing" />
                                    <span className="checkmark"></span>
                                    <span className="label">Double glazing </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[0].answers" value="Triple glazing" />
                                    <span className="checkmark"></span>
                                    <span className="label">Triple glazing</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[0].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[0].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>
                            <label className="mb-3">Is there thermostat(s) in the building?</label>
                            <div className="d-flex mb-3">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[1].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[1].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[1].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[1].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>
                            <label className="mb-3">Are your hot water taps open and close ones?</label>
                            <div className="d-flex">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[2].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[2].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[2].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="heating[2].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>

                            <p className="text-success border-top border-success border-4 pt-2 mt-5 fw-bold">
                                Cooling
                            </p>
                            <label className="mb-3">Are some parts of your building cooled 24/7?</label>
                            <div className="d-flex mb-3">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[0].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[0].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[0].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[0].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>
                            <label className="mb-3">Do you have vents?</label>
                            <div className="d-flex">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[1].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[1].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[1].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="cooling[1].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>


                            <p className="text-success border-top border-success border-4 pt-2 mt-5 fw-bold">
                                LIGHTING
                            </p>
                            <label className="mb-3">Are some parts of your building lighted 24/7?</label>
                            <div className="d-flex mb-3">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[0].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[0].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[0].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[0].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>
                            <label className="mb-3">ARE YOUR LIGHTS LED?</label>
                            <div className="d-flex">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[1].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[1].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[1].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="lighting[1].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>

                            <p className="text-success border-top border-success border-4 pt-2 mt-5 fw-bold">
                                Powering
                            </p>
                            <label className="mb-3">Are some parts of your building powered 24/7?</label>
                            <div className="d-flex mb-3">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[0].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[0].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[0].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[0].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>
                            <label className="mb-3">Are auto switches in use?</label>
                            <div className="d-flex">
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[1].answers" value="Yes" />
                                    <span className="checkmark"></span>
                                    <span className="label">Yes</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[1].answers" value="No" />
                                    <span className="checkmark"></span>
                                    <span className="label">No </span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[1].answers" value="Not applicable" />
                                    <span className="checkmark"></span>
                                    <span className="label">Not applicable</span>
                                </label>
                                <label className="checkbox-container w-20">
                                    <Field type="checkbox" name="powering[1].answers" value="Don't know" />
                                    <span className="checkmark"></span>
                                    <span className="label">Don't know</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-3 mb-5">
                        <button className="btn btn-outline-danger btn-nav"><i className="fas fa-save me-3"></i>SAVE</button>
                        <span>
                            <Link to="/home/settings/utilityandemissions" className="btn btn-danger btn-nav"><i className="fas fa-long-arrow-alt-left me-1"></i>BACK </Link>
                            <Link to="/home/analytics" className="btn btn-danger ms-3 btn-nav">NEXT <i className="fas fa-long-arrow-alt-right ms-1"></i></Link>
                        </span>
                    </div>
                </Form>
            }
        </Formik>
    </section>
}