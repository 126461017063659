import React, { ComponentProps } from "react";
import Select from "react-select";
import { SelectStyle } from "../Helper/ReactSelect";
import AxiosRequestFactory from "../Components/AxiosRequestFactory";
import useAxios from "axios-hooks";
import Option from "../Models/Option";

type Props = Omit<ComponentProps<Select>, "value"> & { value: number };

const mapCountryToOption = (x: {id: number, name: string}): Option => ({
  value: x.id,
  label: x.name,
});

export default function ApiCountrySelect(p: Props) {
  const [{ data = [], ...countriesReq }] = useAxios({
    url: "/site/countries",
  });
  const options = data.map(mapCountryToOption) as Option[];
  return (
    <AxiosRequestFactory request={{ ...countriesReq, data }}>
      <AxiosRequestFactory.Data>
        <Select
          options={options}
          styles={SelectStyle}
          placeholder={
            <span className="select-placeholder">Choose Country</span>
          }
          {...p}
          value={options.find((i) => i.value === p.value)}
        />
      </AxiosRequestFactory.Data>
    </AxiosRequestFactory>
  );
}
