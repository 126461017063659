import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { ChartYears } from "../../../Helper/DateFormat";
import { ResponsiveContainer } from "recharts";

export type LineChartDataSet = {
  date: Date;
  value: number;
  siteName: string;
  fuelSourceName: string;
};

const Chart = (p: {
  dataSet?: LineChartDataSet[][];
  selectedIndex: number;
  myRef: any;
  expand?: boolean;
}) => {
  const svgRef = useRef(null);
  const tempData = p?.dataSet?.flat() || [];

  useEffect(() => {
    if (tempData.length === 0) {
      return;
    }
    setTimeout(() => {
      const width = p.myRef.current?.offsetWidth;
      const height = p.myRef.current?.offsetHeight;
      const marginTop = 10;
      const marginRight = 10;
      const marginBottom = 20;
      const marginLeft = 40;

      var svg: any,
        tip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;

      const fx = d3
        .scaleBand()
        .domain(new Set(tempData.map((d: any) => {
          return ChartYears(new Date(d.date));
        })))
        .rangeRound([marginLeft, width - marginRight]);

      const fuelSourceIds = new Set(tempData.map((d: any) => d.fuelSourceId));

      const x = d3
        .scaleBand()
        .domain(fuelSourceIds as any)
        .rangeRound([0, fx.bandwidth()])
        .paddingInner(0.01555);

      // Y encodes the height of the bar.
      const y = d3
        .scaleLinear()
        .domain([0, d3.max(tempData, (d: any) => d.value) as any])
        .rangeRound([height - marginBottom, marginTop]);

      // Create the SVG container.
      svg = d3
        .select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .attr("id", "emissions")
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto;");

      const everything = svg.selectAll("*");
      everything.remove();

      tip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("pointer-events", "none")
        .style("background", "rgba(0,0,0,0.8)")
        .style("z-index", 10)
        .style("padding", "8px")
        .style("font-size", "11px")
        .style("border-radius", "10px");

      // Append a group for each date, and a rect for each age.
      svg
        .append("g")
        .selectAll()
        .data(d3.group(tempData, (d: any) => ChartYears(new Date(d.date))))
        .join("g")
        .attr("transform", ([date]: any) => `translate(${fx(date)!},0)`)
        .selectAll()
        .data(([, d]: any) => d)
        .join("rect")
        .attr("rx", 5)
        .attr("ry", 5)
        .attr("x", (d: any, index: number) => {
          return index >= 1 ? x(d.fuelSourceId)! - 10 : x(d.fuelSourceId);
          // return index >= 1
          //   ? x(d.fuelSourceId)!
          //   : fx(d.date)! + x(d.fuelSourceId)!;
        })
        .attr("y", (d: any) => y(d.value))
        .attr("width", x.bandwidth() - 15)
        .attr("height", (d: any) => y(0) - y(d.value))
        .attr("fill", (d: any) => d.fuelSourceColorCode)
        .on("mouseover", function (_: any, err: any) {
          tip.style("opacity", 1);
          tip.style("color", "white");
        })
        .on("mousemove", function (event: any, d: any) {
          tip.html(`<h6>${ChartYears(new Date(d.date))}</h6>
          <div><b>Site:</b> ${d.siteName}</div>
          <div><b>FuelSource:</b> ${d.fuelSourceName}</div>
          <div><b>${"value"}:</b> ${d.value}</div>
          `);
          tip
            .style("left", `${event.pageX}px`)
            .style("top", `${event.pageY}px`);
        })
        .on("mouseleave", function () {
          tip.style("opacity", 0);
        });

      // Append the horizontal axis.
      svg
        .append("g")
        .attr("transform", `translate(0,${height - marginBottom})`)
        .call(d3.axisBottom(fx).tickSizeOuter(0))
        .call((g: any) => g.selectAll(".domain").remove())
        .call((g: any) =>
          g.selectAll("line").attr("y2", 0).attr("stroke-opacity", 0)
        );

      // Append the vertical axis.
      svg
        .append("g")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(d3.axisLeft(y))
        .call((g: any) => g.select(".domain").remove())
        .call((g: any) =>
          g.selectAll("line").attr("x2", 0).attr("stroke-opacity", 0)
        );
    }, 1100);

    return () => {
      // tip?.remove();
      // svg?.remove();
    };
  }, [p.dataSet, tempData, p.selectedIndex]);
  return <svg ref={svgRef} />;
};

interface Props {
  expand?: boolean;
  selectedIndex: number;
  records: LineChartDataSet[][];
  loading: boolean;
}
export default function BarChart({
  records,
  loading,
  selectedIndex,
  expand,
}: Props) {
  const myRef = useRef<any>(null);
  useEffect(() => {
    if (myRef?.current) {
      console.log("final Width:", myRef?.current?.offsetWidth);
    } else {
      console.error("Ref not attached to the component");
    }
  }, [myRef, expand]);

  if (loading === true) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (records.flat().length === 0) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <h4>No data</h4>
          </div>
        </div>
      </div>
    );
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <div
        style={{ position: "absolute", height: "100%", width: "100%" }}
        ref={myRef}
      >
        {" "}
        <Chart
          dataSet={records}
          selectedIndex={selectedIndex}
          myRef={myRef}
          expand={expand}
        />{" "}
      </div>
    </ResponsiveContainer>
  );
}
