import { useEffect, useState } from "react";
import {
  Pie,
  ResponsiveContainer,
  PieChart as Pies,
  Sector,
  Cell,
} from "recharts";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 400 },
  { name: "Group C", value: 400 },
  { name: "Group D", value: 400 },
];

interface props {
  value: number;
  loading: boolean;
}

export default function PieChart({ value, loading }: props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const colors = [
    "#94C11F",
    "url(#colorFirst)",
    "url(#colorSecond)",
    "#E97871",
  ];

  useEffect(() => {
    let index = 0;
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].value;
      if (value < sum) {
        index = i;
        break;
      }
    }
    setActiveIndex(index);
  }, [value]);

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    let minValue = 0;
    let maxValue = 0;
    let dataReverse = data.reverse();
    for (let i = 0; i < activeIndex + 1; i++) {
      if (i > 0) {
        minValue += dataReverse[i - 1].value;
      }
      maxValue += data[i].value;
    }
    value = maxValue - value + minValue;
    let numerator = (value - minValue) * (startAngle - endAngle);
    let denominator = maxValue - minValue;
    let angle = numerator / denominator + endAngle;

    const sin = Math.sin(-RADIAN * angle);
    const cos = Math.cos(-RADIAN * angle);
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;

    return (
      <g>
        <circle cx={cx} cy={cy} r={10} stroke="none" fill="#4D505C" />
        <circle cx={cx} cy={cy} r={20} fill="none" stroke="#4D505C" />
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <path d={`M ${sx},${sy} L ${cx + 7},${cy} h -14 z`} fill="#4D505C" />
      </g>
    );
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="150%">
      <Pies id="wasteGraph">
        <defs>
          <linearGradient id="colorFirst">
            <stop offset="0%" stopColor="#94C11F" stopOpacity={1} />
            <stop offset="100%" stopColor="#F5B363" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="colorSecond">
            <stop offset="0%" stopColor="#F5B363" stopOpacity={1} />
            <stop offset="100%" stopColor="#E97871" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          paddingAngle={2}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius="100%"
          innerRadius="70%"
          fill="#4989C6"
        >
          {data.map((entry, index) => (
            <Cell fill={colors[index]} stroke={colors[index]} />
          ))}
        </Pie>
      </Pies>
    </ResponsiveContainer>
  );
}
