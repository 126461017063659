import React, { ComponentProps, useEffect } from "react";
import Select from "react-select";
import useAxios from "axios-hooks";
import { SelectStyle } from "../Helper/ReactSelect";
import AxiosRequestFactory from "./AxiosRequestFactory";
import Option from "../Models/Option";

type Props = Omit<ComponentProps<Select>, "value"> & {
  countryId?: number;
  value?: number;
};

const mapCountryToOption = (x: { id: number; name: string }) => ({
  value: x.id,
  label: x.name,
});

export default function ApiStateSelect(p: Props) {
  const [{ data = [], ...countriesReq }, getStates] = useAxios(
    {
      url: "/site/states",
    },
    { manual: true }
  );

  useEffect(() => {
    if (!p.countryId) return;
    getStates({
      params: {
        countryId: p.countryId,
      },
    });
  }, [p.countryId]);

  const options = data.map(mapCountryToOption) as Option[];
  return (
    <AxiosRequestFactory request={{ ...countriesReq, data }}>
      <AxiosRequestFactory.Data>
        <Select
          isDisabled={data.length === 0}
          options={options}
          styles={SelectStyle}
          placeholder={
            <span className="select-placeholder">Choose Country</span>
          }
          {...p}
          value={options.find((i) => i.value === p.value)}
        />
      </AxiosRequestFactory.Data>
    </AxiosRequestFactory>
  );
}
