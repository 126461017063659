import style from "../analytics.module.css";
import Select from "react-select";
import { SelectStyle } from "../../Helper/ReactSelect";
import { months, years } from "../../Helper/Year";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators, State } from "../../State";
import EnergyLogState from "../EnergyLog/EnergyLogState";
import useDidUpdateEffect from "../../Hooks/useDidUpdateEffect";
import SiteList from "./Site";

export default function EnergyLog() {
  const dispatch = useDispatch();
  const { ChangeMonth, ChangeYear } = bindActionCreators(
    ActionCreators,
    dispatch
  );

  const sideMenuState = EnergyLogState();

  const month = useSelector((state: State) => state.month);
  const site = useSelector((state: State) => state.site);
  const year = useSelector((state: State) => state.year);

  useDidUpdateEffect(() => {
    sideMenuState.fetchLogs();
  }, [year, month, site]);

  return (
    <div className={style.rightSideBar}>
      <div className={sideMenuState.isLoading ? style.baseMenuBusy : "d-none"}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <div className={style.login}>
        <button className="btn btn-success btn-radius px-4 py-2">
          <i className="fas fa-user me-1"></i>
          Login
        </button>
      </div>
      <SiteList sites={sideMenuState.sites} currentSite={site} />
      <div className="px-4 my-3 border-bottom flex-fill">
        <div className="my-5">
          <label className={`form-label ${style.selectLabel}`}>Month</label>
          <Select
            options={months}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={month}
            onChange={(e) => {
              if (e) {
                ChangeMonth(e);
              }
            }}
          />
        </div>
        <div className="my-5">
          <label className={`form-label ${style.selectLabel}`}>Year</label>
          <Select
            options={years}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            onChange={(e) => {
              if (e) {
                ChangeYear(e);
              }
            }}
          />
        </div>
        <div className="d-flex flex-column ">
          <label className="checkbox-container mb-3">
            <input type="checkbox" />
            <span className="checkmark"></span>
            <span className="label">Regular Building Operations</span>
          </label>
          <label className="checkbox-container mb-3">
            <input type="checkbox" />
            <span className="checkmark"></span>
            <span className="label">Irregular Building Operations</span>
          </label>
        </div>
      </div>
      <div className="px-4 mb-5 mt-4">
        <button className={`btn btn-danger mb-2 py-3 w-100 ${style.buttons}`}>
          Download Entire Document
        </button>
        <button
          className={`btn btn-outline-danger border-2 py-3 w-100 ${style.buttons}`}
        >
          Send to Email
        </button>
      </div>
    </div>
  );
}
