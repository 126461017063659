import { useState } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";

interface Props{
    options:{value:any,label:any}[],
    placeholder:string,
    onChange?:Function
}

const SelectStyle={
    indicatorSeparator:(provided:any,state:any)=>({
        ...provided,
        margin:0,
        backgroundColor:state.isFocused ? "#94c11f":"#E8E8E8"
    }),
    dropdownIndicator:(provided:any,state:any)=>({
        ...provided,
        color:"#37404D",
        padding:"0rem",
        height:"100%"
    }),
    control:(provided:any,state:any)=>({
        ...provided,
        boxShadow:'none',
        border:state.isFocused ? "1px solid #94c11f":"1px solid #E8E8E8",
        '&:hover':{
            border:'1px solid #94c11f'
        },
        borderRadius:0,
        height:"48px",
    })
}

export default function Select2Buttons({options,placeholder,onChange}:Props) {
    const [value, setValue] = useState<typeof options[0]>()

    const DropdownIndicator = (props: DropdownIndicatorProps) => {
        return <components.DropdownIndicator {...props}>
            <div className="h-100 d-flex flex-column justify-content-around">
                <i className="fas fa-angle-up mx-3" style={{
                    color:value === options[0]?"#E7E7E8":""
                }}></i>
                <hr className="my-0" />
                <i className="fas fa-angle-down mx-3" style={{
                    color:value === options[options.length-1]?"#E7E7E8":""
                }}></i>
            </div>
        </components.DropdownIndicator>
    }

    return <Select
        options={options}
        styles={SelectStyle}
        placeholder={<span className="select-placeholder">{placeholder}</span>}
        components={{ DropdownIndicator }}
        onChange={(newValue:any) => {
            setValue(newValue)
            if(onChange){
                onChange(newValue)
            }
        }}
    />
}