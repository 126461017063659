import { AxiosError } from "axios";
import React from "react"

type WithChildren = {children: React.ReactElement | React.ReactElement[]}

const Loading = ({ children }: WithChildren) => {
  return <>{children}</>;
};

const Error = ({ children }: WithChildren) => {
  return <>{children}</>;
};

const Data = ({ children }: WithChildren) => {
  return <>{children}</>;
};

const LoadingElement = () => {
    return (
      <div className="d-flex h-100 w-100 justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
}

const ErrorElement = (p?: { error?: AxiosError<ApiError, any> | null }) => {
  return (
    <div className="d-flex vh-50 justify-content-center align-items-center">
      {p?.error?.response?.data.message || "We failed to load the resource"}
    </div>
  );
};

type ApiError = { statusCode: number; message: string }

type AxiosRequestFactoryProps<T = any> = {
  request: {
    loading: boolean;
    data?: T;
    error: AxiosError<ApiError, any> | null;
  };
  children: React.ReactElement | React.ReactElement[];
};
const AxiosRequestFactory = ({
  request,
  children: child,
}: AxiosRequestFactoryProps): React.ReactElement => {
  const children = Array.isArray(child) ? child : [child];

  if (request.loading === true) {
    const found = children.find((i) => i.type === Loading);
    return found ? found : <LoadingElement />;
  }

  if (request.error && request.loading === false) {
    const found = children.find((i) => i.type === Error);
    return found ? found : <ErrorElement error={request.error} />;
  }

  if (request.data && request.loading === false) {
    return children.find((i) => i.type === Data) || children[0];
  }

  return children.find((i) => i.type === Loading) || <LoadingElement />;;
};

AxiosRequestFactory.Error = Error;
AxiosRequestFactory.Loading = Loading;
AxiosRequestFactory.LoadingPlaceholder = LoadingElement;
AxiosRequestFactory.Data = Data;

export default AxiosRequestFactory
