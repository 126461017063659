import Card from "../Card";
import Layout from "../Layout";
import style from "../analytics.module.css";
import { useEffect, useMemo, useState } from "react";
import VerticalBar from "./Horizontal";
import LineChart, { LineChartDataSet } from "../Charts/Lines/LineChart";
import LargeBarChart from "./LargeBarChart";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators, State } from "../../State";
import Table from "./WasteTable";
import PieChart from "../Charts/Pies/Waste";
import OverlappingInfo from "../OverlappingInfo";
import UtilityLeageState from "../UtilityLeague/LeageState";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import CostPanel from "../UtilityLeague/CostPanel";
import IncreasedPercentage from "../UtilityLeague/IncreasedPercentage";
import SideMenuState from "../UtilityLeague/LeageState";
import { months } from "../../Helper/Year";
import { bindActionCreators } from "redux";
import { useConvertHtmlToImg } from "../../Hooks/useConvertHtmlToImg";
import useQueryManager from "../../Hooks/useQueryManager";
import { increasedPercentage } from "../../Helper/increasedPercentage";
import { diffRecords } from "../../Helper/diffRecords";
import { formatISO } from "date-fns";

const ChangeDisplay = (
  value: number,
  expand: boolean,
  selectedIndex: number,
  data: LineChartDataSet[][],
  loading: boolean
) => {
  switch (value) {
    case 0:
      return (
        <LineChart
          expand={expand}
          selectedIndex={selectedIndex}
          dataSet={data}
          showNegativeValues={true}
          loading={loading}
        />
      );
    case 1:
      return (
        <Table
          data={data[0]?.map((i: any) => {
            return {
              date: i.date,
              saving: i.waste,
            };
          })}
          expand={expand}
          selectedIndex={selectedIndex}
          loading={loading}
        />
      );
    default:
      return <PieChart loading={loading} value={950} />;
  }
};



export default function EnergyWaste() {
  const urlQuery = useQueryManager();
  const [fullFirst, setFullFirst] = useState(false);
  const [fullSecond, setFullSecond] = useState(false);
  const [fullThird, setFullThird] = useState(false);
  const [fullFourth, setFullFourth] = useState(false);
  const [probe, setProbe] = useState(false);
  const siteParam = useMemo(() => urlQuery.get("site"), [urlQuery.get("site")]);
  const fuelParam = useMemo(() => urlQuery.get("fuel"), [urlQuery.get("fuel")]);
  const display = useSelector((state: State) => state.display);
  const site = useSelector((state: State) => state.site);
  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const sideMenuState = SideMenuState();
  const dispatch = useDispatch();
  const { ChangeSite } = bindActionCreators(ActionCreators, dispatch);
  const { convertHtmlToImg } = useConvertHtmlToImg();

  const pageState = UtilityLeageState();

  useEffect(() => {
    pageState.fetchSites();
    pageState.getFuelSource().then(({ data }) => {
      if (!fuelParam) {
        pageState.setSelectedFuelsource(data[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (!siteParam && !site && sideMenuState?.sites?.length > 0) {
      ChangeSite(sideMenuState.sites[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenuState.sites]);

  useEffect(() => {
    if (!year) return;
    const getFetchEnergyWaste = async () => {
      await pageState.fetchEnergyWaste({ year, fuelSource, site });
    };
    getFetchEnergyWaste();
    if (month?.value < 2) {
      const getFetchEnergyWastePreviousYear = async () => {
        await pageState.fetchEnergyWastePreviousYear({
          year,
          fuelSource,
          site,
        });
      };
      getFetchEnergyWastePreviousYear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, site, fuelSource]);

  const filterDataOfFuel = () => {
    if (pageState?.energyWaste && pageState?.energyWaste.length === 0) {
      return [];
    }
    const currentValue = pageState.energyWaste[0]?.filter(
      (i: any) =>
        new Date(i.date).getMonth() === month.value && i.siteId === site?.id
    );
    const result: any = [];
    if (currentValue.length > 0) {
      pageState?.fuelSources.forEach((element: any) => {
        const index = currentValue.findIndex(
          (x: any) => x.fuelSourceId === element.id
        );
        if (index !== -1) {
          element["value"] = Math.abs(currentValue[index].waste);
        }
        result.push(element);
      });
    }
    return result;
  };

  const getEmptyDatForSavingGauge = () => {
    return {
      date: new Date(
        formatISO(
          new Date(`${year?.value + "-" + (month?.value + 1) + "-" + 2}`),
          {representation: "date"}
        )
      ),
      fuelSourceId: 0,
      projectedEnergy: 0,
      siteId: 0,
      waste: 0,
      isCreated: true,
    };
  };

  const getPreviousThreeMonthData = (
    data: any,
    selectedIndex: number,
    previousData: any
  ) => {
    if (data.length === 0) {
      return [];
    }
    data.sort(function (a: any, b: any) {
      return a.date - b.date;
    });
    previousData.sort(function (a: any, b: any) {
      return a.date - b.date;
    });
    let selectedMonthData = {};
    // get selected Month data
    if (data && data.length > 0) {
      selectedMonthData = data.find(
        (x: any) =>
          x.siteId === site?.id &&
          new Date(x.date).getFullYear() === year?.value &&
          new Date(x.date).getMonth() === month?.value &&
          x.fuelSourceId === fuelSource?.value
      );
      if (!selectedMonthData) {
        selectedMonthData = getEmptyDatForSavingGauge();
      }
    } else {
      selectedMonthData = getEmptyDatForSavingGauge();
    }
    let arrayList = [];
    const monthList = [...months];
    monthList.pop();
    if (selectedIndex <= 2) {
      for (let index = 0; index <= 4 - selectedIndex; index++) {
        const monthIndex = monthList.length - (4 - index);
        let item = previousData.find((x: any) => {
          return (
            new Date(x.date).getFullYear() === year?.value - 1 &&
            new Date(x.date).getMonth() === monthIndex &&
            x.fuelSourceId === fuelSource?.value &&
            (site && site?.id ? site?.id === x.siteId : true)
          );
        });
        if (item) {
          arrayList.push(item);
        }
      }
      for (let i = 0; i <= selectedIndex - 1; i++) {
        let item = data.find((x: any) => {
          return (
            new Date(x.date).getFullYear() === year?.value &&
            new Date(x.date).getMonth() === i &&
            x.fuelSourceId === fuelSource?.value &&
            (site && site?.id ? site?.id === x.siteId : true)
          );
        });
        if (item) {
          arrayList.push(item);
        }
      }
      arrayList = arrayList.reverse();
      arrayList.unshift(selectedMonthData);
      return arrayList;
    } else {
      for (let index = 1; index <= (selectedIndex > 3 ? 4 : 3); index++) {
        const monthIndex = selectedIndex - index;
        let item = data.find((x: any) => {
          return (
            new Date(x.date).getFullYear() === year?.value &&
            new Date(x.date).getMonth() === monthIndex &&
            x.fuelSourceId === fuelSource?.value &&
            (site && site?.id ? site?.id === x.siteId : true)
          );
        });
        if (item) {
          arrayList.push(item);
        }
      }
      if (selectedIndex === 3) {
        const monthIndex = 11;
        let item = previousData.find((x: any) => {
          return (
            new Date(x.date).getFullYear() === year?.value &&
            new Date(x.date).getMonth() === monthIndex &&
            x.fuelSourceId === fuelSource?.value &&
            (site && site?.id ? site?.id === x.siteId : true)
          );
        });
        if (item) {
          arrayList.push(item);
        } else {
          arrayList.push(getEmptyDatForSavingGauge());
        }
      }
      arrayList.unshift(selectedMonthData);
      return arrayList;
    }
  };

  const filterSavingGaugeData = () => {
    let list = getPreviousThreeMonthData(
      pageState.energyWaste.length > 0 ? pageState.energyWaste[0] : [],
      month.value,
      pageState.energyWastePreviousYear.length > 0
        ? pageState.energyWastePreviousYear[0]
        : []
    );
    const getPercentage = (
      currentMonthWaste: number,
      previousMonthWaste: number
    ) => {
      return previousMonthWaste
        ? (
            ((Math.abs(currentMonthWaste) - Math.abs(previousMonthWaste)) /
              currentMonthWaste) *
            100
          )?.toFixed(2)
        : 0;
    };
    list = list
      .map((x: any, index: number) => {
        return x.isCreated
          ? undefined
          : {
              ...x,
              value: Math.max(
                Math.abs(x?.waste),
                Math.abs(list[index + 1]?.waste || 0)
              ),
              differentInPercentage: getPercentage(
                x?.waste,
                list[index + 1]?.waste
              ),
            };
      })
      .filter((i) => i !== undefined);
    if (list.length > 4) {
      list.pop();
    }
    return list;
  };

  const handleDownloadSvg = () => {
    switch (display.value) {
      case 0:
        return convertHtmlToImg("consumption");
      case 1:
        return convertHtmlToImg("wasteTable");
      case 2:
        return convertHtmlToImg("wasteGraph");
      default:
        return "";
    }
  };

  const getEnergyWasteFilter = (item: any) => {
    return item.filter((x: any) => x.fuelSourceId === fuelSource?.value);
  }

  return (
    <Layout rightSideBar={<SideMenu />}>
      <div className="flex-fill d-flex flex-column">
        <div className="d-flex align-items-center">
          <h4 className={`${style.sectionHeader} mt-4 text-center mx-auto`}>
            Annual Energy Waste and Savings
          </h4>
          <div className="m-2">
            <label className="checkbox-container">
              <input
                type="checkbox"
                onClick={() => {
                  setProbe(!probe);
                }}
                checked={probe}
              />
              <span className="checkmark"></span>
              <span className="label">Probe</span>
            </label>
          </div>
        </div>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <div
            className={`${style.quaterFirst} ${fullFirst && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFirst(!fullFirst)}
              onDownloadClick={() => handleDownloadSvg()}
              isDisabledDownloadBtn={
                pageState.energyWaste.length === 0 ||
                (pageState.energyWaste.length > 0 &&
                  getEnergyWasteFilter(pageState.energyWaste[0])?.length === 0)
              }
              title="Energy waste (kWh)"
              expanded={fullFirst}
              year={year.value}
              top={
                <div className="d-flex align-items-center mb-2">
                  <div
                    className={`rounded-circle text-center p-2 d-flex flex-column 
                                    ${
                                      display.value === 2
                                        ? "me-auto"
                                        : "ms-auto"
                                    }`}
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>
                      {pageState.selectedMonthDaysAmount}
                    </span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  {display.value !== 2 && (
                    <div className="mx-2 d-flex flex-column align-items-end">
                      <span>
                        {diffRecords(
                          pageState.energyWaste,
                          month.value,
                          "waste"
                        ) || "N/A"}{" "}
                        kWh
                      </span>
                      <IncreasedPercentage
                        className={style.sm9lg17}
                        percentage={increasedPercentage(
                          pageState.energyWaste,
                          month.value,
                          "waste"
                        )}
                      />
                    </div>
                  )}
                  <OverlappingInfo
                    text1="Average waste per day"
                    value1={String(
                      Math.abs(
                        pageState.getCurrentWaste()?.waste /
                          (pageState?.selectedMonthDaysAmount || 0)
                      ).toFixed(0)
                    )}
                    color1="#4989C6"
                    text2="Waste this month"
                    value2={
                      pageState.getCurrentWaste()?.waste
                        ? Math.abs(pageState.getCurrentWaste()?.waste).toFixed(
                            2
                          )
                        : "N/A"
                    }
                    color2="#4D505C"
                  />
                </div>
              }
              bottom={
                <>
                  {display.value === 2 && (
                    <div className="mx-auto d-flex flex-column align-items-center">
                      <span
                        style={{
                          fontSize: "26px",
                        }}
                      >
                        {pageState.currentRecord?.consumption || "N/A"} kHW
                      </span>
                      <IncreasedPercentage
                        style={{ fontSize: "15px" }}
                        percentage={
                          pageState.currentRecord?.increasedCostPercentage
                        }
                      />
                    </div>
                  )}

                  <div className="d-flex align-items-center  mt-2">
                    <>
                      <span
                        className={style.legendCircle}
                        style={{
                          backgroundColor: "#4989C6",
                        }}
                      />
                      <span className={style.legend}>
                        {fuelSource?.label} {month.label} {year.value}
                      </span>
                    </>
                  </div>
                </>
              }
            >
              {ChangeDisplay(
                display.value,
                fullFirst,
                month.value,
                pageState.energyWaste.map((item: any) =>
                  getEnergyWasteFilter(item).map((i: any) => ({
                    ...i,
                    value: Math.abs(i.waste),
                    cost: Math.abs(i.waste),
                    fuelSourceName: sideMenuState?.fuelSources?.find(
                      (x) => x.id === i?.fuelSourceId
                    )?.source,
                    siteName: sideMenuState?.sites?.find(
                      (x) => x.id === i?.siteId
                    )?.name,
                  }))
                ).filter(arr => arr.length !== 0),
                pageState.isLoading
              )}
            </Card>
          </div>

          <div
            className={`${style.quaterSecond} ${fullSecond && style.full} p-3`}
          >
            <Card
              onClick={() => setFullSecond(!fullSecond)}
              onDownloadClick={() => convertHtmlToImg("wasteSavingGraph")}
              title="Waste/Savings Gauge (Percentage change in the last 3 months)"
              expanded={fullSecond}
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#E97871",
                    }}
                  />
                  <span className={`${style.legend} me-3`}>
                    {fuelSource?.label} {month.label} {year.value}
                  </span>
                </div>
              }
            >
              <LargeBarChart
                records={filterSavingGaugeData()}
                expand={fullSecond}
                selectedIndex={month.value}
                loading={pageState.isLoading}
              />
            </Card>
          </div>

          <CostPanel
            tooltipTag={"Cost"}
            isCallingFromEnergy={true}
            consumptions={pageState.energyWaste.map((r) =>
              r.map((i: any) => ({
                ...i,
                date: formatISO(i.date, { representation: "date" }),
                cost: i.wasteCost,
              }))
            )}
            selectedYear={sideMenuState.selectedYear.value}
            selectedMonthDaysAmount={sideMenuState.selectedMonthDaysAmount || 0}
            currentRecord={sideMenuState.currentRecord}
            increasedPercentage={increasedPercentage(
              pageState.energyWaste,
              month.value,
              "wasteCost"
            )}
            diffCostPrevMonth={diffRecords(
              pageState.energyWaste.map((r) =>
                r.map((i: any) => ({
                  ...i,
                  date: formatISO(i.date, { representation: "date"}),
                  cost: i.wasteCost,
                }))
              ),
              month.value
            )}
            currentMonthCost={pageState.getCurrentWaste()?.wasteCost}
            averageMonthCost={Number.parseFloat(
              (
                pageState.getCurrentWaste()?.wasteCost /
                (sideMenuState.selectedMonthDaysAmount || 1)
              ).toFixed(2)
            )}
          />

          <div
            className={`${style.quaterFourth} ${fullFourth && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFourth(!fullFourth)}
              isDisabledDownloadBtn={filterDataOfFuel().length === 0}
              onDownloadClick={() => convertHtmlToImg("wasteFuels")}
              title="Fuels"
              expanded={fullFourth}
              top={
                <div className="d-flex align-items-center">
                  <div
                    className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>30</span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  <small
                    className={`${style.sm12lg17} mx-2`}
                    style={{
                      fontWeight: 600,
                      color: "#94C11F",
                    }}
                  >
                    <i className="fas fa-arrow-up"></i> 13.8%
                  </small>
                  <OverlappingInfo
                    text1="Total Energy Consumption this month"
                    value1={`${pageState.getCurrentWaste()?.consumption} kWh`}
                    color1="#94C11F"
                    text2="Total (+)Avoided and Wasted Energy(-)"
                    value2={`${Math.abs(
                      pageState.getCurrentWaste()?.waste
                    )} kWh`}
                    color2="#4989C6"
                  />
                </div>
              }
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#F5B363",
                    }}
                  />
                  <span className={`${style.legend}`}>
                    {fuelSource?.label} {month.label} {year.value}
                  </span>
                </div>
              }
            >
              <VerticalBar
                expand={fullFourth}
                data={filterDataOfFuel()}
                loading={pageState.isLoading}
              />
            </Card>
          </div>
        </div>
      </div>
      <Modal
        isOpen={probe}
        style={{
          content: {
            width: "700px",
            bottom: "auto",
            inset: "unset",
            borderRadius: "20px",
            boxShadow: "0px 0px 50px #0000005E",
            padding: "30px",
          },
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5>Building Heating Energy</h5>
          <button className="btn text-danger" onClick={() => setProbe(false)}>
            <i className="fas fa-times fa-2x"></i>
          </button>
        </div>
        <p>
          <span className="fw-bold">Heating Energy: </span>
          Normal building operations and conditions were analysed. The heating
          demand of the building was in the negative. The estimated wasted
          energy was calculated from the fuel(s) identified for heating the
          building.
        </p>
        <p>
          <span className="fw-bold">Building Cooling Energy:</span>
          Normal building operating conditions and demand were analysed. The
          cooling demand of the building was in the negative. The estimated
          wasted energy was calculated from the fuels identified for cooling the
          building/
        </p>
        <p>
          <span className="fw-bold">Power and Lighting: </span>
          Normal building operating conditions and demand were analysed. The
          power and lighting demand of the building were in the negative. The
          estimated wasted energy was calculated from the fuel(s) identified for
          powering and lighting the building.
        </p>
        For more information. Contact us.
      </Modal>
    </Layout>
  );
}
