import React from "react";
import style from "./analytics.module.css";

interface Props {
  onDownloadClick?: Function;
  isDisabledDownloadBtn?: boolean;
  onClick?: Function;
  title: React.ReactNode;
  expanded: boolean;
  children?: React.ReactNode;
  year?: number;
  top?: React.ReactNode;
  bottom?: React.ReactNode;
  leftTop?: React.ReactNode;
  rightTop?: React.ReactNode;
  titleMenu?: React.ReactNode;
  className?: string;
  bodyClassName?: string;
}
export default function Card({
  onDownloadClick,
  isDisabledDownloadBtn,
  onClick,
  title,
  expanded,
  children,
  year,
  top,
  bottom,
  leftTop,
  rightTop,
  titleMenu,
  className,
  bodyClassName = "",
}: Props) {
  return (
    <div className={`card h-100 ${style.analyticsCard}`}>
      <div className="card-header d-flex justify-content-between align-items-center bg-white ps-4">
        <span className={style.cardTitle}>{title}</span>
        <span className="d-flex align-items-center">
          <span className={style.cardYear}>{year}</span>
          {titleMenu} 
          <button
            className={`btn shadow-none px-2 ${
              isDisabledDownloadBtn ? "disabled" : ""
            }`}
            onClick={() => onDownloadClick && onDownloadClick()}
          >
            <img
              src="/icons/download.svg"
              alt="download"
              style={{
                width: expanded ? "48px" : "24px",
              }}
            />
          </button>
          {onClick && (
            <button
              className={`btn shadow-none px-0`}
              onClick={() => onClick()}
            >
              {expanded ? (
                <img src="/icons/contract.svg" alt="download" />
              ) : (
                <img src="/icons/expand.svg" alt="download" />
              )}
            </button>
          )}
        </span>
      </div>
      {/* This "overflow-auto" class use for fuelcase */}
      <div className={"card-body d-flex flex-column overflow-auto " + bodyClassName}>
        {top}
        <div
          className={`flex-fill position-relative ${
            className ? style[className] : ""
          }`}
        >
          <div className="position-absolute">{leftTop}</div>
          <div className="position-absolute end-0">{rightTop}</div>
          {children}
        </div>
        {bottom}
      </div>
    </div>
  );
}
