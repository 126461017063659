import { NavLink } from "react-router-dom";

export default function Home() {
  return (
    <>
      <section
        style={{
          backgroundImage:"url('/images/bg-image.png')",
          backgroundSize:"cover",
          backgroundPosition:"bottom"
        }}
        className="d-flex py-5 align-items-center justify-content-center flex-column w-100"
      >
        <h1>Welcome to EnergiePad</h1>
        <p className="w-50 text-center fw-bold">
          WHERE YOUR JOURNEY TO BEING ENERGY SAVVY TO REDUCE CARBON AND UTILITY
          COSTS STARTS
        </p>
        <div className="d-flex w-100 justify-content-center menu mt-5">
          <NavLink to="/home/settings" className="card rounded-custom" >
            <div className="card-body px-5 py-5 w-100 text-center d-flex flex-column h-100 justify-content-center">
            <img src="/icons/settings.svg" alt="settings"/>
            <p className="mb-0 fw-bold mt-3">SETTINGS</p>
            </div>
          </NavLink>
          <NavLink to="/home/analytics" className="card mx-5 rounded-custom">
            <div className="card-body px-5 w-100 py-5 text-center d-flex flex-column h-100 justify-content-center">
              <img src="/icons/dashboard.svg" alt="analytics"/>
              <p className="mb-0 fw-bold mt-3">ANALYTICS</p>
            </div>
          </NavLink>
          <NavLink to="/home/user-management" className="card rounded-custom">
            <div className="card-body w-100 px-5 py-5 text-center d-flex flex-column h-100 justify-content-center">
            <img src="/icons/key.svg" alt="user managemnt"/>
            <p className="mb-0 fw-bold mt-3">USER MANAGEMENT</p>
            </div>
          </NavLink>
        </div>
      </section>
      <section className="d-flex align-items-center flex-column mt-5">
        <p className="fw-bold text-center w-50">
          DESIGNED TO HELP BUSINESSES AND HOMES REDUCE ENERGY CONSUMPTION AND
          UTILITY BILLS
        </p>
        <div className="w-50 card">
          <div className="card-body text-center">
            <i className="fas fa-quote-left text-success fs-1"></i>
            <p>
              There are global energy challenges. A portion of energy used in
              business and residential buildings around the world is wasted. In
              addition, global emmissions are surging and a chunk from the
              energy we consume. Furthermore, energy bills are increasing in
              many places around the world*.
            </p>
            <p>
              There are global energy challenges. A portion of energy used in
              business and residential buildings around the world is wasted. In
              addition, global emmissions are surging and a chunk from the
              energy we consume. Furthermore, energy bills are increasing in
              many places around the world*.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
