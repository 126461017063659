import moment from "moment";
import styles from "./styles.module.css"
import formatISO from "date-fns/formatISO";
interface Props {
  expand: boolean;
  data: { date: Date; regularTenantAmount: number; irregularTenantAmount: number }[];
}
export default function Table({ expand, data }: Props) {
    return <table className={`${styles.tableReport} ${expand && styles.expand}`}>
        <thead>
            <tr>
                <th>Month</th>
                <th>Year</th>
                <th>Regular Office Users</th>
                <th>Irregulat Office Users</th>
            </tr>
        </thead>
        <tbody>
            {data.map((i) => {
                return (
                  <tr key={formatISO(i.date)}>
                    <td className="py-4">{moment(i.date).format("MMMM")}</td>
                    <td>{i.date.getFullYear()}</td>
                    <td>{i.regularTenantAmount}</td>
                    <td>{i.irregularTenantAmount}</td>
                  </tr>
                );    
            })}
        </tbody>
    </table>
}