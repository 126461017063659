import useAxios from "axios-hooks";
import { FieldArray, Form, Formik, FormikProps, getIn } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Decimal } from "decimal.js";
import { Link } from "react-router-dom";
import Select from "react-select";
import Select2Buttons from "../Components/Select2Buttons";
import { SelectStyle } from "../Helper/ReactSelect";
import { months, years } from "../Helper/Year";
import { Consumption } from "../Models/Consumption";
import Emission from "../Models/Emission";
import FuelSource from "../Models/FuelSource";
import Option from "../Models/Option";
import { getSites } from "../Services/Site";
import {
  AddConsumption,
  AddEmission,
  AddMonitoring,
  FuelUnitEnum,
} from "../Services/Utility";
import * as Yup from "yup";
import ErrorLabel from "../Components/ErrorLabel";
import { M_T } from "../Models/M_T";
import FuelUse from "../Models/FuelUse";
import { defaultFuelSourceOption } from "../Analytics/SideMenu/Base";

const numWords = require("num-words");

const fuelUnitOptions = Object.entries(FuelUnitEnum).map((i) => ({
  label: i[1],
  value: Number.parseInt(i[0]),
}));

const fuelSourceToOption = (fs: FuelSource): Option => {
  return {
    value: fs.id,
    label: fs.source,
  };
};

const schema = Yup.object({
  siteId: Yup.number().required("Required"),
  month: Yup.number().required("Required"),
  year: Yup.number().required("Required"),
  consumption: Yup.array().of(
    Yup.object({
      fuelSourceId: Yup.number().required("Required"),
      consumption: Yup.number().required("Required"),
      totalCost: Yup.number().required("Required"),
    })
  ),
  emission: Yup.array().of(
    Yup.object({
      fuelSourceId: Yup.number().required("Required"),
      value: Yup.number().required("Required"),
    })
  ),
  uses: Yup.array().of(Yup.number()).min(1, "Required"),
  emissionFactor: Yup.string().oneOf(["Specified", "Default"]).required(),
});

const initial = {
  uses: [] as number[],
  consumption: [] as Consumption[],
  emission: [] as Emission[],
  m_t: [] as M_T[],
  emissionFactor: "Specified",
  year: undefined as number | undefined,
  month: undefined as number | undefined,
  siteId: undefined as number | undefined,
};

export default function UtilityAndEmissions() {
  const alert = useAlert();
  const formikRef = useRef<FormikProps<typeof initial> | null>(null);
  const [sites, setSites] = useState<{ value: number; label: string }[]>([]);

  const [{ data: fuelSources = [] }] = useAxios<FuelSource[]>({
    url: "/utility/fuelSources",
  });

  const [{ data: conversionValues = [] }, fetchConversionValues] = useAxios<
    { unit: string; value: number }[]
  >(
    {
      url: "/conversionUnit",
    },
    { manual: true }
  );

  const [, getConsumptions] = useAxios<Consumption[]>(
    {
      url: "/utility/consumptions",
    },
    { manual: true }
  );

  const [, getEmissions] = useAxios<Consumption[]>(
    {
      url: "/utility/emissions",
    },
    { manual: true }
  );

  const [, getMonitoring] = useAxios<Consumption[]>(
    {
      url: "/utility/monitoring",
    },
    { manual: true }
  );

  const [{ data: fuelUses = [], loading: isFetching }] = useAxios<FuelUse[]>({
    url: "/utility/fuelUse",
  });

  const getConversionFactors = useCallback((fuelSource: string[]) => {
    if (fuelSource.length > 0) {
      fetchConversionValues({
        params: { fuelSource: fuelSource.join(",") },
      });
    }
  }, []);

  const getConversionResult = useCallback(
    (p: { fuelUnit: string; value: number }) => {
      const record = conversionValues.find((i) => i.unit === p.fuelUnit);
      if (record) {
        return new Decimal(record.value).times(p.value).toNumber();
      } else {
        return 0;
      }
    },
    [conversionValues]
  );

  const setCurrentValues = useCallback((values: any) => {
    const units = Array.from(
      new Set<string>(
        values.consumption
          .map((i: any) => i.fuelUnit)
          .concat(values.emission.map((i: any) => i.fuelUnit))
          .filter((i: any) => i !== undefined)
          .map((i: any) => FuelUnitEnum[i])
      )
    );

    getConversionFactors(units);
  }, []);

  useEffect(() => {
    async function fetchData() {
      let result = await getSites();
      setSites(
        result.map((x: any) => ({
          label: x.name,
          value: x.id,
        }))
      );
    }
    fetchData();
  }, []);

  const fetchConsumptions = (p: {
    siteId?: string;
    year?: string;
    month?: string;
  }) => {
    if (p.siteId === undefined) return;
    if (p.year === undefined) return;
    if (p.month === undefined) return;
    getConsumptions({
      params: p,
    }).then(({ data }) => {
      formikRef.current?.setFieldValue("consumption", data);
    });
  };

  const fetchEmission = (p: { siteId?: string; year?: string }) => {
    if (p.siteId === undefined) return;
    if (p.year === undefined) return;
    getEmissions({
      params: p,
    }).then(({ data }) => {
      formikRef.current?.setFieldValue("emission", data);
    });
  };

  const fetchMonitoring = (p: {
    siteId?: string;
    year?: string;
    month?: string;
  }) => {
    if (p.siteId === undefined) return;
    if (p.year === undefined) return;
    if (p.month === undefined) return;
    getMonitoring({
      params: p,
    }).then(({ data }) => {
      formikRef.current?.setFieldValue("m_t", data);
    });
  };

  const onUseChange = useCallback(
    (e) => {
      const use = fuelUses.find((u) => u.use === e.target.name);
      if (use?.id) {
        const found = formikRef.current?.values.uses.find((u) => u === use.id);
        formikRef.current?.setFieldValue(
          "uses",
          found !== undefined
            ? formikRef.current?.values.uses.filter((u) => u !== found)
            : formikRef.current?.values.uses.concat([use.id])
        );
      }
    },
    [formikRef.current, fuelUses]
  );

  return (
    <section className="w-55 mt-5">
      <Formik
        innerRef={formikRef}
        initialValues={initial}
        validationSchema={schema}
        onSubmit={async (values) => {
          if (values.month === undefined) return;
          if (!values.siteId) return;
          if (!values.year) return;

          const promises = [];

          if (values.consumption.length !== 0) {
            let first = await AddConsumption(
              values.consumption,
              values.month,
              values.year,
              values.siteId,
              values.uses
            );
            promises.push(first);
          }

          if (values.emission.length !== 0) {
            let second = await AddEmission(
              values.emission,
              values.siteId,
              values.year,
              values.month,
              values.uses
            );
            promises.push(second);
          }

          if (values.m_t.length !== 0) {
            let second = await AddMonitoring(
              values.m_t,
              values.siteId,
              values.year,
              values.month,
              values.uses
            );
            promises.push(second);
          }

          if (promises.every((i) => i === true)) {
            alert.show("Success");
          } else {
            alert.show("Failed");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div
                className="card rounded-custom"
                style={{
                  filter: "drop-shadow(0px 0px 20px #E7E7E866)",
                }}
              >
                <div className="card-header bg-white rounded-custom-top py-4">
                  <h2 className="text-center mb-0 fw-bold">
                    Utility And Emissions{" "}
                  </h2>
                </div>
                <div
                  className="card-body"
                  style={{
                    paddingBottom: "2.2rem",
                    paddingLeft: "2.2rem",
                    paddingRight: "2.2rem",
                    paddingTop: "2.2rem",
                  }}
                >
                  <div className="row mb-5">
                    <div className="col-12 text-muted mb-1">
                      <label htmlFor="siteId" className="form-label">
                        Site
                      </label>
                      <Select
                        name="siteId"
                        id="siteId"
                        options={sites}
                        styles={SelectStyle}
                        placeholder={
                          <span className="select-placeholder">
                            Choose Site
                          </span>
                        }
                        onChange={(selectedOption) => {
                          setFieldValue("siteId", selectedOption?.value);
                          fetchConsumptions({
                            siteId: selectedOption?.value.toString(),
                            year: values.year?.toString(),
                            month: values.month?.toString(),
                          });

                          fetchEmission({
                            siteId: selectedOption?.value.toString(),
                            year: values.year?.toString(),
                          });
                          fetchMonitoring({
                            siteId: selectedOption?.value.toString(),
                            year: values.year?.toString(),
                            month: values.month?.toString(),
                          });
                        }}
                        onBlur={() => {
                          handleBlur({ target: { name: "siteId" } });
                        }}
                      />
                      <ErrorLabel
                        render={
                          touched.siteId === true && errors.siteId !== undefined
                        }
                      >
                        {errors.siteId}
                      </ErrorLabel>
                    </div>
                    <div className="col-6">
                      <label>Month</label>
                      <Select2Buttons
                        options={months}
                        placeholder="Month"
                        onChange={(value: any) => {
                          setFieldValue("month", value.value);
                          fetchConsumptions({
                            siteId: values.siteId?.toString(),
                            year: values.year?.toString(),
                            month: value.value,
                          });

                          fetchMonitoring({
                            siteId: values?.siteId?.toString(),
                            year: values.year?.toString(),
                            month: value.value,
                          });
                        }}
                      />
                      <ErrorLabel
                        render={
                          touched.month === true && errors.month !== undefined
                        }
                      >
                        {errors.month}
                      </ErrorLabel>
                    </div>
                    <div className="col-6">
                      <label>Year</label>
                      <Select2Buttons
                        options={years}
                        placeholder="Year"
                        onChange={(value: any) => {
                          setFieldValue("year", value.value);
                          fetchConsumptions({
                            siteId: values.siteId?.toString(),
                            year: value.value,
                            month: values.month?.toString(),
                          });

                          fetchEmission({
                            siteId: values.siteId?.toString(),
                            year: value.value,
                          });

                          fetchMonitoring({
                            siteId: values?.siteId?.toString(),
                            year: value.value,
                            month: values.month?.toString(),
                          });
                        }}
                      />
                      <ErrorLabel
                        render={
                          touched.year === true && errors.year !== undefined
                        }
                      >
                        {errors.year}
                      </ErrorLabel>
                    </div>
                  </div>
                  {fuelUses.map((item) => {
                    return (
                      <label key={item.use} className="checkbox-container w-25">
                        <input
                          type="checkbox"
                          name={item.use}
                          checked={
                            values.uses.find((i) => i === item.id) !== undefined
                          }
                          onChange={onUseChange}
                        />
                        <span className="checkmark"></span>
                        <span className="label">{item.use}</span>
                      </label>
                    );
                  })}
                  <ErrorLabel render={errors.uses !== undefined}>
                    {errors.uses?.toString()}
                  </ErrorLabel>
                  <p className="text-danger fw-bold">
                    Please enter your historic energy data on a monthly basis
                    for 10 years
                  </p>
                  <p className="text-danger fw-bold">
                    For Solar PV and Grid Electricity, Consumption(m<sup>3</sup>
                    ) and conversionUnit is not available.
                  </p>
                  <p className="text-success border-top border-success border-4 pt-2 my-4 fw-semibold">
                    ENERGY CONSUMPTION AND COSTS
                  </p>
                  <table className="table  table-striped table-bordered">
                    <thead className="fs-12">
                      <tr></tr>
                      <tr>
                        <th className="border-end text-white">Utilities</th>
                        <th className="text-center border-end text-white">
                          Consumption
                        </th>
                        <th className="text-center border-end text-white">
                          Fuel Unit
                        </th>
                        <th className="text-center border-end text-white">
                          VAT Cost (&#163;)
                        </th>
                        <th className="text-center border-end text-white">
                          Total Cost (&#163;)
                        </th>
                        <th className="text-center border-end text-white">
                          Conversion Factor
                        </th>
                        <th className="unset"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="consumption"
                        render={({ handleRemove, push }) => {
                          return (
                            <>
                              {values.consumption.map((x, idx) => {
                                const key = numWords(idx);
                                return (
                                  <tr key={key}>
                                    <th className="align-middle">
                                      <Select
                                        name={`consumption[${idx}].fuelSourceId`}
                                        id={`consumption[${idx}].fuelSourceId`}
                                        options={fuelSources
                                          .map(fuelSourceToOption)
                                          .concat([defaultFuelSourceOption])}
                                        value={
                                          values.consumption[idx].fuelSourceId
                                            ? {
                                                value:
                                                  values.consumption[idx]
                                                    .fuelSourceId,
                                                label: fuelSources.find(
                                                  (i) =>
                                                    i.id ===
                                                    values.consumption[idx]
                                                      .fuelSourceId
                                                )?.source,
                                              }
                                            : undefined
                                        }
                                        styles={SelectStyle}
                                        defaultValue={defaultFuelSourceOption}
                                        onChange={(selectedOption) => {
                                          let event = {
                                            target: {
                                              name: `consumption[${idx}].fuelSourceId`,
                                              value: selectedOption?.value,
                                            },
                                          };
                                          handleChange(event);
                                        }}
                                        onBlur={() => {
                                          handleBlur({
                                            target: {
                                              name: `consumption[${idx}].fuelSourceId`,
                                            },
                                          });
                                        }}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(
                                            errors.consumption?.[idx],
                                            "fuelSourceId"
                                          ) !== undefined &&
                                          touched.consumption?.[idx]
                                            ?.fuelSourceId === true
                                        }
                                      >
                                        {getIn(
                                          errors.consumption?.[idx],
                                          "fuelSourceId"
                                        )}
                                      </ErrorLabel>
                                    </th>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`consumption[${idx}].consumption`}
                                        id={`consumption[${idx}].consumption`}
                                        value={
                                          !!values.consumption[idx]
                                            ?.consumption &&
                                          !!values.consumption[idx]
                                            ?.conversionUnit
                                            ? values.consumption[idx]
                                                ?.consumption *
                                              values.consumption[idx]
                                                ?.conversionUnit
                                            : values.consumption[idx]
                                                ?.consumption
                                        }
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        onChange={(selectedOption) => {
                                          const event = {
                                            target: {
                                              name: `consumption[${idx}].consumption`,
                                              value:
                                                selectedOption.target.value,
                                            },
                                          };
                                          handleChange(event);

                                          const event2 = {
                                            target: {
                                              name: `consumption[${idx}].conversionFactor`,
                                              value: getConversionResult({
                                                fuelUnit:
                                                  FuelUnitEnum[
                                                    Number.parseInt(
                                                      values.consumption[idx]
                                                        .fuelUnit
                                                    )
                                                  ],

                                                value: Number.parseInt(
                                                  selectedOption.target.value
                                                ),
                                              }),
                                            },
                                          };
                                          handleChange(event2);
                                        }}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(
                                            errors.consumption?.[idx],
                                            "consumption"
                                          ) !== undefined &&
                                          touched.consumption?.[idx]
                                            ?.consumption === true
                                        }
                                      >
                                        {getIn(
                                          errors.consumption?.[idx],
                                          "consumption"
                                        )}
                                      </ErrorLabel>
                                    </td>
                                    <td className="align-middle">
                                      <Select
                                        name={`consumption[${idx}].fuelUnit`}
                                        id={`consumption[${idx}].fuelUnit`}
                                        options={fuelUnitOptions}
                                        value={
                                          values.consumption[idx].fuelUnit
                                            ? {
                                                value: fuelUnitOptions.find(
                                                  (i) =>
                                                    i.label ===
                                                    values.consumption[idx]
                                                      .fuelUnit
                                                )?.value,
                                                label:
                                                  values.consumption[idx]
                                                    .fuelUnit,
                                              }
                                            : undefined
                                        }
                                        styles={{
                                          ...SelectStyle,
                                          control: (a, b) => {
                                            const res = SelectStyle.control(
                                              a,
                                              b
                                            );
                                            res.minWidth = "150px";
                                            return res;
                                          },
                                        }}
                                        defaultValue={defaultFuelSourceOption}
                                        onChange={(selectedOption) => {
                                          let event = {
                                            target: {
                                              name: `consumption[${idx}].fuelUnit`,
                                              value: selectedOption?.value,
                                            },
                                          };
                                          handleChange(event);
                                          setCurrentValues({
                                            ...values,
                                            consumption: [
                                              ...values.consumption,
                                              {
                                                fuelUnit: selectedOption?.value,
                                              },
                                            ],
                                          });
                                        }}
                                        onBlur={() => {
                                          handleBlur({
                                            target: {
                                              name: `consumption[${idx}].fuelUnit`,
                                            },
                                          });
                                        }}
                                      />
                                    </td>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`consumption[${idx}].vat`}
                                        id={`consumption[${idx}].vat`}
                                        value={values.consumption[idx]?.vat}
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        onChange={handleChange}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(
                                            errors.consumption?.[idx],
                                            "vat"
                                          ) !== undefined &&
                                          touched.consumption?.[idx]?.vat ===
                                            true
                                        }
                                      >
                                        {getIn(
                                          errors.consumption?.[idx],
                                          "vat"
                                        )}
                                      </ErrorLabel>
                                    </td>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`consumption[${idx}].totalCost`}
                                        id={`consumption[${idx}].totalCost`}
                                        value={
                                          values.consumption[idx]?.totalCost
                                        }
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        onChange={handleChange}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(
                                            errors.consumption?.[idx],
                                            "totalCost"
                                          ) !== undefined &&
                                          touched.consumption?.[idx]
                                            ?.totalCost === true
                                        }
                                      >
                                        {getIn(
                                          errors.consumption?.[idx],
                                          "totalCost"
                                        )}
                                      </ErrorLabel>
                                    </td>

                                    <td className="text-center align-middle">
                                      <input
                                        name={`consumption[${idx}].conversionFactor`}
                                        id={`consumption[${idx}].conversionFactor`}
                                        value={
                                          values.consumption[idx]
                                            ?.conversionFactor
                                        }
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        onChange={handleChange}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-transparent shadow-none"
                                        type="button"
                                        onClick={handleRemove(idx)}
                                      >
                                        <i className="fas fa-times text-danger fs-3"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th className="align-middle">
                                  <button
                                    className=" fw-bold btn shadow-none d-flex align-items-center text-nowrap"
                                    type="button"
                                    onClick={() =>
                                      push({
                                        fuelSourceId: undefined,
                                        consumption: undefined,
                                        cost: undefined,
                                        totalCost: undefined,
                                      })
                                    }
                                  >
                                    Add Other
                                    <i className="fas fa-plus-circle text-danger fs-3 ms-1"></i>
                                  </button>
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </>
                          );
                        }}
                      />
                    </tbody>
                  </table>
                  <p className="text-success border-top border-success border-4 pt-2 my-5 fw-semibold">
                    ENERGY EMISSIONS
                  </p>
                  <div>
                    <table className="table table-striped table-bordered">
                      <thead className="fs-12">
                        <tr>
                          <th className="border-end text-white fw-semibold">
                            Utilities
                          </th>
                          <th className="text-center border-end text-white">
                            Fuel Unit
                          </th>
                          <th className="text-center border-end text-white">
                            Emission Factor
                          </th>
                          <th className="text-center border-end text-white fw-semibold">
                            Conversion Factor
                          </th>
                          <th className="unset"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="emission">
                          {({ handleRemove, push }) => (
                            <>
                              {values.emission.map((x, idx) => {
                                const key = numWords(idx);
                                return (
                                  <tr key={key}>
                                    <th className="align-middle">
                                      <Select
                                        name={`emission[${idx}].fuelSourceId`}
                                        id={`emission[${idx}].fuelSourceId`}
                                        options={fuelSources
                                          .map(fuelSourceToOption)
                                          .concat([defaultFuelSourceOption])}
                                        value={
                                          values.emission[idx]?.fuelSourceId
                                            ? {
                                                value:
                                                  values.emission[idx]
                                                    ?.fuelSourceId,
                                                label: fuelSources.find(
                                                  (i) =>
                                                    i.id ===
                                                    values.emission[idx]
                                                      ?.fuelSourceId
                                                )?.source,
                                              }
                                            : undefined
                                        }
                                        styles={SelectStyle}
                                        defaultValue={defaultFuelSourceOption}
                                        onChange={(selectedOption) => {
                                          let event = {
                                            target: {
                                              name: `emission[${idx}].fuelSourceId`,
                                              value: selectedOption?.value,
                                            },
                                          };
                                          handleChange(event);
                                        }}
                                        onBlur={() => {
                                          handleBlur({
                                            target: {
                                              name: `emission[${idx}].fuelSourceId`,
                                            },
                                          });
                                        }}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(
                                            errors.emission?.[idx],
                                            "fuelSourceId"
                                          ) !== undefined &&
                                          touched.emission?.[idx]
                                            ?.fuelSourceId === true
                                        }
                                      >
                                        {getIn(
                                          errors.emission?.[idx],
                                          "fuelSourceId"
                                        )}
                                      </ErrorLabel>
                                    </th>
                                    <td className="text-center align-middle">
                                      <Select
                                        name={`emission[${idx}].fuelUnit`}
                                        id={`emission[${idx}].fuelUnit`}
                                        options={fuelUnitOptions}
                                        value={
                                          values.emission[idx].fuelUnit
                                            ? {
                                                value: fuelUnitOptions.find(
                                                  (i) =>
                                                    i.label ===
                                                    values.emission[idx]
                                                      .fuelUnit
                                                )?.value,
                                                label:
                                                  values.emission[idx].fuelUnit,
                                              }
                                            : undefined
                                        }
                                        styles={{
                                          ...SelectStyle,
                                          control: (a, b) => {
                                            const res = SelectStyle.control(
                                              a,
                                              b
                                            );
                                            res.minWidth = "150px";
                                            return res;
                                          },
                                        }}
                                        defaultValue={defaultFuelSourceOption}
                                        onChange={(selectedOption) => {
                                          let event = {
                                            target: {
                                              name: `emission[${idx}].fuelUnit`,
                                              value: selectedOption?.value,
                                            },
                                          };
                                          handleChange(event);
                                          setCurrentValues({
                                            ...values,
                                            emission: [
                                              ...values.emission,
                                              {
                                                fuelUnit: selectedOption?.value,
                                              },
                                            ],
                                          });
                                        }}
                                        onBlur={() => {
                                          handleBlur({
                                            target: {
                                              name: `emission[${idx}].fuelUnit`,
                                            },
                                          });
                                        }}
                                      />
                                    </td>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`emission[${idx}].emissionFactor`}
                                        id={`emission[${idx}].emissionFactor`}
                                        value={
                                          values.emission[idx]?.emissionFactor
                                        }
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        onChange={handleChange}
                                      />
                                    </td>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`emission[${idx}].conversionFactor`}
                                        id={`emission[${idx}].conversionFactor`}
                                        value={
                                          values.emission[idx]?.conversionFactor
                                        }
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        onChange={handleChange}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        onClick={handleRemove(idx)}
                                        className="btn btn-transparent"
                                      >
                                        <i className="fas fa-times text-danger fs-3"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th className="align-middle">
                                  <button
                                    className=" fw-bold btn shadow-none d-flex align-items-center text-nowrap"
                                    type="button"
                                    onClick={() => {
                                      push({
                                        value: 0,
                                        year: new Date().getFullYear(),
                                        fuelSourceId: undefined,
                                        cost: 0,
                                        costM3: 0,
                                        conversionUnit: 0,
                                      });
                                    }}
                                  >
                                    Add Other
                                    <i className="fas fa-plus-circle text-danger fs-3 ms-1"></i>
                                  </button>
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>

                  {
                    //TODO API Layer for this
                  }
                  <p className="text-success border-top border-success border-4 pt-2 mt-5 fw-semibold">
                    Monitoring & Targeting
                  </p>
                  <div>
                    <table className="table table-striped table-bordered">
                      <thead className="fs-12">
                        <tr>
                          <th className="border-end text-white fw-semibold">
                            Utilities
                          </th>
                          <th className="text-center border-end text-white fw-semibold">
                            Fuel Unit
                          </th>
                          <th className="text-center border-end text-white fw-semibold">
                            Conversion Unit (to kWh)
                          </th>
                          <th className="text-center border-end text-white fw-semibold">
                            Energy
                          </th>
                          <th className="text-center border-end text-white fw-semibold">
                            Carbon (kg)
                          </th>
                          <th className="text-center border-end text-white fw-semibold"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="m_t">
                          {({ handleRemove, push }) => (
                            <>
                              {values.m_t.map((x, idx) => {
                                const key = numWords(idx);
                                return (
                                  <tr key={key}>
                                    <th className="align-middle">
                                      <Select
                                        name={`m_t[${idx}].fuelSourceId`}
                                        id={`m_t[${idx}].fuelSourceId`}
                                        options={fuelSources
                                          .map(fuelSourceToOption)
                                          .concat([defaultFuelSourceOption])}
                                        value={
                                          values.m_t[idx]?.fuelSourceId
                                            ? {
                                                value:
                                                  values.m_t[idx]?.fuelSourceId,
                                                label: fuelSources.find(
                                                  (i) =>
                                                    i.id ===
                                                    values.m_t[idx]
                                                      ?.fuelSourceId
                                                )?.source,
                                              }
                                            : undefined
                                        }
                                        styles={SelectStyle}
                                        defaultValue={defaultFuelSourceOption}
                                        onChange={(selectedOption) => {
                                          let event = {
                                            target: {
                                              name: `m_t[${idx}].fuelSourceId`,
                                              value: selectedOption?.value,
                                            },
                                          };
                                          handleChange(event);
                                        }}
                                        onBlur={() => {
                                          handleBlur({
                                            target: {
                                              name: `m_t[${idx}].fuelSourceId`,
                                            },
                                          });
                                        }}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(
                                            errors.m_t?.[idx],
                                            "fuelSourceId"
                                          ) !== undefined &&
                                          touched.m_t?.[idx]?.fuelSourceId ===
                                            true
                                        }
                                      >
                                        {getIn(
                                          errors.m_t?.[idx],
                                          "fuelSourceId"
                                        )}
                                      </ErrorLabel>
                                    </th>
                                    <td className="align-middle">
                                      <Select
                                        name={`m_t[${idx}].fuelUnit`}
                                        id={`m_t[${idx}].fuelUnit`}
                                        options={fuelUnitOptions}
                                        value={
                                          values.m_t[idx].fuelUnit
                                            ? {
                                                value: fuelUnitOptions.find(
                                                  (i) =>
                                                    i.label ===
                                                    values.m_t[idx].fuelUnit
                                                )?.value,
                                                label:
                                                  values.emission[idx].fuelUnit,
                                              }
                                            : undefined
                                        }
                                        styles={{
                                          ...SelectStyle,
                                          control: (a, b) => {
                                            const res = SelectStyle.control(
                                              a,
                                              b
                                            );
                                            res.minWidth = "150px";
                                            return res;
                                          },
                                        }}
                                        defaultValue={defaultFuelSourceOption}
                                        onChange={(selectedOption) => {
                                          let event = {
                                            target: {
                                              name: `m_t[${idx}].fuelUnit`,
                                              value: selectedOption?.value,
                                            },
                                          };
                                          handleChange(event);
                                        }}
                                        onBlur={() => {
                                          handleBlur({
                                            target: {
                                              name: `m_t[${idx}].fuelUnit`,
                                            },
                                          });
                                        }}
                                      />
                                    </td>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`m_t[${idx}].conversionFactor`}
                                        id={`m_t[${idx}].conversionFactor`}
                                        value={
                                          values.m_t[idx]?.conversionFactor
                                        }
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        onChange={handleChange}
                                      />
                                    </td>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`m_t[${idx}].energy`}
                                        id={`m_t[${idx}].energy`}
                                        onChange={handleChange}
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        value={values.m_t[idx]?.energy}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(errors.m_t?.[idx], "energy") !==
                                            undefined &&
                                          touched.m_t?.[idx]?.energy === true
                                        }
                                      >
                                        {getIn(errors.m_t?.[idx], "energy")}
                                      </ErrorLabel>
                                    </td>
                                    <td className="text-center align-middle">
                                      <input
                                        name={`m_t[${idx}].carbon`}
                                        id={`m_t[${idx}].carbon`}
                                        onChange={handleChange}
                                        className="form-control text-center bg-transparent shadow-none h-100 rounded-0 border-0"
                                        type="number"
                                        value={values.m_t[idx]?.carbon}
                                      />
                                      <ErrorLabel
                                        render={
                                          getIn(errors.m_t?.[idx], "carbon") !==
                                            undefined &&
                                          touched.m_t?.[idx]?.carbon === true
                                        }
                                      >
                                        {getIn(errors.m_t?.[idx], "carbon")}
                                      </ErrorLabel>
                                    </td>
                                    <td>
                                      <button
                                        onClick={handleRemove(idx)}
                                        className="btn btn-transparent"
                                      >
                                        <i className="fas fa-times text-danger fs-3"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <th className="align-middle">
                                  <button
                                    className=" fw-bold btn shadow-none d-flex align-items-center text-nowrap"
                                    type="button"
                                    onClick={() => {
                                      push({
                                        value: 0,
                                        year: new Date().getFullYear(),
                                        fuelSourceId: undefined,
                                        energyM3: 0,
                                        energy: 0,
                                        conversionUnit: 0,
                                      });
                                    }}
                                  >
                                    Add Other
                                    <i className="fas fa-plus-circle text-danger fs-3 ms-1"></i>
                                  </button>
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3 mb-5">
                <button
                  className="btn btn-outline-danger btn-nav"
                  type="submit"
                >
                  <i className="fas fa-save me-3"></i>SAVE
                </button>
                <span>
                  <Link
                    to="/home/settings/building-energy-patterns"
                    className="btn btn-danger btn-nav"
                  >
                    <i className="fas fa-long-arrow-alt-left me-1"></i>BACK{" "}
                  </Link>
                  <Link
                    to="/home/analytics"
                    className="btn btn-danger ms-3 btn-nav"
                  >
                    NEXT <i className="fas fa-long-arrow-alt-right ms-1"></i>
                  </Link>
                </span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}
