import { ActionTypes } from "../ActionTypes";
import { Action } from "../Actions";
import Option from "../../Models/Option";


const reducer = (state: Option | null = null, action: Action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_FUELSOURCE:
      return action.payload;
    default:
      return state;
  }
};
export default reducer;
