import moment from "moment";
import { CarbonDataItem, ConsumptionDataItem } from "../Charts/Bars/Stacked_GroupedSigns";
import styles from "../Tables/styles.module.css"
import formatISO from "date-fns/formatISO";
interface Props {
  expand: boolean;
  selectedIndex: number;
  consumptions: ConsumptionDataItem[];
  carbonEmissions: CarbonDataItem[];
}
export default function Table({ expand, selectedIndex, consumptions, carbonEmissions }: Props) {
  return (
    <table className={`${styles.table} ${expand && styles.expand}`}>
      <thead>
        <tr>
          <th>Month</th>
          <th>Energy Consumption</th>
          <th>Energy Target</th>
          <th>Carbon Consumption</th>
          <th>Carbon Target</th>
        </tr>
      </thead>
      <tbody>
        {consumptions.map((item, idx) => {
            const carbonEmission = carbonEmissions[idx]
            return (
              <tr
                key={formatISO(item.date)}
                className={
                  selectedIndex === moment(item.date).month()
                    ? styles.selected
                    : ""
                }
              >
                <td>{moment(item.date).format("MMMM, DD")}</td>
                <td>{item.consumption}</td>
                <td>{item.projectedEnergy}</td>
                <td>{carbonEmission.carbonEmission}</td>
                <td>{carbonEmission.carbonTarget}</td>
              </tr>
            );
        })}
      </tbody>
    </table>
  );
}