import { getIn, useFormikContext } from "formik";
import moment from "moment";
import { useEffect } from "react";
import ErrorLabel from "../../Components/ErrorLabel";
import { Pattern } from "../../Models/BuildingPattern";
import FuelUse from "../../Models/FuelUse";

type PatternInputSectionProps = {
  use: FuelUse;
  index: number;
};

const getCount = (start?: string, end?: string) => {
  var startDate = moment(start);
  var endDate = moment(end);
  return endDate.diff(startDate, "days");
};

export const PatternInputSection = (p: PatternInputSectionProps) => {
  const { values, handleChange, setFieldValue, errors } = useFormikContext<{
    patterns: Pattern[];
  }>();
  const prefix = `patterns[${p.index}]`;
  useEffect(() => {
    setFieldValue(prefix + ".usedInId", p.use.id, false);
    if (
      prefix === "patterns[1]" ||
      prefix === "patterns[2]" ||
      prefix === "patterns[3]"
    ) {
      setFieldValue(prefix + ".consumption", 0, false);
    }
  }, [p.use]);

  useEffect(() => {
    if (!values.patterns?.[p.index]?.startDate) return;
    if (!values.patterns?.[p.index]?.endDate) return;

    const dayDiff = getCount(
      values.patterns?.[p.index]?.startDate,
      values.patterns?.[p.index]?.endDate
    );
    setFieldValue(prefix + ".daysOnYear", dayDiff);
  }, [
    values.patterns?.[p.index]?.startDate,
    values.patterns?.[p.index]?.endDate,
  ]);

  return (
    <>
      <p className="text-success border-top border-success border-4 pt-2 fw-bold">
        {p.use.use.toUpperCase()}
      </p>
      <div className="row">
        <div className="col-6 text-muted mb-3">
          <label htmlFor={prefix + ".startDate"} className="form-label">
            {p.use.use} start date in the year
          </label>
          <input
            type="date"
            value={values.patterns?.[p.index]?.startDate}
            className={`form-control ${
              !values.patterns?.[p.index]?.startDate && "empty"
            }`}
            id={prefix + ".startDate"}
            name={prefix + ".startDate"}
            onChange={handleChange}
          />
          <ErrorLabel
            render={
              typeof getIn(errors?.patterns?.[p.index], "startDate") ===
              "string"
            }
          >
            {getIn(errors?.patterns?.[p.index], "startDate")}
          </ErrorLabel>
        </div>

        <div className="col-6 text-muted mb-3">
          <label htmlFor={prefix + ".endDate"} className="form-label">
            {p.use.use} end date in the year
          </label>
          <input
            type="date"
            value={values.patterns?.[p.index]?.endDate}
            className={`form-control ${
              !values.patterns?.[p.index]?.endDate && "empty"
            }`}
            id={prefix + ".endDate"}
            name={prefix + ".endDate"}
            onChange={handleChange}
          />
          <ErrorLabel
            render={
              typeof getIn(errors?.patterns?.[p.index], "endDate") === "string"
            }
          >
            {getIn(errors?.patterns?.[p.index], "endDate")}
          </ErrorLabel>
        </div>

        {( p.use.use === 'Cooling' ||
          p.use.use === 'Heating' ) && (
            <div className="col-6 text-muted mb-3">
              <label htmlFor={prefix + ".consumption"} className="form-label">
                {p.use.use} temperature (&#176;c)
              </label>
              <input
                type="number"
                className="form-control"
                id={prefix + ".consumption"}
                name={prefix + ".consumption"}
                value={values.patterns?.[p.index]?.consumption}
                placeholder="Temperature(&#176;c)"
                onChange={handleChange}
              />
              <ErrorLabel
                render={
                  typeof getIn(errors?.patterns?.[p.index], "consumption") ===
                  "string"
                }
              >
                {getIn(errors?.patterns?.[p.index], "consumption")}
              </ErrorLabel>
            </div>
          )}
        <div className="col-6 text-muted mb-3">
          <label className="form-label">
            Number of {p.use.use.toLowerCase()} days in the year
          </label>
          <input
            type="number"
            className="form-control"
            id={prefix + ".daysOnYear"}
            placeholder="Choose"
            contentEditable={false}
            value={values.patterns[p.index]?.daysOnYear}
          />
        </div>
      </div>
    </>
  );
};
