import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function useQueryManager() {
  const history = useHistory();
  const { search } = useLocation();
  const manager = useMemo(() => new URLSearchParams(search), [search]);

  const get = useCallback((s: string) => {
    return manager.get(s);
  }, []);

  const set = useCallback(
    (s: string, v: string) => {
      manager.set(s, v);
      history.replace({ search: "?" + manager.toString() });
    },
    [manager.toString()]
  );

  return {
    get,
    set,
  };
}
