import useAxios from "axios-hooks";
import Select from "react-select";
import { SelectStyle } from "../Helper/ReactSelect";
import FuelSource from "../Models/FuelSource";
import Option from "../Models/Option";

interface Props {
  onChange: (o: Option | undefined) => void;
}

const mapToOption = (i: FuelSource): Option => ({
  value: i.id,
  label: i.source,
});

export const BaseOption: Option = {
  value: 0,
  label: "Select...",
};

export default function FuelSourceSelect({ onChange }: Props) {
  const [{ data: fuelSources = [] }] = useAxios<FuelSource[]>({
    url: "/utility/fuelSources",
  });

  const onChangeCb = (o: Option) => {
    if (o.value === 0) {
      onChange(undefined)
    } else {
      onChange(o)
    }
  };

  return (
    <>
      <label>Fuel</label>
      <Select
        defaultValue={BaseOption}
        options={[BaseOption, ...fuelSources.map(mapToOption)]}
        onChange={(v) => v && onChangeCb(v)}
        styles={SelectStyle}
        placeholder={<span className="select-placeholder">Fuels</span>}
      />
    </>
  );
}
