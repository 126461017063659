import {
  Line,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  ComposedChart,
  Text,
} from "recharts";


interface props {
  expand?: boolean;
  data: { siteAddress: string; carbonEmission: number; siteId: number }[];
  currentPage: number;
  perPage?: number;
}

const Tick = ({ x, y, payload }: any) => {
  return (
    <Text
      x={x}
      y={y}
      width={50}
      textAnchor="middle"
      fill="#8E8E8E"
      fontSize="8px"
      fontWeight="normal"
      verticalAnchor="start"
    >
      {payload.value}
    </Text>
  );
};

export default function LineChart({ expand, data, perPage = 6, currentPage }: props) {
  if (data.length === 0) {
    return <h5 className="text-center mt-5">No data</h5>;
  }

  let largest = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].carbonEmission > largest) {
      largest = data[i].carbonEmission;
    }
  }

  const transformedData = (i: props["data"][0]) => {
    return {
      label: i.siteAddress + ` (${i.siteId})`,
      value: i.carbonEmission,
    };
  };

  return (
    <ResponsiveContainer width="99%" height="100%">
      <ComposedChart
        data={data
          .slice((currentPage - 1) * perPage, perPage * currentPage)
          .map(transformedData)}
        margin={{ right: 15 }}
      >
        <CartesianGrid opacity={0.1} />
        <XAxis
          dataKey="label"
          strokeWidth={0}
          stroke="#8E8E8E"
          type="category"
          interval={0}
          tick={<Tick expand={expand} data={data} />}
        />
        <YAxis
          tickLine={false}
          strokeWidth={0}
          stroke="#000"
          domain={[0, largest]}
        />
        <Line
          type="natural"
          dataKey="value"
          stroke="#4989C6"
          strokeWidth={2}
          dot={{ r: 5 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
