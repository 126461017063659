import { useState } from "react";
import Card from "../Card";
import SideMenuState from "./LeageState";
import { State } from "../../State";
import { useSelector } from "react-redux";
import FuelCard from "../FuelCard";
import style from "../analytics.module.css";
import { useConvertHtmlToImg } from "../../Hooks/useConvertHtmlToImg";
import Option from "../../Models/Option";

type ConsumptionPanelProps = {
  loading: boolean;
};

const isSelectedFuelSource = (fuelSource: Option | null) => (f: any) =>
  fuelSource ? f.fuelSourceName === fuelSource?.label : true;

export default function ConsumptionPanel(p: ConsumptionPanelProps) {
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const [fullFourth, setFullFourth] = useState(false);
  const { convertHtmlToImg } = useConvertHtmlToImg();

  const utilityLeageState = SideMenuState();
  const fuelDetails = utilityLeageState.getFuelDetails();

  let itemColSize = 12;
  if (fuelDetails.filter((isSelectedFuelSource(fuelSource))).length === 2) {
    itemColSize = 6;
  } else if (fuelDetails.filter((isSelectedFuelSource(fuelSource))).length >= 3) {
    itemColSize = 4;
  }

  return (
    <div className={`${style.quaterFourth} ${fullFourth && style.full} p-3`}>
      <Card
        onClick={() => setFullFourth(!fullFourth)}
        isDisabledDownloadBtn={fuelDetails.length === 0}
        onDownloadClick={() => convertHtmlToImg("fuelCard")}
        title="Fuels"
        expanded={fullFourth}
      >
        <div className="row h-100 align-items-center p-0 gy-2" id={"fuelCard"}>
          {fuelDetails.length === 0 && <h4 className="text-center">No Data</h4>}
          {fuelDetails.length !== 0 &&
            fuelDetails.filter((isSelectedFuelSource(fuelSource))).map((i) => {
              return (
                <div key={i.fuelSourceName} className={`col-${itemColSize}`}>
                  <FuelCard
                    name={i.fuelSourceName}
                    siteName={i.siteName}
                    value={i.consumption}
                    percentage={i.incesedPercentage}
                  />
                </div>
              );
            })}
        </div>
      </Card>
    </div>
  );
}
