import { ResponsiveContainer, BarChart, Bar } from "recharts";
import { getFullMonthName } from "../../../Helper/DateFormat";

const data = [
  {
    date: new Date(2020, 0, 1),
    value: 97,
  },
  {
    date: new Date(2020, 1, 1),
    value: 80,
  },
  {
    date: new Date(2020, 2, 1),
    value: 65,
  },
  {
    date: new Date(2020, 3, 1),
    value: 20,
  },
];

export const toThermometerData =
  <T extends unknown>(p: { date: (i: T) => Date; value: (i: T) => number }) =>
  (i: T) => {
    return {
      date: p.date(i),
      value: p.value(i),
    };
  };

interface props {
  expand?: boolean;
  data: Array<{ date: Date; value: number }>;
  loading: boolean;
}

const CustomBar = ({ y, height, payload, columnAmount }: any) => {
  let fullLength = y + height - 60;
  let value = payload.value;
  let ratio = ((100 - value) * fullLength) / 100;
  let xCircle = Math.sqrt(100 - (10 - ratio) ** 2);

  let percent20 = ((100 - 20) * fullLength) / 100 + 30;
  let percent40 = ((100 - 40) * fullLength) / 100 + 30;
  let percent60 = ((100 - 60) * fullLength) / 100 + 30;
  let percent80 = ((100 - 80) * fullLength) / 100 + 30;

  const width = 110;
  let index = payload.index;
  const defaultMargin = width * 1.3;

  const x = defaultMargin * index;

  return (
    <g>
      <rect
        width={width}
        height="100%"
        x={x}
        y={0}
        rx={30}
        fill={index === 0 ? "#4D505C" : "#E8E8E8"}
      />

      <rect
        width={20}
        height={fullLength}
        x={x + width / 2 - 10}
        y={20}
        fill="url(#thermometer)"
        stroke="#00000000"
        rx={10}
      />
      <text x={x + 40} y={10} textAnchor="start" fill={"#8E8E8E"}>
        {getFullMonthName(payload.date)}
      </text>
      {ratio > 10 ? (
        <path
          d={`M${x + width / 2 - 10} 30 a 10,10 0 0 1 20,0 v ${ratio} h-20`}
          fill="white"
          stroke="#00000000"
        />
      ) : (
        <path
          d={`M${x + width / 2} ${20} 
                        a 10,10 0 0 1 ${xCircle},${ratio} 
                        h${-1 * 2 * xCircle}
                        a 10,10 0 0 1 ${xCircle},${-1 * ratio}
                        
                        `}
          fill="white"
          stroke="#00000000"
        />
      )}
      <line
        x1={x + width / 2 - 10}
        y1={percent20}
        x2={x + width / 2 + 30}
        y2={percent20}
        fill="white"
        stroke="white"
      />
      <text
        x={x + width / 2 + 15}
        y={percent20 + 20}
        textAnchor="start"
        fill={index === 0 ? "white" : "#8E8E8E"}
      >
        20%
      </text>
      <line
        x1={x + width / 2 - 10}
        y1={percent40}
        x2={x + width / 2 + 30}
        y2={percent40}
        fill="white"
        stroke="white"
      />
      <text
        x={x + width / 2 + 15}
        y={percent40 + 20}
        textAnchor="start"
        fill={index === 0 ? "white" : "#8E8E8E"}
      >
        40%
      </text>
      <line
        x1={x + width / 2 - 10}
        y1={percent60}
        x2={x + width / 2 + 30}
        y2={percent60}
        fill="white"
        stroke="white"
      />
      <text
        x={x + width / 2 + 15}
        y={percent60 + 20}
        textAnchor="start"
        fill={index === 0 ? "white" : "#8E8E8E"}
      >
        60%
      </text>
      <line
        x1={x + width / 2 - 10}
        y1={percent80}
        x2={x + width / 2 + 30}
        y2={percent80}
        fill="white"
        stroke="white"
      />
      <text
        x={x + width / 2 + 15}
        y={percent80 + 20}
        textAnchor="start"
        fill={index === 0 ? "white" : "#8E8E8E"}
      >
        80%
      </text>
      <line
        x1={x + width / 2}
        y1={20}
        x2={x + width / 2 + 30}
        y2={20}
        fill="white"
        stroke="white"
      />
      <text
        x={x + width / 2 + 15}
        y={40}
        textAnchor="start"
        fill={index === 0 ? "white" : "#8E8E8E"}
      >
        100%
      </text>
      <path
        d={`M${x + width / 2},${y + height - 10} a 20, 20 0 0 1 0,-40 v-20`}
        fill="#94C11F"
      />
      <path
        d={`M${x + width / 2},${y + height - 50} a 20, 20 0 0 1 0,40 v-40`}
        fill="#8AB41E"
      />
    </g>
  );
};

export default function Thermometer({ expand, data, loading }: props) {
  const transformedData = data.map((x, i) => {
    return {
      index: i,
      ...x,
    };
  });

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return <h5 className="text-center pt-5">No data</h5>;
  }

  return (
    <ResponsiveContainer width="100%" height="95%">
      <BarChart data={transformedData} id={"carbonGauge"}>
        <defs>
          <linearGradient id="thermometer" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#E97871" stopOpacity={1} />
            <stop offset="50%" stopColor="#F5B363" stopOpacity={1} />
            <stop offset="100%" stopColor="#8AB41E" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Bar
          type="monotone"
          dataKey="value"
          fill="#4989C6"
          shape={<CustomBar columnAmount={data.length} expand={expand} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
