import { useEffect, useState } from "react";
import { Delete, getSite } from "../Services/Site";
import BuildingEnergy from "../Settings/buildingEnergyForm/BuildingEnergy";
import Modal from "react-modal";
import BuildingEnergyModel from "../Models/Tarriff";
import FuelSourceSelect from "./FuelSourceSelect";
import AxiosRequestFactory from "./AxiosRequestFactory";
import Option from "../Models/Option";
import useAxios from "axios-hooks";
import { Site } from "../Models/Site";

const sortData = (sites: Site[], sort: string) => {
  switch (sort) {
    case "propertyType":
      return sites.sort((a, b) => a.type.localeCompare(b.type));
    case "town":
      return sites.sort((a, b) => a.town.localeCompare(b.town));
    case "size":
      return sites.sort((a, b) => a.size - b.size);
    case "population":
      return sites.sort((a, b) => a.population - b.population);
    default:
      return sites.sort((a, b) =>
        a.type > b.type ? 1 : b.type > a.type ? -1 : 0
      );
  }
};

export default function SiteList() {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [sort, setSort] = useState<string>("propertyType");
  const [selectedFuel, setSelectedFuel] = useState<Option>();
  const [selectedSite, setSelectedSite] = useState<BuildingEnergyModel>();

  const [sitesReq, getSites] = useAxios({
    url: "/business/sites",
  });
  useEffect(() => {
    fetchSites();
  }, [selectedFuel]);
  const fetchSites = () => {
    if (selectedFuel) {
      getSites({ params: { fsi: selectedFuel.value } });
    } else {
      getSites();
    }
  };
  useEffect(() => {}, [sitesReq.data, sort]);
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-9 d-flex align-items-center ">
          <label>Sort by</label>
          <span className="radio">
            <input
              id="propertyType"
              name="sort"
              type="radio"
              defaultChecked
              value="propertyType"
              checked={sort === "propertyType"}
              onChange={(e) => setSort(e.target.value)}
            />
            <label htmlFor="propertyType" className="radio-label">
              Property Type
            </label>
          </span>
          <span className="radio">
            <input
              id="town"
              name="sort"
              type="radio"
              value="town"
              checked={sort === "town"}
              onChange={(e) => setSort(e.target.value)}
            />
            <label htmlFor="town" className="radio-label">
              Town
            </label>
          </span>
          <span className="radio">
            <input
              id="size"
              name="sort"
              type="radio"
              value="size"
              checked={sort === "size"}
              onChange={(e) => setSort(e.target.value)}
            />
            <label htmlFor="size" className="radio-label">
              Size
            </label>
          </span>
          <span className="radio">
            <input
              id="population"
              name="sort"
              type="radio"
              value="population"
              checked={sort === "population"}
              onChange={(e) => setSort(e.target.value)}
            />
            <label htmlFor="population" className="radio-label">
              Population
            </label>
          </span>
        </div>
        <div className="d-flex col-12 col-lg-3">
          <div className="flex-fill">
            <FuelSourceSelect onChange={setSelectedFuel} />
          </div>
        </div>
      </div>

      <AxiosRequestFactory request={sitesReq}>
        <AxiosRequestFactory.Loading>
          <table className="table reducePadding table-striped mt-3 table-bordered">
            <thead className="fs-12">
              <tr>
                <th className="border-end text-white align-middle fw-semibold unset">
                  Code
                </th>
                <th className="text-start border-end text-white fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Site Name</span>
                  </label>
                </th>
                <th className="text-start border-end text-white fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Business Name</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Property Type </span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Address</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Postal Code</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Town</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">
                      Full Time Employee Equivalent
                    </span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">
                      FTE Working Hours/Day
                    </span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">
                      Size / gross internal area (m<sup>2</sup>)
                    </span>
                  </label>
                </th>
                <th className="unset border-end"></th>
                <th className="unset"></th>
              </tr>
            </thead>
          </table>
          <AxiosRequestFactory.LoadingPlaceholder />
        </AxiosRequestFactory.Loading>

        <AxiosRequestFactory.Data>
          <table className="table reducePadding table-striped mt-3 table-bordered">
            <thead className="fs-12">
              <tr>
                <th className="border-end text-white align-middle fw-semibold unset">
                  Code
                </th>
                <th className="text-start border-end text-white fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Site Name</span>
                  </label>
                </th>
                <th className="text-start border-end text-white fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Business Name</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Property Type </span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Address</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Postal Code</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">Town</span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">
                      Full Time Employee Equivalent
                    </span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">
                      Working Hours/Full Time Employee
                    </span>
                  </label>
                </th>
                <th className="text-start border-end text-white align-top fw-semibold unset">
                  <label className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span className="label text-white">
                      Gross Internal Area (m<sup>2</sup>)
                    </span>
                  </label>
                </th>
                <th className="unset border-end"></th>
                <th className="unset"></th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <>
                {sortData(sitesReq.data || [], sort).map((x, i) => (
                  <tr key={i}>
                    <th className="align-middle unset text-center">{x.code}</th>
                    <td className="text-center align-middle">{x.name}</td>
                    <td className="text-center align-middle">ACA Limited</td>
                    <td className="text-center align-middle text-break">
                      {x.type}
                    </td>
                    <td className="text-center align-middle text-break">
                      {x.address}
                    </td>
                    <td className="text-center align-middle text-break">
                      {x.postCode}
                    </td>
                    <td className="text-center align-middle text-break">
                      {x.town}
                    </td>
                    <td className="text-center align-middle text-break">
                      {x.population}
                    </td>
                    <td></td>
                    <td className="text-center align-middle text-break">
                      {x.size}
                    </td>
                    <td className="text-center">
                      <button
                        onClick={async () => {
                          if (x.id) {
                            const value = await getSite(x.id);
                            setSelectedSite({
                              site: x,
                              records: value.energies,
                            });
                          }
                        }}
                        className="btn btn-transparent shadow-none px-0"
                        type="button"
                      >
                        <i className="fas fa-eye"></i>
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-transparent px-0 shadow-none"
                        type="button"
                        onClick={async () => {
                          if (x.id) {
                            await Delete(x.id);
                            fetchSites();
                          }
                        }}
                      >
                        <i className="fas fa-times text-danger fs-3"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </>

              <tr>
                <th className="fw-bold align-middle unset" colSpan={2}>
                  <button
                    className="btn shadow-none fw-bold mx-auto d-flex align-items-center text-nowrap"
                    type="button"
                    onClick={() => {
                      setOpenCreateModal(true);
                    }}
                  >
                    Add new
                    <i className="fas fa-plus-circle text-danger fs-3 ms-1"></i>
                  </button>
                </th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </AxiosRequestFactory.Data>
      </AxiosRequestFactory>
      <Modal
        id="new-site-modal"
        isOpen={openCreateModal}
        style={{
          content: {
            top: "130px",
          },
        }}
        ariaHideApp={false}
      >
        <BuildingEnergy
          close={(reason) => {
            if (reason === "SUBMITTED") {
              fetchSites();
            }
            setOpenCreateModal(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={selectedSite !== undefined}
        style={{
          content: {
            top: "130px",
          },
        }}
        ariaHideApp={false}
      >
        <BuildingEnergy
          close={(reason) => {
            if (reason === "SUBMITTED") {
              fetchSites();
            }
            setSelectedSite(undefined);
          }}
          initial={selectedSite}
        />
      </Modal>
    </>
  );
}
