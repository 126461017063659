import { format } from "date-fns";
import Log from "../../Models/Logs";
import styles from "./styles.module.css";
interface Props {
  expand: boolean;
  data: Log[];
}

const reduceData = (json: any, next: any) => {
  if (json[next.use]) {
    json[next.use].push(next);
  } else {
    json[next.use] = [next];
  }
  return json;
};

const getColumns = (data: any) => {
  return data.map((i: any) => {
    return { id: i.usedInId, name: i.use };
  });
};
export default function Table({ expand, data }: Props) {
  const tableData = data.reduce(reduceData, {});
  const columns = getColumns(data);
  return (
    <table className={`${styles.tableReport} ${expand && styles.expand}`}>
      <thead>
        <tr>
          <th>Date/weekly format</th>
          {columns.map((i: any) => {
            return <th key={i.id}>{i.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {columns.map((column: any) => {
          return (
            <tr key={column.id.toString() + "-"}>
              {tableData[column.name].map((log: any) => {
                  const start = log.startDate.split("-");
                  const end = log.endDate.split("-");
                  const startDate = new Date(start[0], start[1] - 1, start[2]);
                  const endDate = new Date(end[0], end[1] - 1, end[2]);
                return (
                  <>
                    <td className="py-3">
                      {format(startDate, "dd MMMM")} -{" "}
                      {format(endDate, "dd MMMM yyyy")}
                    </td>
                    <td>Heating buildings and/or hot water when needed</td>
                  </>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
