import { useEffect, useState } from "react";
import Layout from "../Layout";
import PortfolioSide from "./PortfolioSideMenu";
import Card from "../Card";
import style from "../analytics.module.css";
import PieChart from "../Charts/Pies/Full";
import Table from "./PortfolioTable";
import BarChart from "./PortfolioBarChart";
import LineChart from "./PortfolioLineChart";
import OverlappingInfo from "../OverlappingInfo";
import PortfolioState from "./PortfolioState";
import { useSelector } from "react-redux";
import { State } from "../../State";

type Panels = "TABLE" | "WASTE" | "EMISSION";

export default function Portfolio() {
  const [fullFirst, setFullFirst] = useState(false);
  const [fullSecond, setFullSecond] = useState(false);
  const [fullThird, setFullThird] = useState(false);
  const [fullFourth, setFullFourth] = useState(false);

  const [tablePage, setTablePage] = useState(1);
  const [wastePage, setWastePage] = useState(1);
  const [emissionPage, setEmissionPage] = useState(1);

  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const state = PortfolioState();

  useEffect(() => {
    state.fetchSites();
  }, []);

  useEffect(() => {
    state.fetchPortfolioData();
  }, [year, month, fuelSource]);

  const increasePage = (panel: Panels) => {
    let fn = setTablePage;

    if (panel === "EMISSION") {
      fn = setEmissionPage;
    }

    if (panel === "WASTE") {
      fn = setWastePage;
    }

    return () => {
      fn((i) => i + 1);
    };
  };

  const decreasePage = (panel: Panels) => {
    let fn = setTablePage;

    if (panel === "EMISSION") {
      fn = setEmissionPage;
    }

    if (panel === "WASTE") {
      fn = setWastePage;
    }

    return () => {
      fn((i) => i - 1);
    };
  };

  return (
    <Layout rightSideBar={<PortfolioSide />}>
      <div className="flex-fill d-flex flex-column">
        <h4 className={`${style.sectionHeader} mt-4 text-center`}>Portfolio</h4>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <div
            className={`${style.quaterFirst} ${fullFirst && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFirst(!fullFirst)}
              title="Number of Sites"
              expanded={fullFirst}
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={style.legend}>Number of sites</span>
                </div>
              }
            >
              <PieChart
                colors={["#4989C6", "#E8E8E8"]}
                text={`${state.sites.length} sites`}
                data={[
                  { name: "aa", value: 400 },
                  { name: "bb", value: 400 },
                ]}
              />
            </Card>
          </div>
          <div
            className={`${style.quaterSecond} ${fullSecond && style.full} p-3`}
          >
            <Card
              onClick={() => setFullSecond(!fullSecond)}
              title={<div className="d-flex">Table of Sites</div>}
              expanded={fullSecond}
              bottom={
                <div className="d-flex align-items-end mt-2">
                  <button
                    disabled={tablePage === 1}
                    onClick={decreasePage("TABLE")}
                    className="btn px-0"
                  >
                    <i
                      className="fas fa-long-arrow-alt-left"
                      style={{
                        color: "#4989C6",
                      }}
                    ></i>
                  </button>
                  <button
                    disabled={tablePage === Math.ceil(state.sites.length / 6)}
                    onClick={increasePage("TABLE")}
                    className="btn px-0 ms-auto"
                  >
                    <i
                      className="fas fa-long-arrow-alt-right"
                      style={{
                        color: "#4989C6",
                      }}
                    ></i>
                  </button>
                </div>
              }
            >
              <Table
                currentPage={tablePage}
                data={state.sites}
                expand={fullSecond}
              />
            </Card>
          </div>
          <div
            className={`${style.quaterThird} ${fullThird && style.full} p-3`}
          >
            <Card
              onClick={() => setFullThird(!fullThird)}
              title="Energy M & T (kWh)"
              expanded={fullThird}
              top={
                <div className="d-flex align-items-center">
                  <div
                    className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>
                      {state.selectedMonthDaysAmount}
                    </span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  <small
                    className={`${style.sm12lg17} mx-2`}
                    style={{
                      fontWeight: 600,
                      color: "#94C11F",
                    }}
                  >
                    <i className="fas fa-arrow-up"></i> 13.8%
                  </small>
                  <OverlappingInfo
                    text1="Target Monthly usage"
                    value1="20 kWh"
                    color1="#94C11F"
                    text2="Actual Monthly usage"
                    value2="180 kWh"
                    color2="#4989C6"
                  />
                </div>
              }
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={style.legend}>
                    {fuelSource?.label} M & T {month.label} {year.label}
                  </span>
                  <button
                    disabled={wastePage === 1}
                    onClick={decreasePage("WASTE")}
                    className="btn ms-auto"
                  >
                    <i
                      className="fas fa-long-arrow-alt-left"
                      style={{
                        color: "#4989C6",
                      }}
                    ></i>
                  </button>
                  <button
                    disabled={
                      wastePage === Math.ceil(state.energyWaste.length / 5)
                    }
                    onClick={increasePage("WASTE")}
                    className="btn px-0"
                  >
                    <i
                      className="fas fa-long-arrow-alt-right"
                      style={{
                        color: "#4989C6",
                      }}
                    ></i>
                  </button>
                </div>
              }
            >
              <BarChart
                currentPage={wastePage}
                data={state.energyWaste}
                expand={fullThird}
              />
            </Card>
          </div>
          <div
            className={`${style.quaterFourth} ${fullFourth && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFourth(!fullFourth)}
              title="Carbon M & T (kg)"
              expanded={fullFourth}
              top={
                <div className="d-flex align-items-center mb-2">
                  <div
                    className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>
                      {state.selectedMonthDaysAmount}
                    </span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  <div className="mx-2 d-flex flex-column align-items-end">
                    <span>58kWh</span>
                    <small
                      className={style.sm9lg17}
                      style={{
                        fontWeight: 600,
                        color: "#E97871",
                      }}
                    >
                      <i className="fas fa-arrow-down"></i> 13.8%
                    </small>
                  </div>

                  <OverlappingInfo
                    text1="Target carbon emissions"
                    value1="20 kg"
                    color1="#4989C6"
                    text2="Actual carbon emissions"
                    value2="180 kg"
                    color2="#4D505C"
                  />
                </div>
              }
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={style.legend}>
                    {fuelSource?.label} emissions {month.label} {year.label}
                  </span>
                  <button
                    disabled={emissionPage === 1}
                    onClick={decreasePage("EMISSION")}
                    className="btn ms-auto"
                  >
                    <i
                      className="fas fa-long-arrow-alt-left"
                      style={{
                        color: "#4989C6",
                      }}
                    ></i>
                  </button>
                  <button
                    onClick={increasePage("EMISSION")}
                    disabled={
                      emissionPage ===
                      Math.ceil(state.carbonEmissions.length / 5)
                    }
                    className="btn px-0"
                  >
                    <i
                      className="fas fa-long-arrow-alt-right"
                      style={{
                        color: "#4989C6",
                      }}
                    ></i>
                  </button>
                </div>
              }
            >
              <LineChart
                currentPage={emissionPage}
                data={state.carbonEmissions}
                expand={fullFourth}
              />
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
