import { Pie, ResponsiveContainer, PieChart as Pies, Sector } from "recharts";


type Props = {
    data: { name: string, value: number}[];
    loading: boolean;
}


export default function PieChart({ data, loading }: Props) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = props;

    const sin = Math.sin(-RADIAN * startAngle);
    const cos = Math.cos(-RADIAN * startAngle);
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;

    return (
      <g>
        <circle cx={cx} cy={cy} r={10} stroke="none" fill="#4D505C" />
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill="#E8E8E8"
        />
        <path d={`M ${sx},${sy} L ${cx + 7},${cy} h -14 z`} fill="#4D505C" />
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="150%">
      <Pies id={"chatGraph"}>
        <Pie
          activeIndex={1}
          activeShape={renderActiveShape}
          paddingAngle={0}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius="100%"
          innerRadius="70%"
          fill="#4989C6"
        />
      </Pies>
    </ResponsiveContainer>
  );
}