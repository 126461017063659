import { getMonth } from "date-fns";
import moment from "moment";
import styles from "../Tables/styles.module.css";
interface Props {
  expand: boolean;
  selectedIndex: number;
  data: { date: Date; saving: number }[];
  loading: boolean;
}
export default function Table({ expand, selectedIndex, data, loading }: Props) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return <h5 className="text-center pt-5">No data</h5>;
  }

  return (
    <table className={`${styles.table} ${expand && styles.expand}`} id={"wasteTable"}>
      <thead>
        <tr>
          <th>Month</th>
          <th>Performance</th>
          <th className="px-1">Avoidable/ Unavoidable Energy (kWh)</th>
          <th>Cost (£)</th>
          <th>Carbon impacts (t)</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((i) => {
          return (
            <tr
              id={i.date.toString()}
              className={
                getMonth(i.date) === selectedIndex ? styles.selected : ""
              }
            >
              <td>{moment(i.date).format("MMMM, DD")}</td>
              <td>
                <i
                  className={`fas ${
                    i.saving >= 0 ? "fa-arrow-up" : "fa-arrow-down"
                  }`}
                ></i>
              </td>
              <td>{Math.abs(i.saving).toFixed(2)}</td>
              <td>100</td>
              <td>120</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
